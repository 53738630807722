import React from "react";
import LoadingButton from "../loading-button/LoadingButton";
import { functions } from "../../firebaseConfig";
import { httpsCallable } from "firebase/functions";

//button adds listing to staff picks
export default function AddStaffPicks({ listing }) {
  const [loading, setLoading] = React.useState({
    disabled: false,
    content: "+S.P",
  });
  React.useEffect(() => {
    if (listing.collections && listing.collections.includes("staff-picks")) {
      setLoading({ disabled: true, content: "+ S.P" });
    }
  }, []);

  async function handleClick() {
    try {
      setLoading({ content: "", disabled: true });
      const addStaffPicks = httpsCallable(functions, "addStaffPicks");
      const res = await addStaffPicks({ listingId: listing.id });
      console.log(res);
      if (res.data.status === "success") {
        setLoading({ content: "added", disabled: true });
        return;
      }
      setLoading({
        disabled: false,
        content: "+S.P",
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <LoadingButton
      loading={loading}
      onClick={handleClick}
      variant="contained"
    />
  );
}
