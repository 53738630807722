import { Box, Typography } from "@mui/material";
import { useInstantSearch } from "react-instantsearch";
import CurrentRefinements from "./CurrentRefinements";

export default function SearchOverview(props) {
  const { results } = useInstantSearch(props);
  if (results.query === "*") {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {results.nbHits} sản phẩm
        </Typography>
        <CurrentRefinements />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        overflow: "scroll",
      }}
    >
      <Typography variant="body2" sx={{ fontSize: "16px" }}>
        {results.nbHits} sản phẩm hợp "{results.query}"
      </Typography>
      <CurrentRefinements />
    </Box>
  );
}
