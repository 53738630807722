import React from "react";
import { connectRange } from "react-instantsearch-dom";
import {
  TextField,
  Box,
  ListItem,
  Divider,
  Modal,
  ListItemText,
  Typography,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import FilterFooter from "./FilterFooter";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";

function CustomPriceRangeMb(props) {
  const { currentRefinement, min, max, refine, closeParent, attribute } = props;

  const [open, setOpen] = React.useState(false);

  function refineMax(e) {
    if (e.target.value > max) {
      refine({
        ...currentRefinement,
        max: undefined,
      });
    } else {
      refine({
        ...currentRefinement,
        max: e.currentTarget.value,
      });
    }
  }

  function refineMin(e) {
    if (e.target.value < min) {
      refine({
        ...currentRefinement,
        min: undefined,
      });
    } else {
      refine({
        ...currentRefinement,
        min: e.currentTarget.value,
      });
    }
  }

  function refineMin(e) {}

  function modalContent() {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "200%",
          bgcolor: "background.default",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            backgroundColor: "background.default",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              margin: "35px auto 20px auto",
              height: "20px",
            }}
          >
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <ChevronLeftIcon fontSize="medium" />
            </IconButton>
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                letterSpacing: "1.5px",
              }}
            >
              GIÁ
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(false);
                closeParent();
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "85%",
              display: "flex",
              gap: "20px",
              margin: "20px auto 0 auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              placeholder="min"
              inputProps={{ type: "number", min: 0 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "secondary.main" }}>K</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={refineMin}
            ></TextField>
            <Box sx={{ borderBottom: "1px solid ", width: "200px" }} />
            <TextField
              placeholder="max"
              inputProps={{ type: "number" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "secondary.main" }}>K</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={refineMax}
            ></TextField>
          </Box>
        </Box>
        <FilterFooter
          closeParent={() => {
            setOpen(false);
            closeParent();
          }}
        />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <ListItem
        sx={{ padding: "8px 5px 8px 5px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        >
          giá
        </ListItemText>
        <ChevronRightIcon />
      </ListItem>
      <Divider />
      <Modal open={open} keepMounted>
        {modalContent()}
      </Modal>
    </React.Fragment>
  );
}

const PriceRangeMb = connectRange(CustomPriceRangeMb);

export default PriceRangeMb;
