import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import AddressD from "./AddressD";
import DisplayTotalD from "./DisplayTotalD";
import PaymentD from "./PaymentD";
import LoadingButton from "../../../components/loading-button/LoadingButton";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "34px",
  alignItems: "center",
  pt: "34px",
  pb: "34px",
};

export default function CheckoutD({
  listing,
  address,
  setAddress,
  addressArray,
  setAddressArray,
  updateAddress,
  handlePurchase,
  setPayment,
  loading,
}) {
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [disabledStatus, setDisabledStatus] = React.useState(true);

  React.useEffect(() => {
    if (address) {
      setDisabledStatus(false);
    } else {
      setDisabledStatus(true);
    }
  }, [address]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          pt: "70px",
          backgroundColor: "#161616",
          width: "50%",
          height: "95vh",
        }}
      >
        <AddressD
          setAddress={setAddress}
          address={address}
          addressArray={addressArray}
          updateAddress={updateAddress}
        />
        <PaymentD setPayment={setPayment} />
        <Button
          disabled={disabledStatus}
          onClick={() => {
            setConfirmModal(true);
          }}
          variant="contained"
          sx={{ width: "70%", borderRadius: 0, mt: "20px" }}
        >
          mua hàng
        </Button>
        <Modal open={confirmModal}>
          <div>
            <Box sx={modalStyle}>
              <Typography
                sx={{
                  fontSize: "18px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                Complete Purchase? <SentimentSatisfiedAltIcon />
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "90%",
                  gap: "5%",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <LoadingButton
                    loading={loading}
                    onClick={handlePurchase}
                    variant="contained"
                  />
                </Box>
                <Button
                  sx={{ width: "50%", borderRadius: 0 }}
                  variant="outlined"
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                >
                  Huỷ
                </Button>
              </Box>
            </Box>
          </div>
        </Modal>
      </Box>
      <Box sx={{ width: "50%" }}>
        <DisplayTotalD listing={listing} />
      </Box>
    </Box>
  );
}
