import React from "react";
import { Box, Avatar, Typography, Badge } from "@mui/material";
import { db } from "../../../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function VerifiedAvatar({ verified, photo }) {
  if (verified) {
    return (
      <Badge
        badgeContent={
          <CheckCircleIcon
            variant="no stroke"
            sx={{ color: "primary.main", fontSize: "16px" }}
          />
        }
        overlap="circular"
      >
        <Avatar sx={{ zIndex: 1 }} src={photo} />
      </Badge>
    );
  }
  return <Avatar sx={{ zIndex: 1 }} src={photo} />;
}

export default function SellerInfoMb({ listing }) {
  const [sellerUrl, setSellerUrl] = React.useState("");
  const [seller, setSeller] = React.useState(undefined);

  React.useEffect(() => {
    async function getSeller() {
      const sellerProfile = await getDoc(doc(db, "profiles", listing.uid));
      setSeller(sellerProfile.data());
      setSellerUrl(`/u/${sellerProfile.data().username}`);
    }
    getSeller();
  }, []);

  if (!seller) {
    return <Avatar />;
  }
  return (
    <Box
      sx={{
        width: "90%",
        margin: "auto",
        display: "flex",
        gap: "8px",
        alignItems: "center",
      }}
    >
      <Link to={sellerUrl} style={{ textDecoration: "none" }}>
        <VerifiedAvatar verified={seller.verified} photo={seller.photo.url} />
      </Link>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Link to={sellerUrl} style={{ textDecoration: "none" }}>
          <Typography sx={{ color: "primary.main", fontSize: "15px" }}>
            {seller.username}
          </Typography>
          <Typography sx={{ color: "primary.dark", fontSize: "14px" }}>
            {seller.sold} sold
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
