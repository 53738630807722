import React from "react";
import { UserContext } from "../../../User";
import { useLocation } from "react-router-dom";
import SearchClearMb from "../../custom-search-box/SearhClearMb";
import Logo from "../../logo/Logo";
import NavMenuMb from "./NavMenuMb";
import { Box, Divider, Typography } from "@mui/material";

export default function NavMb({ notifications }) {
  const location = useLocation();

  const user = React.useContext(UserContext).user;

  if (location.pathname.includes("checkout")) {
    return (
      <React.Fragment>
        <Box
          sx={{
            scrollBehavior: "none",
            position: "fixed",
            width: "100%",
            backgroundColor: "background.default",
            zIndex: 3,
          }}
        >
          <Box
            color="background.light"
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              left: "4%",
              justifyContent: "space-between",
              alignItems: "center",
              height: "64px",
              padding: "10px 0 10px 0",
              width: "92%",
              margin: "auto",
            }}
          >
            <Logo />
            <Typography
              variant="h1"
              sx={{
                fontSize: "20px",
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              CHECKOUT
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: "secondary.dark" }} />
        </Box>
        <Box sx={{ height: "64px" }} />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Box
        sx={{
          scrollBehavior: "none",
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "12px",
          padding: "10px 0 10px 0",
          width: "100%",
          backgroundColor: "background.default",
          zIndex: 4,
          borderBottom: "0.5px solid",
          borderColor: "secondary.dark",
        }}
      >
        <Box
          color="background.default"
          position="relative"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "92%",
          }}
        >
          <Logo />
          <SearchClearMb />
          <NavMenuMb notifications={notifications} />
        </Box>
      </Box>
      <Box sx={{ height: "64px" }} />
    </React.Fragment>
  );
}
