import { Box, Typography, Divider } from "@mui/material";
import { Link } from "react-router-dom";

function DisplayListing({ listing }) {
  const sellerUrl = `/u/${listing.user}`;

  return (
    <Box
      sx={{
        display: "flex",
        borderColor: "secondary.dark",
        width: "70%",
        margin: "106px auto 0 auto",
        gap: "20px",
      }}
    >
      <img
        src={listing.images[0]}
        style={{ height: "200px", width: "200px", objectFit: "cover" }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1px" }}>
        <Typography sx={{ fontSize: "17px", mb: "5px" }}>
          {listing.brands[0]}
        </Typography>
        <Typography sx={{ color: "primary.dark", fontSize: "15px" }}>
          {listing.title}
        </Typography>
        <Typography sx={{ color: "primary.dark", fontSize: "15px" }}>
          size: {listing.size[listing.size.length - 1]}
        </Typography>
        <Link to={sellerUrl} style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              color: "primary.main",
              textDecoration: "underline",
              mt: "5px",
            }}
          >
            seller: {listing.user}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

function DisplayPrice({ listing }) {
  return (
    <Box
      sx={{
        width: "70%",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        margin: "20px auto 0 auto",
        color: "primary.dark",
      }}
    >
      <Typography sx={{ fontSize: "17px", color: "primary.main", mb: "8px" }}>
        Tổng Đơn
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>giá</Typography>
        <Typography>{listing.price}k</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>phí ship</Typography>
        <Typography>{listing.shipping}k</Typography>
      </Box>
      <Divider sx={{ margin: "12px 0 10px 0", borderStyle: "dashed" }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ color: "primary.main" }}>Tổng</Typography>
        <Typography>
          {Number(listing.price) + Number(listing.shipping)}k
        </Typography>
      </Box>
    </Box>
  );
}

export default function DisplayTotalD({ listing }) {
  return (
    <Box>
      <DisplayListing listing={listing} />
      <DisplayPrice listing={listing} />
    </Box>
  );
}
