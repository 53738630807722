import React from "react";
import { useParams } from "react-router-dom";
import { doc, getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import MediaQuery from "react-responsive";
import { UserContext } from "../../User";
import {
  useRefinementList,
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";
import StaffPicksD from "./StaffPicksD";

function StaffPicksRefined(props) {
  const { refine, items } = useRefinementList(props);
  React.useEffect(() => {
    refine("staff-picks");
  }, []);
}

export default function StaffPicks() {
  return (
    <React.Fragment>
      <StaffPicksRefined attribute="collections" />
      <MediaQuery maxWidth={899}></MediaQuery>
      <MediaQuery minWidth={900}>
        <StaffPicksD />
      </MediaQuery>
    </React.Fragment>
  );
}
