import React from "react";
import { httpsCallable } from "firebase/functions";
import { Box, TextField, Typography, Fade } from "@mui/material";
import LoadingButton from "../../components/loading-button/LoadingButton";
import { functions } from "../../firebaseConfig";

const containerSize = {
  xxs: "75vw",
  xs: "75vw",
  sm: "75vw",
  md: "30vw",
  lg: "30vw",
  xl: "30vw",
};

export default function RequestPassword() {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState({
    content: "Gửi liên kết đăng nhập",
    disabled: false,
  });
  const [formStatus, setFormStatus] = React.useState({
    content: "",
    color: "error",
  });

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading({ content: "", disabled: true });
      const sendPasswordReset = httpsCallable(functions, "passwordReset");
      await sendPasswordReset({ email });
      setLoading({ content: "Gửi liên kết đăng nhập", disabled: true });
      setFormStatus({
        content: "Gửi liên kết đăng nhập thành công",
        color: "primary.main",
      });
    } catch (err) {
      setFormStatus({ content: err.message, color: "error" });
      setLoading({ content: "Gửi liên kết đăng nhập", disabled: false });
    }
  }

  return (
    <Fade in={true}>
      <Box
        onSubmit={handleSubmit}
        component="form"
        sx={{
          width: containerSize,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          margin: "50px auto 0 auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: "20px", fontWeight: "bold", mb: "20px" }}
        >
          QUÊN MẬT KHẨU
        </Typography>
        <TextField
          value={email}
          onChange={handleEmail}
          label="email"
        ></TextField>
        <LoadingButton loading={loading} variant="contained" />
        <Typography color={formStatus.color}>{formStatus.content} </Typography>
      </Box>
    </Fade>
  );
}
