import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import AddressMb from "./AddressMb";
import PaymentMb from "./PaymentMb";
import DisplayTotalMb from "./DisplayTotalMb";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "34px",
  alignItems: "center",
  pt: "34px",
  pb: "34px",
};

export default function CheckoutMb({
  listing,
  address,
  setAddress,
  addressArray,
  setAddressArray,
  updateAddress,
  handlePurchase,
}) {
  const [payment, setPayment] = React.useState("cod");
  const [confirmModal, setConfirmModal] = React.useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        width: "90%",
        flexDirection: "column",
        margin: "auto",
        pt: "30px",
        gap: "30px",
        pb: "80px",
      }}
    >
      <AddressMb
        setAddress={setAddress}
        address={address}
        addressArray={addressArray}
        updateAddress={updateAddress}
      />
      <PaymentMb setPayment={setPayment} />
      <DisplayTotalMb listing={listing} />
      <Button
        onClick={() => {
          setConfirmModal(true);
        }}
        variant="contained"
        sx={{ borderRadius: 0 }}
      >
         mua hàng
      </Button>
      <Modal open={confirmModal}>
        <div>
          <Box sx={modalStyle}>
            <Typography
              sx={{
                fontSize: "18px",
                width: "90%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Complete Purchase? <SentimentSatisfiedAltIcon />
            </Typography>
            <Box
              sx={{
                display: "flex",
                width: "90%",
                gap: "5%",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ width: "50%", borderRadius: 0 }}
                onClick={handlePurchase}
              >
                xác nhận
              </Button>
              <Button
                sx={{ width: "50%", borderRadius: 0 }}
                variant="outlined"
                onClick={() => {
                  setConfirmModal(false);
                }}
              >
                Huỷ
              </Button>
            </Box>
          </Box>
        </div>
      </Modal>
    </Box>
  );
}
