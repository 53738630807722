import React from "react";
import { UserContext } from "../../User";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import MediaQuery from "react-responsive";
import SoldD from "./SoldD";
import SoldMb from "./SoldMb";
import Error from "../error/Error";

export default function Sold() {
  const user = React.useContext(UserContext).user;
  const [sold, setSold] = React.useState([]);
  const [revenue, setRevenue] = React.useState("");
  const [pages, setPages] = React.useState([]);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    async function getSold() {
      const soldSnap = await getDocs(collection(db, `/users/${user.uid}/sold`));
      if (!soldSnap.empty) {
        let soldData = [];
        let revenueData = 0;
        soldSnap.forEach((soldListing) => {
          const soldListingData = soldListing.data();
          soldData.push(soldListingData);
          revenueData +=
            Number(soldListingData.listing.price) +
            Number(soldListingData.listing.shipping);
        });
        soldData = soldData.sort(function descending(a, b) {
          if (a.timestamp.seconds > b.timestamp.seconds) {
            return -1;
          }
          if (a.timestamp.seconds < b.timestamp.seconds) {
            return 1;
          }
          return 0;
        });
        setSold(soldData);
        setRevenue(revenueData);
      }
    }
    if (user) {
      getSold();
    }
  }, [user]);

  React.useEffect(() => {
    const chunkSize = 10;
    let newPages = [];
    for (let i = 0; i < sold.length; i += chunkSize) {
      const chunk = sold.slice(i, i + chunkSize);
      newPages.push(chunk);
    }
    console.log(newPages);
    setPages(newPages);
  }, [sold]);

  if (pages.length === 0) {
    return;
  }

  return (
    <React.Fragment>
      <MediaQuery minWidth={900}>
        <SoldD
          sold={sold}
          revenue={revenue}
          page={page}
          setPage={setPage}
          pages={pages}
        />
      </MediaQuery>
      <MediaQuery maxWidth={899}>
        <SoldMb
          sold={sold}
          revenue={revenue}
          page={page}
          setPage={setPage}
          pages={pages}
        />
      </MediaQuery>
    </React.Fragment>
  );
}
