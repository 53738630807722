import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import Listing from "../../components/listing/Listing";

export default function SavesD({ saves }) {
  return (
    <React.Fragment>
      <Typography
        variant="h1"
        sx={{
          mt: "30px",
          fontSize: "20px",
          mb: "10px",
          alignSelf: "start",
          ml: "3vw",
          fontWeight: "bold",
          letterSpacing: "1px",
        }}
      >
        SAVES
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "21px",
          margin: "auto",
          alignItems: "center",
          justifyContent: "center",
          margin: "30px auto 0 auto",
          width: "100%",
        }}
      >
        {saves.map((save, index) => {
          return <Listing listing={save} key={save.id} />;
        })}
      </Box>
    </React.Fragment>
  );
}
