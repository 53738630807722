import React from "react";
import {
  Menu,
  Typography,
  Button,
  InputAdornment,
  TextField,
  Box,
} from "@mui/material";
import { useRange } from "react-instantsearch";

export default function PriceRangeMb(props) {
  const { containerRef } = props;
  const { currentRefinement, min, max, refine } = useRange(props);
  const [newMin, setNewMin] = React.useState(undefined);
  const [newMax, setNewMax] = React.useState(undefined);
  const [variant, setVariant] = React.useState("outlined");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function setRange() {
    if (newMax > max && newMin < min) {
      refine([undefined, undefined]);
    }
    if (newMax > max) {
      refine([newMin, undefined]);
    }
    if (newMin < min) {
      refine([undefined, newMax]);
    } else {
      refine([newMin, newMax]);
    }
  }

  React.useEffect(() => {
    setTimeout(setRange, 1000);
  }, [newMin, newMax]);

  React.useEffect(() => {
    if (open) {
      setVariant("contained");
    } else {
      setVariant("outlined");
    }
  }, [open]);

  function handleClick(e) {
    setAnchorEl(containerRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        variant={variant}
        sx={{
          borderRadius: 0,
          fontSize: "0.7rem",
          padding: "3px 6px 3px 6px",
          minWidth: 0,
        }}
      >
        giá
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: "background.paper",
              width: "95vw",
              position: "absolute",
              border: "0.5px solid",
              borderColor: "secondary.dark",
              borderRadius: 0,
              mt: "10px",
            },
          },
        }}
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <Box
          sx={{
            width: "85%",
            display: "flex",
            gap: "20px",
            margin: "15px auto 15px auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ fontSize: "13px" }}
            placeholder="min"
            inputProps={{ type: "number", min: 0 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography sx={{ color: "secondary.main" }}>K</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setNewMin(e.target.value);
            }}
          ></TextField>
          <Box sx={{ borderBottom: "1px solid ", width: "200px" }} />
          <TextField
            sx={{ fontSize: "13px" }}
            placeholder="max"
            inputProps={{ type: "number" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography sx={{ color: "secondary.main" }}>K</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setNewMax(e.target.value);
            }}
          ></TextField>
        </Box>
      </Menu>
    </React.Fragment>
  );
}
