import React from "react";
import {
  Box,
  Button,
  ListItem,
  Collapse,
  ListItemText,
  TextField,
  Divider,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { connectRange } from "react-instantsearch-dom";
import { useRange } from "react-instantsearch";

export default function PriceRange(props) {
  const { refine, attribute, min, max } = useRange(props);

  const [open, setOpen] = React.useState(false);
  const [newMin, setNewMin] = React.useState();
  const [newMax, setNewMax] = React.useState();

  function setRange() {
    if (newMax > max && newMin < min) {
      refine([undefined, undefined]);
    }
    if (newMax > max) {
      refine([newMin, undefined]);
    }
    if (newMin < min) {
      refine([undefined, newMax]);
    } else {
      refine([newMin, newMax]);
    }
  }

  function reset() {
    refine([undefined, undefined]);
    setNewMin(undefined);
    setNewMax(undefined);
  }

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "7px 15px 7px 15px",
          width: "100%",
        }}
        inset="true"
      >
        <ListItemText
          primary="giá"
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        />
        {open ? <ExpandLess fontSize="10px" /> : <ExpandMore fontSize="10px" />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        sx={{
          padding: "0 13px 0 13px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px 4px 22px 4px",
          }}
        >
          <TextField
            inputProps={{ type: "number" }}
            InputProps={{
              endAdornment: "K",
              sx: { fontSize: "14px", textAlign: "right" },
            }}
            placeholder="min"
            onChange={(e) => {
              setNewMin(e.target.value);
            }}
          ></TextField>
          <TextField
            inputProps={{ type: "number" }}
            InputProps={{
              endAdornment: "K",
              sx: { fontSize: "14px", textAlign: "right" },
            }}
            placeholder="max"
            onChange={(e) => {
              setNewMax(e.target.value);
            }}
          ></TextField>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={setRange}
              variant="contained"
              sx={{ width: "48%", borderRadius: 0 }}
            >
              Set
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "48%", borderRadius: 0 }}
              onClick={reset}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Collapse>
      <Divider sx={{ backgroundColor: "primary.main" }} />
    </React.Fragment>
  );
}
