import { createTheme } from "@mui/material/styles";

const Dark = createTheme({
  typography: {
    body1: { fontSize: "14px" },
  },
  palette: {
    mode: "dark",
    primary: { main: "#ffffff" },
    secondary: {
      main: "rgb(174, 174, 174)",
      dark: "rgba(255, 255, 255, 0.12)",
    },
    background: { default: "#121212" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: "0.5px solid",
            "&:hover": { border: "0.5px solid" },
          },
        },
        {
          props: { variant: "text" },
          style: {
            "&:hover": { textDeocration: "underline" },
          },
        },
      ],
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        style: { fontSize: "14px" },
        MenuProps: {
          PaperProps: {
            elevation: 0,
            sx: {
              mt: "10px",
              position: "absolute",
              border: "0.5px solid",
              borderRadius: 0,
              maxHeight: {
                xxs: "60vw",
                xs: "60vw",
                sm: "60vw",
                md: "20vw",
                lg: "20vw",
                xl: "20vw",
              },
            },
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          backgroundColor: "primary.main",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { borderBottomWidth: "0.5px" },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        InputProps: {
          style: {
            fontSize: "14px ",
          },
        },
        InputLabelProps: {
          style: {
            fontSize: "14px ",
          },
          required: false,
        },
      },
      styleOverrides: {
        root: {
          width: "100%",
          lineHeight: 0,
          borderRadius: "0.5px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "0.5px solid",
              borderColor: "primary.main",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          marginBottom: "6px",
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { variant: "no stroke" },
          style: {
            strokeWidth: 0,
          },
        },
      ],
      styleOverrides: {
        root: {
          stroke: "#121212",
          strokeWidth: 1,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        size: "small",
        style: { fontSize: "14px" },
        PaperProps: {
          elevation: 0,
          sx: {
            backgroundColor: "background.default",
          },
        },
      },
    },
  },
});

export default Dark;
