import { Box, Typography } from "@mui/material";
import { connectStateResults } from "react-instantsearch-dom";

function CustomSearchOverview({ searchResults, searchState }) {
  if (searchState.query && searchResults) {
    return (
      <Box sx={{ width: "90%", margin: "auto", mt: "20px" }}>
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          <strong>{searchResults.nbHits}</strong> listings for{" "}
          <strong>"{searchState.query.replace(`"`, "")}"</strong>
        </Typography>
      </Box>
    );
  } else if (searchResults) {
    return (
      <Box sx={{ width: "90%", margin: "auto", mt: "20px" }}>
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {searchResults.nbHits} listing(s)
        </Typography>
      </Box>
    );
  }
}

const SearchOverviewMb = connectStateResults(CustomSearchOverview);

export default SearchOverviewMb;
