import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { getDocs, collection, where, query } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import ListingSwiper from "../listing-swiper/ListingSwiper";
import { UserContext } from "../../User";
import { LegendToggleRounded } from "@mui/icons-material";

export default function ClosetdListings() {
  const [listings, setListings] = React.useState([]);

  React.useEffect(() => {
    async function getListings() {
      const q = query(
        collection(db, "listings"),
        where("uid", "==", "R53I8sxFXbNNy6DWHbdMeas58Cv1")
      );
      const listingsSnap = await getDocs(q);
      let newListings = [];
      listingsSnap.forEach((listing) => {
        const data = listing.data();
        data.id = listing.id;
        newListings.push(data);
      });
      setListings(newListings);
    }
    getListings();
  }, []);
  return (
    <ListingSwiper
      listings={listings}
      label={"listed by closet'd"}
      link="/u/closetd"
    />
  );
}
