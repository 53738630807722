import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Card,
  Checkbox,
  Divider,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useRefinementList } from "react-instantsearch";
import { useParams } from "react-router-dom";

function Gender(props) {
  const { items, refine } = useRefinementList(props);
  const [open, setOpen] = React.useState(true);
  const preset = useParams().preset;

  React.useEffect(() => {
    if (preset === "nam") {
      refine("m");
    }
    if (preset === "nu") {
      refine("w");
    }
  }, [preset]);

  const handleClick = () => {
    setOpen(!open);
  };

  function transformLabel(label) {
    if (label === "w") {
      return "nữ";
    }
    return "nam";
  }

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "7px 15px 7px 15px",
          width: "100%",
        }}
        inset="true"
      >
        <ListItemText
          primary="giới tính"
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        />
        {open ? <ExpandLess fontSize="10px" /> : <ExpandMore fontSize="10px" />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        sx={{
          padding: "0 13px 0 13px",
        }}
      >
        <List disablePadding sx={{ marginBottom: "16px" }}>
          {items.map((item) => {
            return (
              <ListItem
                sx={{
                  display: "flex",
                  justifyItems: "start",
                  gap: "2px",
                  padding: 0,
                }}
                key={item.label}
              >
                <Checkbox
                  checked={item.isRefined ? true : false}
                  disableRipple
                  size="8px"
                  onChange={(e) => {
                    refine(item.value);
                  }}
                  sx={{ padding: "2px" }}
                ></Checkbox>
                <ListItemText
                  sx={{ padding: 0 }}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "13px",
                      padding: 0,
                    },
                  }}
                >
                  {transformLabel(item.label)}
                </ListItemText>
                <Card sx={{ padding: "2px 3px 2px 3px" }}>
                  <Typography sx={{ fontSize: "11px" }}>
                    {item.count}
                  </Typography>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <Divider sx={{ backgroundColor: "primary.main" }} />
    </React.Fragment>
  );
}

export default Gender;
