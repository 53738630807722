import React from "react";
import { db } from "../../firebaseConfig";
import { startOfWeek, isToday } from "date-fns";
import { query, where, getDocs, collection } from "firebase/firestore";
import { Typography, Box } from "@mui/material";
import { UserContext } from "../../User";

export default function WeeklyOverview() {
  const [today, setToday] = React.useState(0);
  const [week, setWeek] = React.useState(0);
  const user = React.useContext(UserContext).user;

  const current = new Date();
  const start = startOfWeek(current, { weekStartsOn: 1 });

  React.useEffect(() => {
    async function getListings() {
      const q = query(
        collection(db, "listings"),
        where("timestamp", ">=", start),
        where("uid", "==", user.uid)
      );
      const docSnap = await getDocs(q);
      let total = 0;
      let listedToday = 0;
      docSnap.forEach((doc) => {
        total += 1;
        const data = doc.data();
        const listingDate = data.timestamp.toDate();
        if (isToday(listingDate)) {
          listedToday += 1;
        }
      });
      setWeek(total);
      setToday(listedToday);
    }
    if (user.displayName.includes("curated")) {
      getListings();
    }
  }, []);

  if (user.displayName.includes("curated")) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "50%",
          mt: "30px",
        }}
      >
        <Typography sx={{ fontSize: "20px" }}>Tuần: {week}</Typography>
        <Typography sx={{ fontSize: "20px" }}>Hôm nay: {today}</Typography>
      </Box>
    );
  }
}
