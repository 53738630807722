import React from "react";
import { Box, Typography, Fade, Button, IconButton } from "@mui/material";
import SoldListingD from "../../components/sold-listing/SoldListingD";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function SoldD({ sold, revenue, page, setPage, pages }) {
  return (
    <Fade in={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          margin: "30px auto 30px auto ",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "20px",
            mb: "10px",
            alignSelf: "start",
            ml: "3vw",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          SOLD
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "30px 0 40px 1vw",
            width: "95%",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <Typography sx={{ fontSize: "18px" }}>
            <strong>{revenue.toLocaleString()}k</strong> /{" "}
            <strong>{sold.length}</strong> đơn
          </Typography>
          <Link to="/sell/new" style={{ width: "20%" }}>
            <Button variant="outlined" size="large" startIcon={<AddIcon />}>
              đăng bán
            </Button>
          </Link>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1vw" }}>
          <Box
            sx={{
              display: "flex",
              ml: "15vw",
              padding: "0 1vw 0 2vw",
              justifyContent: "space-between",
              width: "60vw",
            }}
          >
            <Typography variant="h2" sx={{ width: "10vw", fontSize: "16px" }}>
              NGƯỜI BÁN
            </Typography>
            <Typography variant="h2" sx={{ width: "10vw", fontSize: "16px" }}>
              NGƯỜI MUA
            </Typography>
            <Typography variant="h2" sx={{ width: "10vw", fontSize: "16px" }}>
              TÌNH TRẠNG
            </Typography>
            <Typography variant="h2" sx={{ width: "10vw", fontSize: "16px" }}>
              TỔNG
            </Typography>
          </Box>
          {pages[page].map((soldListing) => {
            return (
              <SoldListingD
                soldListing={soldListing}
                key={soldListing.listingId}
              />
            );
          })}
          <Box>
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                gap: "3px",
                justifyContent: "center",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  if (page > 0) {
                    setPage(page - 1);
                  }
                }}
              />

              <ArrowForwardIosIcon
                sx={{ "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  if (page < pages.length - 1) {
                    setPage(page + 1);
                  }
                }}
              />
            </Box>
            <Typography
              sx={{ textAlign: "center", fontSize: "15px", mt: "5px" }}
            >
              {page + 1} / {pages.length}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
