import { Box, Typography } from "@mui/material";
import { useInstantSearch } from "react-instantsearch";

function SearchOverviewMb(props) {
  const { results } = useInstantSearch(props);

  if (results.query !== "*") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", display: "flex", margin: "auto" }}
        >
          {results.nbHits} sản phẩm hợp '{results.query.replace(`"`, "")}'
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box sx={{ minWidth: 0 }}>
        <Typography variant="body2" sx={{ fontSize: "14px" }}>
          {results.nbHits} sản phẩm
        </Typography>
      </Box>
    );
  }
}
export default SearchOverviewMb;
