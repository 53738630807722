import { UserContext } from "../../User";
import React from "react";
import MediaQuery from "react-responsive";
import SavesD from "./SavesD";
import SavesMb from "./SavesMb";
import { db } from "../../firebaseConfig";
import { doc, getDocs, collection, WriteBatch } from "firebase/firestore";
import Error from "../error/Error";

export default function Saves() {
  const [saves, setSaves] = React.useState([]);
  const [soldLoading, setSoldLoading] = React.useState({
    content: "Unsave Sold",
    disabled: false,
  });
  const user = React.useContext(UserContext).user;

  React.useEffect(() => {
    async function getSaves() {
      const savesSnap = await getDocs(
        collection(db, `/users/${user.uid}/saves`)
      );
      let savesData = [];
      savesSnap.forEach((save) => {
        savesData.push(save.data());
      });
      savesData.sort(function descending(a, b) {
        if (a.timestamp.seconds > b.timestamp.seconds) {
          return -1;
        }
        if (a.timestamp.seconds < b.timestamp.seconds) {
          return 1;
        }
        return 0;
      });
      console.log(savesData);
      setSaves(savesData);
    }
    if (user) {
      getSaves();
    }
  }, [user]);

  async function getSaves() {
    const savesSnap = await getDocs(collection(db, `/users/${user.uid}/saves`));
    let savesData = [];
    savesSnap.forEach((save) => {
      savesData.push(save.data());
    });
    savesData.sort(function descending(a, b) {
      if (a.timestamp.seconds > b.timestamp.seconds) {
        return -1;
      }
      if (a.timestamp.seconds < b.timestamp.seconds) {
        return 1;
      }
      return 0;
    });
    setSaves(savesData);
  }

  async function unsaveSold() {
    const batch = WriteBatch(db);
    for (const save of saves) {
      if (save.sold) {
        batch.delete(doc(db, `/users/${user.uid}/saves/${save.id}`));
      }
    }
    await batch.commit();
    await getSaves();
  }

  if (!saves.length) {
    return (
      <Error
        title="No Saved Listings"
        content="You don't have any saved listings. Save something and come back!"
      />
    );
  } else {
    return (
      <React.Fragment>
        <MediaQuery minWidth={900}>
          <SavesD saves={saves} unsaveSold={unsaveSold} />
        </MediaQuery>
        <MediaQuery maxWidth={899}>
          <SavesMb saves={saves} unsaveSold={unsaveSold} />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
