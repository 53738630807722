import React from "react";
import { connectStateResults } from "react-instantsearch-dom";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import { Box, Typography, Button } from "@mui/material";

function CustomClearRefinements({ items, refine }) {
  return (
    <Button
      sx={{ width: "40%", borderRadius: 0 }}
      variant="outlined"
      onClick={() => refine(items)}
      disabled={!items.length}
    >
      Reset
    </Button>
  );
}

const ClearRefinements = connectCurrentRefinements(CustomClearRefinements);

function CustomFilterFooter({ closeParent, searchResults }) {
  if (searchResults) {
    return (
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          bgcolor: "background.default",
          display: "flex",
          padding: "15px 0 25px 0",
          gap: "20px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body"
          sx={{ color: "secondary.light", letterSpacing: "0.2px" }}
        >
          {searchResults.nbHits.toLocaleString()} listings found
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "20px",
            margin: "auto",
            justifyContent: "center",
          }}
        >
          <ClearRefinements />
          <Button
            sx={{ width: "40%", borderRadius: 0 }}
            variant="contained"
            onClick={() => {
              closeParent();
            }}
          >
            Shop Items
          </Button>
        </Box>
      </Box>
    );
  }
}

const FilterFooter = connectStateResults(CustomFilterFooter);

export default FilterFooter;
