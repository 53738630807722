import React from "react";
import {
  Box,
  Modal,
  Typography,
  Input,
  InputLabel,
  FormControl,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { UserContext } from "../../../User";
import addresses from "../Address.json";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "34px",
  alignItems: "center",
  pb: "30px",
};

function CityOptions({ city, setCity }) {
  const [options, setOptions] = React.useState([]);

  function handleChange(e, value) {
    setCity(value);
  }

  React.useEffect(() => {
    let optionsArray = [];
    for (const [key, value] of Object.entries(addresses)) {
      optionsArray.push({ name: value.name, code: value.code });
    }
    optionsArray.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setOptions(optionsArray);
  }, []);

  React.useEffect(() => {
    console.log(options);
  }, [options]);

  return (
    <Autocomplete
      required
      onChange={handleChange}
      ListboxProps={{
        sx: {
          mt: "10px",
          backgroundColor: "background.default",
          fontSize: "14px",
          width: "100%",
          position: "absolute",
          border: "0.5px solid",
          borderRadius: 0,
          maxHeight: "30vh",
          zIndex: 1,
        },
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label="tỉnh thành"
          sx={{ width: "100%" }}
          InputLabelProps={{ required: false }}
        />
      )}
      getOptionLabel={(option) => {
        return option.name;
      }}
      options={options}
    />
  );
}

function DistrictOptions({ city, district, setDistrict }) {
  const [options, setOptions] = React.useState([]);

  function handleChange(e, value) {
    setDistrict(value);
  }

  React.useEffect(() => {
    function getOptions() {
      if (!city || !city.code) {
        return;
      }
      setDistrict({ code: "", name: "" });
      let optionsArray = [];
      const cityCode = city.code;
      for (const [key, value] of Object.entries(
        addresses[cityCode]["quan-huyen"]
      )) {
        optionsArray.push({ name: value.name, code: value.code });
      }
      optionsArray.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      optionsArray.push({ name: "", code: "" });
      setOptions(optionsArray);
    }
    getOptions();
  }, [city]);

  if (!city || !city.code) {
    return (
      <TextField
        variant="standard"
        disabled="true"
        sx={{ width: "90%" }}
        label="quận huyện"
      />
    );
  }
  return (
    <Autocomplete
      required
      key={city.code}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => {
        if (option.name === value.name && option.code === value.code) {
          return true;
        }
        return false;
      }}
      ListboxProps={{
        sx: {
          mt: "10px",
          backgroundColor: "background.default",
          fontSize: "14px",
          width: "100%",
          position: "absolute",
          border: "0.5px solid",
          borderRadius: 0,
          maxHeight: "30vh",
          zIndex: 1,
        },
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label="quận huyện"
          sx={{ width: "100%" }}
          InputLabelProps={{ required: false }}
        />
      )}
      getOptionLabel={(option) => {
        return option.name;
      }}
      options={options}
    />
  );
}

function WardOptions({ city, district, setWard, ward }) {
  const [options, setOptions] = React.useState([]);

  function handleChange(e, value) {
    setWard(value);
  }

  React.useEffect(() => {
    function getOptions() {
      if (!district || !district.code) {
        return;
      }
      setWard({ code: "", name: "" });
      let optionsArray = [];
      const cityCode = city.code;
      const districtCode = district.code;
      for (const [key, value] of Object.entries(
        addresses[cityCode]["quan-huyen"][districtCode]["xa-phuong"]
      )) {
        optionsArray.push({ name: value.name, code: value.code });
      }
      optionsArray.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      optionsArray.push({ name: "", code: "" });
      setOptions(optionsArray);
    }
    getOptions();
  }, [district]);

  if (!district || !district.code) {
    return (
      <TextField
        variant="standard"
        disabled="true"
        sx={{ width: "90%" }}
        label="phường xã"
      />
    );
  }
  return (
    <Autocomplete
      key={district.code}
      isOptionEqualToValue={(option, value) => {
        if (option.name === value.name && option.code === value.code) {
          return true;
        }
        return false;
      }}
      onChange={handleChange}
      ListboxProps={{
        sx: {
          mt: "10px",
          backgroundColor: "background.default",
          fontSize: "14px",
          width: "100%",
          position: "absolute",
          border: "0.5px solid",
          borderRadius: 0,
          maxHeight: "30vh",
          zIndex: 1,
        },
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          label="phường xã"
          sx={{ width: "100%" }}
          InputLabelProps={{ required: false }}
        />
      )}
      getOptionLabel={(option) => {
        return option.name;
      }}
      options={options}
    />
  );
}

export default function AddAddressD({ addressArray, updateAddress, close }) {
  const user = React.useContext(UserContext).user;
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [ward, setWard] = React.useState({ name: "", code: "" });
  const [district, setDistrict] = React.useState({ name: "", code: "" });
  const [city, setCity] = React.useState({ name: "", code: "" });

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const newArray = [];
      const formData = {
        name,
        phone,
        city: city.name,
        district: district.name,
        ward: ward.name,
        street,
      };
      newArray.push(formData);
      if (addressArray.length > 0) {
        newArray.push(addressArray[0]);
      }
      if (addressArray.length > 1) {
        newArray.push(addressArray[1]);
      }
      await updateDoc(doc(db, "users", user.uid), {
        addressArray: newArray,
        defaultAddress: formData,
      });
      await updateAddress();
      close();
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Box component="form" sx={modalStyle} onSubmit={handleSubmit}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          borderBottom: "1px solid",
          borderColor: "secondary.dark",
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: "18px", fontWeight: "normal" }}
        >
          Thêm Địa Chỉ
        </Typography>
        <CloseIcon
          color="action"
          sx={{
            "&:hover": {
              cursor: "pointer",
              borderBottom: "1px solid white",
            },
          }}
          onClick={close}
        />
      </Box>
      <TextField
        required
        variant="standard"
        value={name}
        label="tên"
        onChange={(e) => {
          setName(e.target.value);
        }}
        sx={{ width: "90%" }}
      />
      <Box sx={{ display: "flex", gap: "20px", width: "90%" }}>
        <TextField
          required
          variant="standard"
          value={phone}
          label="số điện thoại"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
        />
        <CityOptions city={city} setCity={setCity} />
      </Box>
      <Box sx={{ display: "flex", gap: "20px", width: "90%" }}>
        <DistrictOptions city={city} setDistrict={setDistrict} />
        <WardOptions
          city={city}
          district={district}
          ward={ward}
          setWard={setWard}
        />
      </Box>
      <TextField
        variant="standard"
        value={street}
        label="địa chỉ"
        onChange={(e) => {
          setStreet(e.target.value);
        }}
        sx={{ width: "90%" }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{
          width: "90%",
          padding: "10px",
          margin: "25px 0 5px 0",
          fontWeight: "bold",
          borderRadius: 0,
        }}
      >
        Lưu địa chỉ
      </Button>
    </Box>
  );
}
