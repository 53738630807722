import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

export default function PaymentD({ setPayment }) {
  function handleChange(e) {
    setPayment(e.target.value);
  }

  return (
    <Box sx={{ width: "70%", mt: "20px" }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: "17px",
          width: "70%",
          fontWeight: "normal",
        }}
      >
        Thanh Toán
      </Typography>
      <RadioGroup
        defaultValue="cod"
        onChange={handleChange}
        sx={{
          border: "1px solid",
          borderColor: "secondary.dark",
          mt: "20px",
          padding: "17px",
        }}
      >
        <FormControlLabel
          value="cod"
          control={<Radio sx={{ mr: "20px" }} disableRipple size="small" />}
          label={
            <Typography sx={{ fontSize: "15px" }}>
              COD - Thanh toán tiền mặt
            </Typography>
          }
        />
        <FormControlLabel
          disabled={true}
          value="ck"
          control={<Radio sx={{ mr: "20px" }} disableRipple size="small" />}
          label={
            <Typography sx={{ fontSize: "15px" }}>
              CK (Chưa có chức năng)
            </Typography>
          }
        />
      </RadioGroup>
    </Box>
  );
}
