import React from "react";
import { Box, Typography, Badge, Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const containerWidth = {
  xxs: "80%",
  xs: "80%",
  sm: "80%",
  md: "38vw",
  lg: "38vw",
  xl: "38vw",
};

function NoFees() {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ fontSize: "17px", letterSpacing: "0.5px", fontWeight: "bold" }}
      >
        1. 0% Fees for 1 Year
      </Typography>
      <Typography sx={{ mt: "7px", color: "secondary.main" }}>
        Begins after fees are implemented.
      </Typography>
    </Box>
  );
}

function How() {}

function Verified() {
  return (
    <Box sx={{ mt: "60px" }}>
      <Typography
        variant="h2"
        sx={{ fontSize: "17px", letterSpacing: "0.5px", fontWeight: "bold" }}
      >
        2. Badges
      </Typography>
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          gap: "10px",
          justifyContent: "start",
          alignItems: "center",
          padding: "20px 10px 20px 10spx",
          width: "100%",
        }}
      >
        <Badge
          badgeContent={<CheckCircleIcon variant="no stroke" />}
          overlap="circular"
        >
          <Avatar sx={{ height: "75px", width: "75px" }} />
        </Badge>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <Typography>
            CLOSET'D{"   "}/{" "}
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>OG</span>
          </Typography>
          <Typography
            type="body"
            sx={{ color: "secondary.main", fontSize: "15px" }}
          >
            XXX sold
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ mt: "7px", color: "secondary.main" }}>
        Exclusive OG badge + verified check.
      </Typography>
    </Box>
  );
}

function Support() {
  return (
    <Box sx={{ mt: "60px" }}>
      <Typography
        variant="h2"
        sx={{ fontSize: "17px", letterSpacing: "0.5px", fontWeight: "bold" }}
      >
        3. Support
      </Typography>
      <Typography sx={{ mt: "7px", color: "secondary.main" }}>
        You'll receive personal support from our team for any problems which may
        arise. Optionally, you can help us come up with ways to make the app
        better.
      </Typography>
    </Box>
  );
}

function Requirements() {
  return (
    <Box>
      <Typography
        variant="h2"
        sx={{ fontSize: "17px", letterSpacing: "0.5px", fontWeight: "bold" }}
      >
        List 20 items/month for 10 months
      </Typography>
      <Typography sx={{ mt: "20px", color: "secondary.main" }}>
        List 20 items for 10 out of the next 12 months (You can miss up to 2
        months and still fulfill the requirements). ** this is very wordy :((
      </Typography>
    </Box>
  );
}

export default function OGTerms() {
  return (
    <Box
      sx={{
        width: containerWidth,
        margin: "30px auto 30px auto",
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: "20px", letterSpacing: "1.5px" }}
      >
        Become an <strong>OG SELLER</strong>
      </Typography>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          letterSpacing: "1px",
          mt: "40px",
        }}
      >
        REQUIREMENTS
      </Typography>
      <Box
        sx={{
          width: "95%",
          margin: "10px auto 30px auto",
          padding: "4% 6% 4% 6%",
          border: "0.5px solid",
          borderColor: "secondary.dark",
        }}
      >
        <Requirements />
      </Box>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
          letterSpacing: "1px",
          mt: "40px",
        }}
      >
        PERKS
      </Typography>
      <Box
        sx={{
          width: "95%",
          margin: "10px auto 30px auto",
          padding: "4% 6% 4% 6%",
          border: "0.5px solid",
          borderColor: "secondary.dark",
        }}
      >
        <NoFees />
        <Verified />
        <Support />
      </Box>
    </Box>
  );
}
