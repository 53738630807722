import { Box } from "@mui/material";
import SearchOverview from "./SearchOverview";
import SortBy from "./SortBy";

export default function StateBar() {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        margin: "auto",
        height: "60px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "0.5px solid",
        borderColor: "secondary.dark",
      }}
    >
      <SearchOverview />
      <SortBy
        defaultRefinement="listings/sort/_text_match:desc"
        items={[
          {
            label: "Xếp theo: liên quan",
            value: "listings/sort/_text_match:desc",
          },
          {
            label: "Xếp theo: mới nhất",
            value: "listings/sort/timestamp:desc",
          },
          {
            label: "Xếp theo: giá cao",
            value: "listings/sort/price:desc",
          },
          {
            label: "Xếp theo: giá thấp",
            value: "listings/sort/price:asc",
          },
        ]}
      />
    </Box>
  );
}
