import React from "react";
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  setDoc,
  doc,
  serverTimestamp,
  query,
  getDocs,
  collection,
  where,
  getDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { auth, db, functions } from "../../firebaseConfig";
import LoadingButton from "../loading-button/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const formStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xxs: "80%",
    xs: "80%",
    sm: "80%",
    md: "30%",
    lg: "30%",
    xl: "30%",
  },
  padding: "20px",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

function ValidIcon({ valid }) {
  if (valid === "none") {
    return "";
  }
  if (valid) {
    return <CheckCircleIcon sx={{ stroke: "none", fontSize: "16px" }} />;
  }
  return <CancelIcon sx={{ stroke: "none", fontSize: "16px" }} />;
}

export default function Signup({ close, openSignin }) {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState({
    disabled: false,
    content: "đăng ký",
  });
  const [username, setUsername] = React.useState("");
  const [valid, setValid] = React.useState("none");
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();

  function checkUsername() {
    if (username.length === 0) {
      setValid("none");
    } else if (username.length < 3) {
      setValid(false);
    } else if (!/^[a-z0-9][a-z0-9_]*([.][a-z0-9_]+)*$/.test(username)) {
      setValid(false);
    } else {
      getDoc(doc(db, `/usernames/${username.toLowerCase()}`)).then(
        (usernameSnap) => {
          if (!usernameSnap.exists()) {
            setValid(true);
          } else {
            setValid(false);
          }
        }
      );
    }
  }

  React.useEffect(() => {
    function containsUppercase(str) {
      return /[A-Z]/.test(str);
    }
    if (containsUppercase(username)) {
      const newUsername = username.toLowerCase();
      setUsername(newUsername);
    }
    setTimeout(checkUsername, 1500);
  }, [username]);

  function handleUsername(e) {
    let value = e.target.value;
    setUsername(value);
  }

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  async function signup(event) {
    event.preventDefault();
    setLoading({ disabled: true, content: "" });
    try {
      //check if username already in use
      const createUser = httpsCallable(functions, "createUser");
      const usernameSnap = await getDoc(
        doc(db, `/usernames/${username.toLowerCase()}`)
      );
      if (username.length < 3) {
        throw new Error("username must contain atleast 3 characters");
      }
      if (usernameSnap.exists()) {
        throw new Error("username taken");
      }
      if (password.length < 8) {
        throw new Error("password must contain atleast 8 characters");
      }
      if (password.length > 20) {
        throw new Error("password must contain less than 20 characters");
      }
      if (!/^[a-z0-9][a-z0-9_]*([.][a-z0-9_]+)*$/.test(username)) {
        throw new Error("invalid username");
      }
      const res = await createUser({
        username,
        email,
        password,
      });
      if (res.data.status === "success") {
        navigate("/success-signup");
        close();
      } else {
        const err = res.data.err;
        setError(err);
        setLoading({ disabled: false, content: "đăng ký" });
      }
    } catch (err) {
      setError(err.message);
      setLoading({ disabled: false, content: "đăng ký" });
    }
  }

  return (
    <Box component="form" sx={formStyle} onSubmit={signup}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" fontSize="18px">
          Đăng Ký Tài Khoản
        </Typography>
        <CloseIcon
          color="action"
          sx={{
            "&:hover": { cursor: "pointer", borderBottom: "1px solid white" },
          }}
          onClick={() => {
            close();
          }}
        />
      </Box>
      <TextField
        required
        variant="standard"
        value={username}
        label="@username"
        size="small"
        onChange={handleUsername}
        InputProps={{
          sx: { pl: "2px", fieldset: { borderColor: "secondary.dark" } },
          endAdornment: (
            <InputAdornment position="end" sx={{ padding: 0, ml: 0 }}>
              <ValidIcon valid={valid} />
            </InputAdornment>
          ),
        }}
      ></TextField>
      <TextField
        required
        variant="standard"
        value={email}
        label="email"
        size="small"
        onChange={handleEmail}
        type="email"
      ></TextField>
      <TextField
        required
        variant="standard"
        value={password}
        label="password"
        type="password"
        size="small"
        onChange={handlePassword}
      ></TextField>
      <Typography variant="body2" color="error">
        {error}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <LoadingButton loading={loading} variant="outlined" />
        <Typography
          sx={{
            mt: "20px",
            mb: "10px",
            fontSize: "14px",
            textDecoration: "underline",
            textAlign: "center",
            "&:hover": { cursor: "pointer" },
          }}
          variant="body1"
          onClick={() => {
            close();
            openSignin();
          }}
        >
          Đăng nhập
        </Typography>
      </Box>
    </Box>
  );
}
