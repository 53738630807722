import React from "react";
import { UserContext } from "../../User";
import { Card, Typography, Box, Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Edit from "../edit/Edit";
import Delete from "../delete/Delete";
import SaveNoCount from "../save/SaveNoCount";
import { StaffPicksButton } from "../staff-picks/StaffPicksButton";

const listingWidthHeight = {
  xxs: "50vw",
  xs: "50vw",
  sm: "50vw",
  md: "17.5vw",
  lg: "17.5vw",
  xl: "17.5vw",
};

const soldTextPosition = {
  xxs: "21.5vw",
  xs: "21.5vw",
  sm: "21.5vw",
  md: "8.75vw",
  lg: "8.75vw",
  xl: "8.75vw",
};

function ListingImage({ image, sold }) {
  if (sold) {
    return (
      <Box
        sx={{
          backgroundColor: "none",
          borderRadius: "0",
          width: listingWidthHeight,
          height: listingWidthHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
          overflow: "hidden",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            backgroundColor: "background.default",
            zIndex: 2,
            width: listingWidthHeight,
            height: listingWidthHeight,
            opacity: "60%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
            letterSpacing: "0.1rem",
            fontWeight: "bold",
            color: "yellow",
          }}
        >
          SOLD
        </Typography>
        <img src={image} style={{ height: "100%", zIndex: 0 }} />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        borderRadius: "0",
        width: listingWidthHeight,
        height: listingWidthHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          cursor: "pointer",
        },
        overflow: "hidden",
      }}
    >
      <img src={image} style={{ height: "100%" }} />
    </Box>
  );
}

export default function Listing({ listing }) {
  const listingUrl = `/listing/${listing.id}`;
  const user = React.useContext(UserContext).user;
  const location = useLocation();

  if (location.pathname.includes("saves")) {
    return (
      <Box
        sx={{
          width: listingWidthHeight,
          border: "0.5px solid",
          borderColor: "secondary.dark",
          overflow: "hidden",
        }}
      >
        <Link
          to={listingUrl}
          style={{
            textDecoration: "none",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListingImage image={listing.images[0]} sold={listing.sold} />
        </Link>
        <Box
          sx={{
            height: "41.5px",
            padding: "4px 4px 4px 4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "primary.main",
            width: "100%",
          }}
        >
          <SaveNoCount listingId={listing.id} />
          <Typography
            noWrap={true}
            variant="body"
            sx={{
              fontSize: "16px",
            }}
          >
            {listing.price}k
          </Typography>
        </Box>
      </Box>
    );
  }
  if (
    user &&
    user.uid === listing.uid &&
    !location.pathname.includes("search")
  ) {
    return (
      <Box
        sx={{
          width: listingWidthHeight,
          border: "0.5px solid",
          borderColor: "secondary.dark",
          overflow: "hidden",
        }}
      >
        <Link
          to={listingUrl}
          style={{
            textDecoration: "none",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListingImage image={listing.images[0]} sold={listing.sold} />
        </Link>
        <Box
          sx={{
            mt: "8px",
            padding: "0px 8px 8px 8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "primary.main",
            gap: "10px",
            width: "100%",
          }}
        >
          <Edit listingId={listing.id} size="small" fontSize="14px" />
          <Delete listingId={listing.id} size="small" fontSize="14px" />
        </Box>
      </Box>
    );
  }
  if (user && user.displayName === "closetd") {
    return (
      <Box
        sx={{
          width: listingWidthHeight,
          border: "0.5px solid",
          borderColor: "secondary.dark",
        }}
      >
        <Link
          to={listingUrl}
          style={{
            textDecoration: "none",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListingImage image={listing.images[0]} sold={listing.sold} />
        </Link>
        <Typography
          sx={{
            mt: "4px",
            padding: "5px 8px 0px 8px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "13px",
            textOverflow: "ellipsis",
          }}
        >
          {listing.title}
        </Typography>
        <Box
          sx={{
            padding: "0px 8px 10px 8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "primary.main",
            width: "100%",
          }}
        >
          <Typography
            noWrap={true}
            variant="body"
            sx={{
              fontSize: "13px",
            }}
          >
            {listing.type.size.toUpperCase()}
          </Typography>
          <Typography
            noWrap={true}
            variant="body"
            sx={{
              fontSize: "13px",
            }}
          >
            {listing.price}k
          </Typography>
          <Box sx={{ width: "30%" }}>
            <StaffPicksButton listing={listing} />
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        width: listingWidthHeight,
        border: "0.5px solid",
        borderColor: "secondary.dark",
      }}
    >
      <Link
        to={listingUrl}
        style={{
          textDecoration: "none",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListingImage image={listing.images[0]} sold={listing.sold} />
      </Link>
      <Typography
        sx={{
          mt: "4px",
          padding: "5px 8px 0px 8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: "13px",
          textOverflow: "ellipsis",
        }}
      >
        {listing.title}
      </Typography>
      <Box
        sx={{
          padding: "0px 8px 10px 8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "primary.main",
          width: "100%",
        }}
      >
        <Typography
          noWrap={true}
          variant="body"
          sx={{
            fontSize: "13px",
          }}
        >
          {listing.type.size.toUpperCase()}
        </Typography>
        <Typography
          noWrap={true}
          variant="body"
          sx={{
            fontSize: "13px",
          }}
        >
          {listing.price}k
        </Typography>
      </Box>
    </Box>
  );
}
