import React from "react";
import AddStaffPicks from "./AddStaffPicks";
import RemoveStaffPicks from "./RemoveStaffPicks";

//returns remove staff picks if already in staff picks or add staff picks if not
export function StaffPicksButton({ listing }) {
  if (listing.collections && listing.collections.includes("staff-picks")) {
    return <RemoveStaffPicks listing={listing} />;
  }
  return <AddStaffPicks listing={listing} />;
}
