import React from "react";
import {
  Box,
  Autocomplete,
  Button,
  Card,
  Dialog,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { UserContext } from "../../../User";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "../../../components/loading-button/LoadingButton";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebaseConfig";
import { useRefinementList } from "react-instantsearch";

function Collection({ collection, setCollections, collections }) {
  function deleteCollection() {
    const newCollections = collections.filter((item) => item !== collection);
    setCollections(newCollections);
  }

  return (
    <Card
      sx={{
        padding: "5px 8px 5px 8px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
      }}
    >
      <Typography>{collection}</Typography>
      <CloseIcon
        onClick={() => {
          deleteCollection();
        }}
        sx={{ fontSize: "10px", "&:hover": { cursor: "pointer" } }}
      />
    </Card>
  );
}

export default function EditCollections(props) {
  const { listing } = props;
  const { items } = useRefinementList(props);
  const [collection, setCollection] = React.useState("");
  const [collections, setCollections] = React.useState([]);
  const [loading, setLoading] = React.useState({
    content: "update collections",
    disabled: false,
  });
  const user = React.useContext(UserContext).user;

  React.useEffect(() => {
    if (listing.collections) {
      setCollections(listing.collections);
    }
  }, [listing]);

  function addCollection() {
    let temp = [...collections];
    temp.push(collection);
    setCollections(temp);
    setCollection("");
  }

  async function onClick(e) {
    try {
      setLoading({ content: "", disabled: true });
      const updateCollections = httpsCallable(functions, "updateCollections");
      const res = await updateCollections({
        collections,
        listingId: listing.listingId,
      });
      console.log(res);
      setLoading({ content: "update collections", disabled: false });
    } catch (err) {
      console.log(err);
      setLoading({ content: "update collections", disabled: false });
    }
  }

  if (user && user.displayName === "hieu") {
    return (
      <Box sx={{ mt: "20px" }}>
        <Box sx={{ display: "flex", gap: "10px" }}>
          {items.map((item) => {
            return (
              <Card
                sx={{
                  padding: "5px 8px 5px 8px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                key={item.label}
              >
                <Typography>{item.label}</Typography>
                <Typography sx={{ fontSize: "13px", color: "secondary.main" }}>
                  {item.count}
                </Typography>
              </Card>
            );
          })}
        </Box>
        <Box sx={{ display: "flex", gap: "10px", mt: "10px" }}>
          <TextField
            value={collection}
            onChange={(e) => {
              setCollection(e.target.value);
            }}
            size="small"
            variant="outlined"
            label="add collection"
            InputLabelProps={{ required: false }}
          />
          <Button
            variant="outlined"
            onClick={() => {
              addCollection();
            }}
          >
            add+
          </Button>
        </Box>
        <Box sx={{ display: "flex", gap: "5px", mt: "10px", mb: "10px" }}>
          {collections.map((collection) => {
            return (
              <Collection
                collection={collection}
                key={collection}
                setCollections={setCollections}
                collections={collections}
              />
            );
          })}
        </Box>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onClick}
        />
      </Box>
    );
  }
}
