import React from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { db, functions } from "../../firebaseConfig";
import { UserContext } from "../../User";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import MediaQuery from "react-responsive";
import CheckoutD from "./checkout-d/CheckoutD";
import CheckoutMb from "./checkout-mb/CheckoutMb";
import Error from "../error/Error";
import NotVerified from "../error/NotVerified";

export default function Checkout() {
  const navigate = useNavigate();

  const listingId = useParams().listingId;
  const user = React.useContext(UserContext).user;
  const [listing, setListing] = React.useState(undefined);
  const [addressArray, setAddressArray] = React.useState([]);
  const [address, setAddress] = React.useState(undefined);
  const [payment, setPayment] = React.useState("cod");
  const [soldIG, setSoldIG] = React.useState(false);
  const [loading, setLoading] = React.useState({
    disabled: false,
    content: "xác nhận",
  });

  React.useEffect(() => {
    async function getListing() {
      try {
        const [listingSnap, userSnap, sellerSnap] = await Promise.all([
          getDoc(doc(db, "listings", listingId)),
          getDoc(doc(db, "users", user.uid)),
        ]);
        if (listingSnap.data()) {
          setListing(listingSnap.data());
        }
        if (
          userSnap.data().addressArray &&
          userSnap.data().addressArray.length > 0
        ) {
          setAddressArray(userSnap.data().addressArray);
          setAddress(userSnap.data().defaultAddress);
        }
        return;
      } catch (err) {
        console.log(err);
      }
    }
    getListing();
  }, [user]);

  /*
  React.useEffect(() => {
    async function checkSold() {
      const checkSoldIG = httpsCallable(functions, "checkSoldIG");
      const res = await checkSoldIG({ listingId });
      if (res.data.status === "success" && res.data.sold === true) {
        console.log("sold!");
        setSoldIG(true);
      }
    }
    checkSold();
  }, []);
  */

  async function handlePurchase() {
    setLoading({ disabled: true, content: "" });
    const data = {
      address,
      payment,
      timestamp: serverTimestamp(),
      listingId,
    };
    const purchaseListing = httpsCallable(functions, "purchaseListing");
    const res = await purchaseListing(data);
    if (res.data.status === "success") {
      navigate("/success-purchase");
    }
    /*
    await setDoc(doc(db, `listings/${listingId}/buyer/${user.uid}`), data);
    navigate("/success-purchase");
    */
  }

  async function updateAddress() {
    const userSnap = await getDoc(doc(db, "users", user.uid));
    setAddress(userSnap.data().defaultAddress);
    setAddressArray(userSnap.data().addressArray);
    return;
  }
  if (!user) {
    return <div />;
  }
  if (!listing) {
    return (
      <Error
        title="Listing not found"
        content="We tried but couldn't find a listing with this id"
      />
    );
  }
  if (listing.sold) {
    return (
      <Error
        title="Listing sold"
        content="This listing has been sold and is no longer available"
      />
    );
  }
  if (soldIG) {
    return (
      <Error
        title="Đã bán trên IG"
        content="App vừa check thì sản phẩm này đã hết hàng vì đã bán trên IG"
      />
    );
  }
  if (!user.emailVerified) {
    return <NotVerified />;
  }
  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <CheckoutMb
          listing={listing}
          addressArray={addressArray}
          setAddressArray={setAddressArray}
          address={address}
          setAddress={setAddress}
          handlePurchase={handlePurchase}
          updateAddress={updateAddress}
          setPayment={setPayment}
          loading={loading}
        />
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <CheckoutD
          listing={listing}
          addressArray={addressArray}
          setAddressArray={setAddressArray}
          address={address}
          setAddress={setAddress}
          handlePurchase={handlePurchase}
          updateAddress={updateAddress}
          setPayment={setPayment}
          loading={loading}
        />
      </MediaQuery>
    </React.Fragment>
  );
}
