import React from "react";
import {
  Box,
  List,
  ListItemButton,
  InputAdornment,
  ListItem,
  Collapse,
  ListItemText,
  Checkbox,
  Card,
  Typography,
  Divider,
  TextField,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { connectRefinementList } from "react-instantsearch-dom";
import { useRefinementList } from "react-instantsearch";

function CustomBrandList({ items, refine, attribute, searchForItems }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "7px 15px 7px 15px",
          width: "100%",
        }}
        inset="true"
      >
        <ListItemText
          primary={attribute}
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        />
        {open ? <ExpandLess fontSize="10px" /> : <ExpandMore fontSize="10px" />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        sx={{
          padding: "0 13px 0 13px",
        }}
      >
        <TextField
          sx={{ width: "96%", margin: "10px 5px 10px 5px", padding: "0" }}
          onChange={(e) => {
            searchForItems(e.currentTarget.value);
          }}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size="small" />
              </InputAdornment>
            ),
          }}
        ></TextField>
        <List disablePadding sx={{ marginBottom: "16px" }}>
          {items.map((item) => {
            return (
              <ListItem
                sx={{
                  display: "flex",
                  justifyItems: "start",
                  gap: "2px",
                  padding: 0,
                }}
                key={item.label}
              >
                <Checkbox
                  checked={item.isRefined ? true : false}
                  disableRipple
                  size="8px"
                  onChange={(e) => {
                    refine(item.value);
                  }}
                  sx={{ padding: "2px" }}
                ></Checkbox>
                <ListItemText
                  sx={{ padding: 0 }}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "13px",
                      padding: 0,
                    },
                  }}
                >
                  {item.label}
                </ListItemText>
                <Card sx={{ padding: "2px 3px 2px 3px" }}>
                  <Typography sx={{ fontSize: "11px" }}>
                    {item.count}
                  </Typography>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
      <Divider sx={{ backgroundColor: "primary.main" }} />
    </React.Fragment>
  );
}

const BrandList = connectRefinementList(CustomBrandList);
export default BrandList;
