import React from "react";
import Listing from "../../../components/listing/Listing";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { connectInfiniteHits } from "react-instantsearch-dom";

function CustomInfiniteHitsMb({ hits, refineNext, hasMore }) {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "start",
          margin: "20px auto 30px auto",
          gap: "0",
          width: "100vw",
        }}
      >
        {hits.map((hit) => {
          return <Listing listing={hit} key={hit.id} />;
        })}
      </Box>
      <Button
        disabled={!hasMore}
        onClick={refineNext}
        variant="contained"
        sx={{ width: "90%", margin: "0 auto 40px auto", borderRadius: 0 }}
      >
        + tải thêm
      </Button>
    </React.Fragment>
  );
}

const InfiniteHitsMb = connectInfiniteHits(CustomInfiniteHitsMb);

export default InfiniteHitsMb;
