import React from "react";
import Listing from "../../../components/listing/Listing";
import AddIcon from "@mui/icons-material/Add";
import { useRef } from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useInfiniteHits } from "react-instantsearch";

function InfiniteHitsMb(props) {
  const { hits, showMore, isLastPage } = useInfiniteHits(props);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0vw",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto 30px auto",
          width: "100vw",
        }}
      >
        {hits.map((hit) => {
          return <Listing listing={hit} key={hit.id} />;
        })}
      </Box>
      <Button
        disabled={isLastPage}
        onClick={showMore}
        variant="contained"
        sx={{ width: "90%", margin: "0 auto 40px auto", borderRadius: 0 }}
      >
        + tải thêm
      </Button>
    </React.Fragment>
  );
}

export default InfiniteHitsMb;
