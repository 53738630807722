import React from "react";
import { useParams } from "react-router-dom";
import { doc, getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import MediaQuery from "react-responsive";
import UserProfileD from "./user-profile-d/UserProfileD";
import UserProfileMb from "./user-profile-mb/UserProfileMb";
import { UserContext } from "../../User";
import {
  useRefinementList,
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";

function UserRefined(props) {
  const { uid } = props;
  const { refine, items } = useRefinementList(props);
  React.useEffect(() => {
    refine(uid);
  }, []);
}

function ClearSearchQuery(props) {
  const { refine } = useClearRefinements(props);
  React.useEffect(() => {
    refine();
  }, []);
  return;
}

export default function UserProfile() {
  const username = useParams().username;
  const user = React.useContext(UserContext).user;
  const [profile, setProfile] = React.useState(undefined);

  React.useEffect(() => {
    async function getUser() {
      try {
        const q = query(
          collection(db, "profiles"),
          where("username", "==", username)
        );
        const querySnap = await getDocs(q);
        if (querySnap.empty) {
          return console.log("user does not exist");
        }
        setProfile(querySnap.docs[0].data());
      } catch (err) {
        console.log(err);
      }
    }
    getUser();
  }, [username]);

  React.useEffect(() => {}, [profile]);

  if (!profile) {
    return <div />;
  } else {
    return (
      <React.Fragment>
        <UserRefined attribute="uid" uid={profile.uid} />
        <MediaQuery maxWidth={899}>
          <UserProfileMb profile={profile} />
        </MediaQuery>
        <MediaQuery minWidth={900}>
          <UserProfileD profile={profile} />
        </MediaQuery>
      </React.Fragment>
    );
  }
}
