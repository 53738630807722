import React from "react";
import { Box, Typography, Fade } from "@mui/material";
import SoldListingMb from "../../components/sold-listing/SoldListingMb";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function PurchasedMb({ purchased, pages, page, setPage }) {
  return (
    <Fade in={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2vw",
          mt: "35px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            letterSpacing: "1px",
            fontSize: "20px",
            fontWeight: "bold",
            ml: "7vw",
            mb: "10px",
          }}
        >
          PURCHASED
        </Typography>
        {pages[page].map((soldListing) => {
          return (
            <SoldListingMb
              soldListing={soldListing}
              key={soldListing.listingId}
            />
          );
        })}
        <Box sx={{ mb: "20px" }}>
          <Box
            sx={{
              mt: "10px",
              display: "flex",
              gap: "3px",
              justifyContent: "center",
            }}
          >
            <ArrowBackIosNewIcon
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                if (page > 0) {
                  setPage(page - 1);
                }
              }}
            />

            <ArrowForwardIosIcon
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => {
                if (page < pages.length - 1) {
                  setPage(page + 1);
                }
              }}
            />
          </Box>
          <Typography sx={{ textAlign: "center", fontSize: "15px", mt: "5px" }}>
            {page + 1} / {pages.length}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
