import React from "react";
import { UserContext } from "../../User";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import MediaQuery from "react-responsive";
import PurchasedD from "./PurchasedD";
import PurchasedMb from "./PurchasedMb";
import Error from "../error/Error";

export default function Purchased() {
  const user = React.useContext(UserContext).user;
  const [purchased, setPurchased] = React.useState([]);
  const [pages, setPages] = React.useState([]);
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    async function getPurchased() {
      const purchasedSnap = await getDocs(
        collection(db, `/users/${user.uid}/purchased`)
      );
      if (!purchasedSnap.empty) {
        let purchasedData = [];
        purchasedSnap.forEach((soldListing) => {
          purchasedData.push(soldListing.data());
        });
        purchasedData = purchasedData.sort(function descending(a, b) {
          if (a.timestamp.seconds > b.timestamp.seconds) {
            return -1;
          }
          if (a.timestamp.seconds < b.timestamp.seconds) {
            return 1;
          }
          return 0;
        });
        setPurchased(purchasedData);
      }
    }
    if (user) {
      getPurchased();
    }
  }, [user]);

  React.useEffect(() => {
    const chunkSize = 10;
    let newPages = [];
    for (let i = 0; i < purchased.length; i += chunkSize) {
      const chunk = purchased.slice(i, i + chunkSize);
      newPages.push(chunk);
    }
    console.log(newPages);
    setPages(newPages);
  }, [purchased]);

  if (pages.length === 0) {
    return;
  }

  if (!purchased.length) {
    return (
      <Error
        title="No Purchased Listings"
        content="You don't have any purchased listings. Buy something and come back!"
      />
    );
  }

  return (
    <React.Fragment>
      <MediaQuery minWidth={900}>
        <PurchasedD
          purchased={purchased}
          page={page}
          setPage={setPage}
          pages={pages}
        />
      </MediaQuery>
      <MediaQuery maxWidth={899}>
        <PurchasedMb
          purchased={purchased}
          page={page}
          setPage={setPage}
          pages={pages}
        />
      </MediaQuery>
    </React.Fragment>
  );
}
