import { Button, LinearProgress } from "@mui/material";

export default function LoadingButton({ loading, onClick, variant }) {
  function buttonContent() {
    if (loading.content) {
      return loading.content;
    }
    return <LinearProgress sx={{ width: "90%", margin: "10px 0 10px 0" }} />;
  }

  if (onClick) {
    return (
      <Button
        onClick={onClick}
        variant={variant}
        disabled={loading.disabled}
        sx={{ width: "100%", borderRadius: 0 }}
      >
        {buttonContent()}
      </Button>
    );
  }

  return (
    <Button
      type="submit"
      variant={variant}
      disabled={loading.disabled}
      sx={{ width: "100%", borderRadius: 0 }}
    >
      {buttonContent()}
    </Button>
  );
}
