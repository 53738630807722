import React from "react";
import { UserContext } from "../../User";
import Error from "../error/Error";
import LoadingButton from "../../components/loading-button/LoadingButton";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Box, Typography, TextField } from "@mui/material";

const containerWidth = {
  xxs: "80vw",
  xs: "80vw",
  sm: "80vw",
  md: "40vw",
  lg: "40vw",
  xl: "40vw",
};

export default function Support() {
  const user = React.useContext(UserContext).user;
  const [support, setSupport] = React.useState("");
  const [loading, setLoading] = React.useState({
    content: "gửi yêu cầu",
    disabled: false,
  });
  const [formStatus, setFormStatus] = React.useState({
    content: "",
    color: "error",
  });
  console.log(user);

  function reset() {
    setSupport("");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading({ content: "", disabled: true });
      const timestamp = Date.now();
      const data = {
        content: support,
        username: user.displayName,
        uid: user.uid,
        timestamp,
      };
      await addDoc(collection(db, `/support`), data);
      setFormStatus({ color: "primary.main", content: "request received :)" });
      setLoading({ content: "gửi yêu cầu", disabled: false });
    } catch (err) {
      setFormStatus({ color: "error", content: err.message });
      setLoading({ content: "gửi yêu cầu", disabled: false });
    }
  }

  if (!user) {
    return (
      <Error error={{ main: "bạn cần phải đăng nhập để yêu cầu hỗ trợ" }} />
    );
  }
  return (
    <Box
      onSubmit={handleSubmit}
      component="form"
      sx={{
        width: containerWidth,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "40px auto 0 auto",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "20px",
          letterSpacing: "2px",
          fontWeight: "bold",
          mb: "10px",
        }}
      >
        YÊU CẦU HỖ TRỢ
      </Typography>
      <TextField
        multiline
        minRows={5}
        required
        value={support}
        label="bạn cần hỗ trợ gì?"
        onChange={(e) => {
          setSupport(e.target.value);
        }}
      />
      <Box
        sx={{ display: "flex", gap: "20px", alignItems: "center", mt: "10px" }}
      >
        <LoadingButton loading={loading} variant="contained" />
        <Typography
          color={formStatus.color}
          variant="body2"
          sx={{ fontSize: "14px", width: "100%" }}
        >
          {formStatus.content}
        </Typography>
      </Box>
    </Box>
  );
}
