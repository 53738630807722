import { TextField, MenuItem, Select, Box, ListSubheader } from "@mui/material";

const genderTranslation = {
  m: "nam",
  w: "nữ",
};

const categories = {
  m: [
    { value: "tops", label: "áo" },
    { value: "bottoms", label: "quần" },
    { value: "outerwear", label: "áo khoác" },
    { value: "footwear", label: "giày dép" },
    { value: "accessories", label: "phụ kiện" },
  ],
  w: [
    { value: "tops", label: "áo" },
    { value: "bottoms", label: "quần" },
    { value: "outerwear", label: "áo khoác" },
    { value: "skirts", label: "skirt/chân váy" },
    { value: "dresses", label: "váy" },
    { value: "footwear", label: "giày dép" },
    { value: "accessories", label: "phụ kiện" },
  ],
};

const categoryTranslation = {
  tops: "áo",
  outerwear: "áo khoác",
  bottoms: "quần",
  footwear: "giày dép",
  skirts: "chân váy",
  dresses: "váy",
  accessories: "phụ kiện",
};

const subcategoryTranslation = {
  tees: "áo phông",
  tube: "tube top",
  athletic: "áo thể thao",
  jersey: "áo đội bóng",
  polos: "áo polo",
  shirts: "áo sơ mi",
  cardigan: "cardigan",
  "sweatshirts-hoodies": "hoodie / sweatshirt",
  "sweaters-knitwear": "áo len",
  other: "khác",
  "cargo-pants": "quần cargo",
  "military-pants": "quần military",
  "work-pants": "quần workwear",
  jeans: "quần jeans",
  chinos: "quần chino",
  "sweats-joggers": "quần nỉ (sweatpants)",
  "casual-pants": "quần casual",
  "dress-pants": "quần dress-pants",
  "track-pants": "quần thể thao",
  shorts: "quần đùi (shorts)",
  swimwear: "đồ bơi",
  "denim-jacket": "áo khoác denim",
  "leather-jacket": "áo da",
  "military-jacket": "áo khoác military / quần đội",
  "bomber-jacket": "áo khoác bomber",
  "wool-jacket": "áo khoác len",
  "felt-jacket": "áo khoác dạ",
  "work-jacket": "áo khoác workwear",
  "track-jacket": "áo khoác thể thao",
  puffer: "áo khoác puffer",
  windbreaker: "áo chống gió",
  parka: "áo khoác parka",
  boots: "boots",
  "low-sneakers": "sneaker low",
  "high-sneakers": "sneaker high",
  bags: "túi",
  watches: "đồng hồ",
  hats: "mũ",
  belts: "thắt lưng",
  glasses: "kính mắt",
  gloves: "găng tay",
  jewelry: "trang sức",
  wallets: "ví",
  "baby-tee": "baby tee",
  "tank-tops": "áo ba lỗ",
  "crop-tops": "áo crop top",
  blouses: "áo cánh",
  "body-suits": "body-suit",
  leggings: "leggings",
  short: "ngắn / mini",
  long: "dài / midi",
  "jump-suits": "áo liền quần / jumpsuit",
  skort: "quần giả váy / skort",
  necklaces: "vòng cổ",
  rings: "nhẫn",
  "hair-accessories": "phụ kiện tóc",
  scarves: "khăn quàng cổ",
};

const subCategories = {
  m: {
    tops: [
      "tees",
      "jersey",
      "athletic",
      "polos",
      "shirts",
      "tank-tops",
      "sweatshirts-hoodies",
      "sweaters-knitwear",
      "other",
    ],
    bottoms: [
      "jeans",
      "work-pants",
      "cargo-pants",
      "military-pants",
      "track-pants",
      "sweats-joggers",
      "chinos",
      "casual-pants",
      "dress-pants",
      "shorts",
      "swimwear",
      "other",
    ],
    outerwear: [
      "denim-jacket",
      "leather-jacket",
      "military-jacket",
      "bomber-jacket",
      "track-jacket",
      "wool-jacket",
      "felt-jacket",
      "work-jacket",
      "puffer",
      "windbreaker",
      "parka",
      "other",
    ],
    footwear: [
      "boots",
      "loafer",
      "low-sneakers",
      "high-sneakers",
      "derby",
      "oxford",
      "other",
    ],
    accessories: [
      "scarves",
      "bags",
      "watches",
      "hats",
      "belts",
      "glasses",
      "gloves",
      "necklaces",
      "rings",
      "wallets",
      "other",
    ],
  },
  w: {
    tops: [
      "tees",
      "tube",
      "shirts",
      "jersey",
      "baby-tee",
      "crop-tops",
      "tank-tops",
      "cardigan",
      "sweatshirts-hoodies",
      "sweaters-knitwear",
      "blouses",
      "body-suits",
      "polos",
      "other",
    ],
    bottoms: [
      "jeans",
      "dress-pants",
      "leggings",
      "sweats-joggers",
      "track-pants",
      "shorts",
      "work-pants",
      "cargo-pants",
      "military-pants",
      "chinos",
      "other",
    ],
    outerwear: [
      "denim-jacket",
      "leather-jacket",
      "military-jacket",
      "wool-jacket",
      "work-jacket",
      "track-jacket",
      "puffer",
      "windbreaker",
      "parka",
      "other",
    ],
    footwear: [
      "boots",
      "casual",
      "dress",
      "low-sneakers",
      "high-sneakers",
      "other",
    ],
    dresses: ["long", "short", "jump-suits"],
    skirts: ["short", "long", "skort"],
    accessories: [
      "bags",
      "necklaces",
      "scarves",
      "rings",
      "watches",
      "hats",
      "belts",
      "glasses",
      "gloves",
      "wallets",
      "hair-accessories",
      "other",
    ],
  },
};

const sizes = {
  m: {
    tops: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    bottoms: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
    ],
    outerwear: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    footwear: [
      "one-size",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
  },
  w: {
    tops: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    bottoms: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "22",
      "24",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
    ],
    outerwear: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    footwear: [
      "one-size",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    skirts: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "xxxl",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
    ],
    dresses: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "xxxl",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
    ],
  },
};

function Category({ handleChange, category, gender }) {
  return (
    <Select
      required={true}
      value={gender === "" ? "" : `${gender}.${category}`}
      onChange={handleChange}
      renderValue={(selected) => {
        return `${genderTranslation[selected[0]]} / ${
          categoryTranslation[selected.slice(2)]
        }`;
      }}
    >
      <ListSubheader>nữ</ListSubheader>
      {categories.w.map((category) => {
        return (
          <MenuItem
            size="small"
            dense
            value={`w.${category.value}`}
            key={`w.${category.value}`}
            sx={{
              fontSize: "14px",
              padding: "0 15px 0 25px",
              height: "20px",
            }}
          >
            {category.label}
          </MenuItem>
        );
      })}
      <ListSubheader>nam</ListSubheader>
      {categories.m.map((category) => {
        return (
          <MenuItem
            size="small"
            dense
            value={`m.${category.value}`}
            key={`m.${category.value}`}
            sx={{
              fontSize: "14px",
              padding: "0 15px 0 25px",
              height: "20px",
            }}
          >
            {category.label}
          </MenuItem>
        );
      })}
    </Select>
  );
}

function SubCategory({ handleChange, category, gender, sub }) {
  if (!category) {
    return (
      <TextField
        disabled
        select
        label="tiểu loại / chọn loại trước"
        InputLabelProps={{ sx: { fontSize: "14px" } }}
      >
        <MenuItem></MenuItem>
      </TextField>
    );
  }
  return (
    <Select
      required={true}
      onChange={handleChange}
      value={sub}
      renderValue={(selected) => {
        return `${subcategoryTranslation[selected]}`;
      }}
    >
      {subCategories[gender][category].map((category) => {
        return (
          <MenuItem
            dense
            size="small"
            value={category}
            key={category}
            sx={{
              fontSize: "14px",
              padding: "0 15px 0 15px",
              height: "20px",
            }}
          >
            {subcategoryTranslation[category]}
          </MenuItem>
        );
      })}
    </Select>
  );
}

function Size({ handleChange, gender, category, size }) {
  if (!category) {
    return (
      <TextField
        disabled
        select
        label="size / chọn loại trước"
        InputLabelProps={{ sx: { fontSize: "14px" } }}
      >
        <MenuItem></MenuItem>
      </TextField>
    );
  }
  if (category === "accessories") {
    return;
  }
  return (
    <Select required={true} onChange={handleChange} value={size}>
      {sizes[gender][category].map((size) => {
        return (
          <MenuItem
            dense
            size="small"
            value={size}
            key={size}
            sx={{
              fontSize: "15px",
              padding: "0 15px 0 15px",
              height: "30px",
            }}
          >
            {size}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default function Type({
  gender,
  category,
  sub,
  size,
  handleGenderCategory,
  handleSub,
  handleSize,
}) {
  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        mt: "15px",
        gap: "15px",
      }}
    >
      <Category
        category={category}
        gender={gender}
        handleChange={handleGenderCategory}
      />
      <SubCategory
        handleChange={handleSub}
        sub={sub}
        category={category}
        gender={gender}
      />
      <Size
        handleChange={handleSize}
        gender={gender}
        category={category}
        size={size}
      />
    </Box>
  );
}
