import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import ListingSwiper from "../listing-swiper/ListingSwiper";
import { UserContext } from "../../User";
import { LegendToggleRounded } from "@mui/icons-material";

export default function Recent() {
  const user = React.useContext(UserContext).user;
  const [listings, setListings] = React.useState([]);

  React.useEffect(() => {
    async function getItemsUser() {
      const userSnap = await getDoc(doc(db, "users", user.uid));
      const recentlyViewed = userSnap.data().recentlyViewed;
      if (!recentlyViewed) {
        return;
      }
      let listingSnaps = await Promise.all(
        recentlyViewed.map((item) => {
          return getDoc(doc(db, `listings`, item));
        })
      );
      listingSnaps = listingSnaps.filter((snap) => snap.exists());
      console.log(listingSnaps);
      const newListings = listingSnaps.map((snap) => {
        let data = snap.data();
        data.id = snap.id;
        return data;
      });
      newListings.reverse();
      setListings(newListings);
    }

    async function getItemsGuest() {
      const recentlyViewed = JSON.parse(localStorage.getItem("recentlyViewed"));
      if (!recentlyViewed) {
        return;
      }
      console.log(recentlyViewed);
      let listingSnaps = await Promise.all(
        recentlyViewed.map((item) => {
          return getDoc(doc(db, `listings`, item));
        })
      );
      listingSnaps = listingSnaps.filter((snap) => snap.exists());
      const newListings = listingSnaps.map((snap) => {
        let data = snap.data();
        data.id = snap.id;
        return data;
      });
      newListings.reverse();
      setListings(newListings);
    }

    async function getItems() {
      if (user) {
        getItemsUser();
      } else {
        getItemsGuest();
      }
    }

    getItems();
  }, [user]);
  if (listings.length == 0) {
    return;
  }
  return <ListingSwiper listings={listings} label="xem gần đây" />;
}
