import React from "react";
import Success from "../success/Success";
import Error from "../error/Error";
import { applyActionCode, signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";

export default function VerifyEmail({ actionCode }) {
  const [verified, setVerified] = React.useState(false);
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    applyActionCode(auth, actionCode)
      .then(async () => {
        await signOut(auth);
        setVerified(true);
      })
      .catch((err) => {
        setError(err.message);
        setVerified(false);
      });
  }, []);

  if (error) {
    return <Error title="ERROR" content={error} />;
  }
  if (!verified) {
    return;
  }
  return (
    <Success
      title="Thành Công"
      content="Xác nhận email thành công. Vui lòng đăng nhập lại."
    />
  );
}
