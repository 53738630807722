import React from "react";
import { List, ListItem, ListItemText, Collapse, Divider } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SubRefinementList from "./SubRefinementList";
import { connectRefinementList } from "react-instantsearch-dom";
import { transformItems } from "../../../components/sizes/Sizes";
import { useRefinementList } from "react-instantsearch";

function SizeList(props) {
  const { refine, items, currentRefinements } = useRefinementList(props);
  const [open, setOpen] = React.useState(false);
  const [newItems, setNewItems] = React.useState(transformItems(items));

  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setNewItems(transformItems(items));
  }, [items]);

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "7px 15px 7px 15px",
          width: "100%",
        }}
        inset="true"
      >
        <ListItemText
          primary="size"
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        />
        {open ? <ExpandLess fontSize="10px" /> : <ExpandMore fontSize="10px" />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        sx={{
          padding: "0 13px 0 13px",
        }}
      >
        <List disablePadding sx={{ marginBottom: "16px" }}>
          <ListItem
            sx={{
              padding: "0 10px 6px 10px",
            }}
            inset="true"
          >
            <ListItemText
              primary="nam"
              primaryTypographyProps={{
                sx: {
                  fontSize: "15px",
                  fontWeight: "bold",
                },
              }}
            />
          </ListItem>
          <SubRefinementList items={newItems.m.tops} refine={refine} sub="áo" />
          <SubRefinementList
            items={newItems.m.outerwear}
            refine={refine}
            sub="áo khoác"
          />
          <SubRefinementList
            items={newItems.m.bottoms}
            refine={refine}
            sub="quần"
          />
          <SubRefinementList
            items={newItems.m.footwear}
            refine={refine}
            sub="giày"
          />
          <ListItem
            sx={{
              padding: "0 10px 6px 10px",
            }}
            inset="true"
          >
            <ListItemText
              primary="nữ"
              primaryTypographyProps={{
                sx: {
                  marginTop: "16px",
                  fontSize: "15px",
                  fontWeight: "bold",
                },
              }}
            />
          </ListItem>
          <SubRefinementList items={newItems.w.tops} refine={refine} sub="áo" />
          <SubRefinementList
            items={newItems.w.outerwear}
            refine={refine}
            sub="áo khoác"
          />
          <SubRefinementList
            items={newItems.w.dresses}
            refine={refine}
            sub="váy"
          />
          <SubRefinementList
            items={newItems.w.bottoms}
            refine={refine}
            sub="quần"
          />
          <SubRefinementList
            items={newItems.w.footwear}
            refine={refine}
            sub="giày"
          />
        </List>
      </Collapse>
      <Divider sx={{ backgroundColor: "primary.main" }} />
    </React.Fragment>
  );
}

export default SizeList;
