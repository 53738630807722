import React from "react";
import { db } from "../../firebaseConfig";
import { getDocs, collection, doc, updateDoc } from "firebase/firestore";
import { isAfter, isBefore, formatDistanceToNow } from "date-fns";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Modal,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LoadingButton from "../../components/loading-button/LoadingButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "34px",
  alignItems: "center",
  pb: "30px",
  pt: "30px",
};

export default function Scraped() {
  const [pageNumber, setPageNumber] = React.useState(1);
  const [page, setPage] = React.useState([]);
  const [sellers, setSellers] = React.useState([]);
  const [loading, setLoading] = React.useState({
    content: "lưu",
    disabled: false,
  });
  const [open, setOpen] = React.useState(false);
  const [confirmed, setConfirmed] = React.useState(undefined);
  const [seller, setSeller] = React.useState(undefined);

  async function submit(e) {
    e.preventDefault();
    try {
      setLoading({ content: "", disabled: true });
      const docRef = doc(db, "scraped", seller.IG);
      await updateDoc(docRef, { confirmed });
      setLoading({ content: "lưu", disabled: false });
      setSeller(undefined);
      setOpen(false);
    } catch (err) {
      console.log(err);
      setLoading({ content: "lưu", disabled: false });
    }
  }

  function modalContent() {
    return (
      <Box component="form" sx={modalStyle} onSubmit={submit}>
        <Box sx={{ width: "80%" }}>
          <Select
            sx={{ width: "100%" }}
            onChange={(e) => {
              setConfirmed(e.target.value);
            }}
          >
            <MenuItem value={true}>đống ý</MenuItem>
            <MenuItem value={false}>từ chối</MenuItem>
          </Select>
          <LoadingButton loading={loading} variant="contained" />
        </Box>
      </Box>
    );
  }

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  function sortListings() {
    function compareNumListings(a, b) {
      if (a.listings.length > b.listings.length) {
        return -1;
      } else if (a.listings.length < b.listings.length) {
        return 1;
      }
      return 0;
    }
    let newSellers = [...sellers];
    newSellers.sort(compareNumListings);
    setSellers(newSellers);
  }

  function sortDate() {
    function compareDate(a, b) {
      if (isBefore(a.mostRecent, b.mostRecent)) {
        return 1;
      } else if (!isBefore(a.mostRecent, b.mostRecent)) {
        return -1;
      }
      return 0;
    }
    let newSellers = [...sellers];
    newSellers.sort(compareDate);
    setSellers(newSellers);
  }

  function sortOption(e) {
    const option = e.target.value;
    if (option === "date") {
      sortDate();
      return;
    }
    if (option == "number") {
      sortListings();
      return;
    }
  }

  React.useEffect(() => {
    const newPage = paginate(sellers, 20, pageNumber);
  }, [pageNumber, sellers]);

  React.useEffect(() => {
    async function getSellers() {
      const qSnap = await getDocs(collection(db, "scraped"));
      const newSellers = [];
      qSnap.forEach((doc) => {
        let seller = doc.data();
        seller.mostRecent = seller.mostRecent.toDate();
        newSellers.push(seller);
      });
      setSellers(newSellers);
    }
    getSellers();
  }, []);

  function SellerConfirmed({ confirmed }) {
    if (confirmed) {
      return (
        <Typography sx={{ color: "success.main", width: "100px" }}>
          có
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ color: "yellow", width: "100px" }}>chưa</Typography>
      );
    }
  }

  function SellerName({ seller, IGLink }) {
    if (!seller.favorite) {
      return (
        <a href={IGLink}>
          <Typography sx={{ width: "180px" }}>{seller.IG}</Typography>
        </a>
      );
    } else {
      return (
        <Box sx={{ display: "flex", gap: "5px", width: "180px" }}>
          <StarBorderIcon />
          <a href={IGLink}>
            <Typography sx={{ width: "180px" }}>{seller.IG}</Typography>
          </a>
        </Box>
      );
    }
  }

  function SellerGender({ gender }) {
    if (gender == "m") {
      return (
        <Typography sx={{ color: "#6495ED", width: "100px" }}>nam</Typography>
      );
    }
    if (gender == "w") {
      return <Typography sx={{ color: "pink", width: "100px" }}>nữ</Typography>;
    }
    return <Typography sx={{ width: "100px" }}>error</Typography>;
  }

  function newPosts(newPosts) {
    if (!newPosts) {
      return "error";
    }
    if (newPosts.length == 30) {
      return "30+";
    }
    return newPosts.length;
  }

  if (sellers.length == 0) {
    return;
  } else {
    return (
      <Box sx={{ mb: "30px" }}>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            justifyContent: "center",
            mb: "30px",
            mt: "30px",
          }}
        >
          <TextField
            select
            onChange={sortOption}
            label="xếp"
            sx={{ width: "150px" }}
          >
            <MenuItem value="number"># sản phẩm</MenuItem>
            <MenuItem value="date">ngày đăng</MenuItem>
          </TextField>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "15px",
            width: "70%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "40px",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <Typography sx={{ width: "30px" }}>#</Typography>
            <Typography sx={{ width: "180px" }}>@IG</Typography>
            <Typography sx={{ width: "100px" }}>đống ý</Typography>
            <Typography sx={{ width: "100px" }}># chưa đăng</Typography>
            <Typography sx={{ width: "100px" }}>giới tính</Typography>
            <Typography sx={{ width: "100px" }}>date</Typography>
          </Box>
          {sellers.map((seller) => {
            const IGLink = `https://instagram.com/${seller.IG}`;
            return (
              <Box
                sx={{
                  display: "flex",
                  gap: "40px",
                  alignItems: "center",
                  justifyContent: "start",
                }}
                key={seller.IG}
              >
                <Typography sx={{ width: "30px" }}>
                  {seller.listings.length}
                </Typography>
                <SellerName IGLink={IGLink} seller={seller} />
                <SellerConfirmed confirmed={seller.confirmed} />
                <Typography sx={{ width: "100px" }}>
                  {newPosts(seller.newPosts)}
                </Typography>

                <SellerGender gender={seller.gender} />
                <Typography sx={{ width: "100px" }}>
                  {formatDistanceToNow(seller.mostRecent)}
                </Typography>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setSeller(seller);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          {modalContent()}
        </Modal>
      </Box>
    );
  }
}
