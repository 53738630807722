import React from "react";
import { Box, TextField, Typography, Button } from "@mui/material";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import ErrorPage from "../error/Error";
import LoadingButton from "../../components/loading-button/LoadingButton";

const containerSize = {
  xxs: "75vw",
  xs: "75vw",
  sm: "75vw",
  md: "30vw",
  lg: "30vw",
  xl: "30vw",
};

export default function ResetPassword({ actionCode }) {
  const [verified, setVerified] = React.useState(false);
  const [error, setError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verify, setVerify] = React.useState("");
  const [loading, setLoading] = React.useState({
    content: "đổi mật khẩu",
    disabled: false,
  });
  const [formStatus, setFormStatus] = React.useState({
    content: "",
    color: "error",
  });

  React.useEffect(() => {
    verifyPasswordResetCode(auth, actionCode)
      .then(() => {
        setVerified(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading({ content: "", disabled: true });
      if (verify !== password) {
        throw new Error("passwords do not match");
      }
      console.log("different passwords");
      if (password.length < 8) {
        throw new Error("password must contain atleast 8 characters");
      }
      await confirmPasswordReset(auth, actionCode, password);
      setFormStatus({
        content: "đổi mật khẩu thành công",
        color: "primary.main",
      });
      setLoading({ content: "đổi mật khẩu", disabled: true });
    } catch (err) {
      setFormStatus({ content: err.message, color: "error" });
      setLoading({ content: "đổi mật khẩu", disabled: false });
    }
  }

  if (error) {
    return <ErrorPage title="ERROR" content={error} />;
  }
  if (!verified) {
    return;
  }
  return (
    <Box
      onSubmit={handleSubmit}
      component="form"
      sx={{
        width: containerSize,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "50px auto 0 auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: "20px", fontWeight: "bold", mb: "20px" }}
      >
        ĐỔI MẬT KHẨU
      </Typography>
      <TextField
        value={password}
        inputProps={{ type: "password" }}
        label="mật khẩu mới"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></TextField>
      <TextField
        value={verify}
        inputProps={{ type: "password" }}
        label="xác nhận mật khẩu"
        onChange={(e) => {
          setVerify(e.target.value);
        }}
      ></TextField>
      <LoadingButton loading={loading} variant="contained" />
      <Typography color={formStatus.color}>{formStatus.content} </Typography>
    </Box>
  );
}
