import { auth } from "./firebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from "react";
import React from "react";
import { CircularProgress, Box } from "@mui/material";

const UserContext = React.createContext();

function User({ children }) {
  const [user, setUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setPending(false);
      } else {
        setUser(null);
        setPending(false);
      }
    });
  }, []);
  
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
}

export { User, UserContext };
