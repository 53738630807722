import React from "react";
import { Box, Typography, Button, LinearProgress } from "@mui/material";

const containerSize = {
  xxs: "74%",
  xs: "74%",
  sm: "74%",
  md: "37%",
  lg: "37%",
  xl: "37%",
};

export default function PublishFooter({ submitButton }) {
  function buttonContent() {
    if (submitButton.content) {
      return "đăng";
    }
    return <LinearProgress sx={{ width: "90%", margin: "10px 0 10px 0" }} />;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        bgcolor: "background.default",
        display: "flex",
        padding: "15px 0 15px 0",
        gap: "20px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: containerSize,
          gap: "5vw",
          margin: "auto",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Button
          disabled={submitButton.disabled}
          type="submit"
          sx={{ width: "41%", borderRadius: 0 }}
          variant="contained"
        >
          {buttonContent()}
        </Button>
      </Box>
    </Box>
  );
}
