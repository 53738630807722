import React from "react";
import { UserContext } from "../../User";
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import { Box } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { db } from "../../firebaseConfig";
import SignupModal from "../auth-forms/SignupModal";
import MediaQuery from "react-responsive";

export default function SaveNoCount({ listingId }) {
  const [save, setSave] = React.useState(true);
  const user = React.useContext(UserContext).user;

  async function handleSave() {
    const docRef = doc(db, `listings/${listingId}/saves/${user.uid}`);
    if (save) {
      setSave(false);
      await deleteDoc(docRef);
      return;
    }
    setSave(true);
    await setDoc(docRef, { user: user.displayName, uid: user.uid });
    return;
  }
  if (user) {
    if (save) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
            width: "28px",
          }}
        >
          <FavoriteIcon
            size="small"
            onClick={handleSave}
            sx={{
              stroke: "#121212",
              strokeWidth: 1,
              fontSize: "27px",
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
                transition: "all .2s ease-in-out",
              },
            }}
          />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FavoriteBorderIcon
          onClick={handleSave}
          sx={{
            fontSize: "28px",
            stroke: "#121212",
            strokeWidth: 1,
            "&:hover": {
              cursor: "pointer",
              transform: "scale(1.05)",
              transition: "all .2s ease-in-out",
            },
          }}
        />
      </Box>
    );
  }
  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SignupModal
            Component={({ handleClick }) => {
              return (
                <FavoriteBorderIcon
                  onClick={handleClick}
                  sx={{
                    fontSize: "28px",
                    stroke: "#121212",
                    strokeWidth: 1,
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.05)",
                      transition: "all .2s ease-in-out",
                    },
                  }}
                />
              );
            }}
          />
        </Box>
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SignupModal
            Component={({ handleClick }) => {
              return (
                <FavoriteBorderIcon
                  onClick={handleClick}
                  sx={{
                    fontSize: "28px",
                    stroke: "#121212",
                    strokeWidth: 1,
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.05)",
                      transition: "all .2s ease-in-out",
                    },
                  }}
                />
              );
            }}
          />
        </Box>
      </MediaQuery>
    </React.Fragment>
  );
}
