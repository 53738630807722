import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { doc, deleteDoc } from "firebase/firestore";
import { UserContext } from "../../User";
import { db } from "../../firebaseConfig";
import LoadingButton from "../loading-button/LoadingButton";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

export default function Delete({ listingId, size, fontSize }) {
  const user = React.useContext(UserContext).user;
  const navigate = useNavigate();
  const location = useLocation();
  const userUrl = `/u/${user.displayName}`;
  const [dialog, setDialog] = React.useState(false);
  const [loading, setLoading] = React.useState({
    content: "Xoá",
    disabled: false,
  });
  const [disabled, setDisabled] = React.useState(false);

  async function deleteListing() {
    try {
      setLoading({ content: "", disabled: true });
      await deleteDoc(doc(db, `listings/${listingId}`));
      setDialog(false);
      if (!location.pathname.includes(userUrl)) {
        navigate(userUrl);
      }
      setDisabled(true);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <Button
        disabled={disabled}
        size={size}
        variant="outlined"
        sx={{ width: "100%", borderRadius: 0, fontSize: fontSize }}
        onClick={() => {
          setDialog(true);
        }}
      >
        Xoá
      </Button>
      <Dialog
        open={dialog}
        onClose={() => {
          setDialog(!dialog);
        }}
        PaperProps={{
          sx: {
            padding: "10px",
            backgroundColor: "background.paper",
            borderRadius: 0,
            border: "0.5px solid",
            borderColor: "primary.main",
          },
        }}
      >
        <DialogTitle
          variant="h2"
          sx={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          Delete your listing? <SentimentDissatisfiedIcon />
        </DialogTitle>
        <DialogActions sx={{ display: "flex", gap: "10px" }}>
          <LoadingButton
            loading={loading}
            onClick={deleteListing}
            variant="contained"
          />
          <Button
            variant="outlined"
            onClick={() => {
              setDialog(false);
            }}
            sx={{ width: "100%" }}
          >
            Huỷ
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
