import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import SoldListingMb from "../../components/sold-listing/SoldListingMb";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function SoldMb({ sold, revenue, page, setPage, pages }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2vw",
        mt: "35px",
        mb: "35px",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          letterSpacing: "1px",
          fontSize: "20px",
          fontWeight: "bold",
          ml: "7vw",
          mb: "10px",
        }}
      >
        SOLD
      </Typography>
      <Box
        sx={{
          display: "flex",
          margin: "10px auto 20px auto",
          width: "90%",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Link to="/sell/new">
          <Button variant="outlined" size="large" startIcon={<AddIcon />}>
            đăng bán
          </Button>
        </Link>
        <Typography sx={{ fontSize: "18px" }}>
          <strong>{revenue.toLocaleString()}k</strong> /{" "}
          <strong>{sold.length}</strong> đơn
        </Typography>
      </Box>
      {pages[page].map((soldListing) => {
        return (
          <SoldListingMb
            soldListing={soldListing}
            key={soldListing.listingId}
          />
        );
      })}
      <Box>
        <Box
          sx={{
            mt: "10px",
            display: "flex",
            gap: "3px",
            justifyContent: "center",
          }}
        >
          <ArrowBackIosNewIcon
            sx={{ "&:hover": { cursor: "pointer" } }}
            onClick={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
          />

          <ArrowForwardIosIcon
            sx={{ "&:hover": { cursor: "pointer" } }}
            onClick={() => {
              if (page < pages.length - 1) {
                setPage(page + 1);
              }
            }}
          />
        </Box>
        <Typography sx={{ textAlign: "center", fontSize: "15px", mt: "5px" }}>
          {page + 1} / {pages.length}
        </Typography>
      </Box>
    </Box>
  );
}
