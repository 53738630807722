import React from "react";
import { useParams } from "react-router-dom";
import MediaQuery from "react-responsive";
import { UserContext } from "../../User";
import {
  useRefinementList,
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";
import SearchD from "../search-copy/search-d/SearchD";
import SearchMb from "../search-copy/search-mb/SearchMb";

function CollectionRefined(props) {
  const { collection } = props;
  const { refine, items } = useRefinementList(props);
  React.useEffect(() => {
    console.log("refining");
    refine(collection);
  }, []);
}

function ClearSearchQuery(props) {
  const { refine } = useClearRefinements(props);
  React.useEffect(() => {
    refine();
  }, []);
  return;
}

export default function Collection() {
  const collection = useParams().collection;
  const user = React.useContext(UserContext).user;

  return (
    <React.Fragment>
      <CollectionRefined attribute="collections" collection={collection} />
      <MediaQuery maxWidth={899}>
        <SearchMb />
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <SearchD />
      </MediaQuery>
    </React.Fragment>
  );
}
