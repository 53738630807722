import React from "react";
import Listing from "../../../components/listing/Listing";
import { Box, Button } from "@mui/material";
import { connectInfiniteHits } from "react-instantsearch-dom";

function CustomInfiniteHits({ hits, refineNext, hasMore }) {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1vw",
          margin: "auto",
          alignItems: "center",
          justifyContent: "start",
          margin: "0px auto",
        }}
      >
        {hits.map((hit) => {
          return <Listing listing={hit} key={hit.id} />;
        })}
      </Box>
      <Button
        size="large"
        disabled={!hasMore}
        onClick={refineNext}
        variant="contained"
        sx={{ width: "20%", margin: "50px auto 30px 80%", borderRadius: 0 }}
      >
        + tải thêm
      </Button>
    </React.Fragment>
  );
}

const InfiniteHits = connectInfiniteHits(CustomInfiniteHits);

export default InfiniteHits;
