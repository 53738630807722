import React from "react";
import Listing from "../../../components/listing/Listing";
import { Box, Button } from "@mui/material";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { useInfiniteHits } from "react-instantsearch";

export default function InfiniteHits(props) {
  const { hits, showMore, isLastPage } = useInfiniteHits(props);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1vw",
          margin: "auto",
          alignItems: "center",
          justifyContent: "start",
          margin: "0px auto",
        }}
      >
        {hits.map((hit) => {
          return <Listing listing={hit} key={hit.id} />;
        })}
      </Box>
      <Button
        size="large"
        disabled={isLastPage}
        onClick={showMore}
        variant="contained"
        sx={{ width: "20%", margin: "50px auto 30px 80%", borderRadius: 0 }}
      >
        + tải thêm
      </Button>
    </React.Fragment>
  );
}
