import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { db } from "../../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";
import ActionButtons from "./ActionButtons";

const statusTranslations = {
  processing: "đang xử lý",
  shipped: "đã ship",
  cancelled: "đã bị huỷ",
  received: "đã nhận",
};

function ListingInfo({ listing, sellerUsername }) {
  const sellerUrl = `/u/${sellerUsername}`;

  return (
    <Box
      sx={{
        width: "10vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Link
        to={sellerUrl}
        style={{ textDecoration: "none", margin: 0, padding: 0 }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "primary.main",
            textDecoration: "underline",
          }}
        >
          {sellerUsername}
        </Typography>
      </Link>
      <Typography sx={{ fontSize: "14px", mt: "5px" }}>
        {listing.title}
      </Typography>
    </Box>
  );
}

function PurchaseInfo({ soldDate, payment, listing }) {
  return (
    <Box
      sx={{
        width: "10vw",
      }}
    >
      <Typography sx={{ fontSize: "16px" }}>
        {Number(listing.price) + Number(listing.shipping)}k /{" "}
        {payment.toUpperCase()}
      </Typography>
      <Typography sx={{ color: "secondary.main", mt: "5px" }}>
        {format(soldDate, "dd/MM/yyyy")}
      </Typography>
      <Typography sx={{ color: "secondary.main" }}>
        {formatDistanceToNow(soldDate)} ago
      </Typography>
    </Box>
  );
}

function Status({ status, tracking }) {
  const [statusColor, setStatusColor] = React.useState("yellow");

  React.useEffect(() => {
    if (status === "shipped" || status === "received") {
      setStatusColor("success.main");
    } else if (status === "cancelled") {
      setStatusColor("error");
    } else {
      setStatusColor("yellow");
    }
  }, [status]);

  if (tracking) {
    return (
      <Box
        sx={{
          width: "10vw",
        }}
      >
        <Typography sx={{ fontSize: "16px" }} color={statusColor}>
          {statusTranslations[status]}
        </Typography>
        <Typography sx={{ mt: "5px" }}>
          {tracking.courier} / {tracking.code}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "10vw",
      }}
    >
      <Typography sx={{ fontSize: "16px" }} color={statusColor}>
        {statusTranslations[status]}
      </Typography>
    </Box>
  );
}

function DisplayAddress({ address, buyerUsername }) {
  const buyerUrl = `/u/${buyerUsername}`;
  console.log(address);

  return (
    <Box
      sx={{
        width: "10vw",
      }}
    >
      <Link
        to={buyerUrl}
        style={{ textDecoration: "none", margin: 0, padding: 0 }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "primary.main",
            textDecoration: "underline",
          }}
        >
          {buyerUsername}
        </Typography>
      </Link>
      <Typography sx={{ fontSize: "14px", mt: "5px" }}>
        {address.name} {address.phone}
        <br />
        {address.street}
        <br />
        {address.ward}
        {", "}
        {address.district}
        {", "}
        {address.city}
      </Typography>
    </Box>
  );
}

export default function SoldListingD({ soldListing }) {
  const {
    listing,
    address,
    buyer,
    seller,
    listingId,
    timestamp,
    payment,
    status,
    tracking,
  } = soldListing;
  const [currentStatus, setCurrentStatus] = React.useState(status);
  const [sellerUsername, setSellerUsername] = React.useState(undefined);
  const [buyerUsername, setBuyerUsername] = React.useState(undefined);
  const soldDate = timestamp.toDate();
  const listingUrl = `/listing/${listingId}`;

  React.useEffect(() => {
    async function getInfo() {
      const [buyerSnap, sellerSnap] = await Promise.all([
        getDoc(doc(db, `/profiles/${buyer.uid}`)),
        getDoc(doc(db, `/profiles/${seller.uid}`)),
      ]);
      setBuyerUsername(buyerSnap.data().username);
      setSellerUsername(sellerSnap.data().username);
    }
    getInfo();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        margin: "auto",
        justifyContent: "center",
      }}
    >
      <Box sx={{ height: "15vw", width: "15vw" }}>
        <Link to={listingUrl}>
          <img
            src={listing.images[0]}
            style={{ height: "100%", objectFit: "cover", width: "15vw" }}
          />
        </Link>
      </Box>
      <Box
        sx={{
          border: "0.5px solid",
          borderColor: "secondary.dark",
          display: "flex",
          width: "60vw",
          justifyContent: "space-between",
          padding: "1vw 1vw 1vw 2vw",
        }}
      >
        <ListingInfo listing={listing} sellerUsername={sellerUsername} />
        <DisplayAddress address={address} buyerUsername={buyerUsername} />
        <Status status={currentStatus} tracking={tracking} />
        <PurchaseInfo soldDate={soldDate} payment={payment} listing={listing} />
      </Box>
      <ActionButtons
        status={currentStatus}
        setStatus={setCurrentStatus}
        listingId={listingId}
        timestamp={timestamp}
      />
    </Box>
  );
}
