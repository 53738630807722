import React from "react";
import ListingDetailsMb from "./listing-details-mb/ListingDetailsMb";
import ListingDetailsD from "./listing-details-d/ListingDetailsD";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, functions } from "../../firebaseConfig";
import { UserContext } from "../../User";
import { httpsCallable } from "firebase/functions";

import MediaQuery from "react-responsive";

export default function ListingDetails() {
  const listingId = useParams().listingId;
  const [listing, setListing] = React.useState(undefined);
  const user = React.useContext(UserContext).user;

  React.useEffect(() => {
    async function recentlyViewedUser() {
      const recentlyViewed = httpsCallable(functions, "recentlyViewed");
      await recentlyViewed({ listingId });
    }

    function recentlyViewedGuest() {
      let recentlyViewed = localStorage.getItem("recentlyViewed");
      if (!recentlyViewed) {
        recentlyViewed = [];
      } else {
        recentlyViewed = JSON.parse(recentlyViewed);
      }
      for (let i = 0; i < recentlyViewed.length; i++) {
        const value = recentlyViewed[i];
        if (value === listingId) {
          recentlyViewed.splice(i, 1);
          break;
        }
      }
      recentlyViewed.push(listingId);
      if (recentlyViewed.length > 20) {
        recentlyViewed.splice(0, 1);
      }
      localStorage.setItem("recentlyViewed", JSON.stringify(recentlyViewed));
    }
    if (user) {
      console.log("user");
      recentlyViewedUser();
    } else {
      recentlyViewedGuest();
    }
  }, [user]);

  React.useEffect(() => {
    async function getInfo() {
      try {
        const listingSnap = await getDoc(doc(db, "listings", listingId));
        const data = listingSnap.data();
        data.listingId = listingId;
        setListing(data);
        return;
      } catch (err) {
        console.log(err);
      }
    }
    getInfo();
  }, []);

  if (!listing) {
    return <div />;
  }
  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <ListingDetailsMb listing={listing} />
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <ListingDetailsD listing={listing} />
      </MediaQuery>
    </React.Fragment>
  );
}
