import React from "react";
import { useLocation } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import VerifyEmail from "./VerifyEmail";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Action() {
  let query = useQuery();
  const [mode, setMode] = React.useState("");
  const [actionCode, setActionCode] = React.useState("");

  React.useEffect(() => {
    const currentMode = query.get("mode");
    setMode(currentMode);
    const currentActionCode = query.get("oobCode");
    setActionCode(currentActionCode);
  }, []);

  React.useEffect(() => {
    console.log(mode);
  }, [mode]);

  React.useEffect(() => {
    console.log(actionCode);
  }, [actionCode]);

  if (!mode) {
    return;
  }
  if (mode === "resetPassword") {
    return <ResetPassword actionCode={actionCode} />;
  }
  if (mode === "verifyEmail") {
    return <VerifyEmail actionCode={actionCode} />;
  }
}
