import React from "react";
import { Box, Divider, Button, Typography } from "@mui/material";
import InfiniteHitsMb from "./InfiniteHitsMb";
import SearchStoreMb from "./SearchStoreMb";
import RefinementBar from "./RefinementBar";

export default function SearchMb() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          scrollBehavior: "none",
          position: "sticky",
          top: "64px",
          zIndex: 3,
          backgroundColor: "background.default",
          overscrollBehavior: "none",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "96%",
            margin: "auto",
          }}
        >
          <RefinementBar />
        </Box>
        <SearchStoreMb />
        <Divider />
      </Box>

      <InfiniteHitsMb />
    </Box>
  );
}
