import React from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import GalleryMb from "./GalleryMb";
import StickyBuyMb from "./StickyBuyMb";
import SellerInfoMb from "./SellerInfoMb";
import Save from "../../../components/save/Save";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

function DisplayBrands({ brands }) {
  return (
    <Typography component="span">
      {brands.map((brand, index) => {
        if (index != 0) {
          return (
            <Typography
              key={brand}
              component="span"
              sx={{
                display: "inline",
                textDecoration: "underline",
                fontSize: "15px",
              }}
            >
              , {brand}
            </Typography>
          );
        }
        return (
          <Typography
            key={brand}
            component="span"
            sx={{
              display: "inline",
              fontSize: "15px",
              textDecoration: "underline",
            }}
          >
            {brand}
          </Typography>
        );
      })}
    </Typography>
  );
}

function ListingInfo({ listing }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "80%" }}>
      <DisplayBrands brands={listing.brands} />
      <Typography sx={{ fontSize: "15px" }}>{listing.title}</Typography>
      <Typography sx={{ fontSize: "14px", color: "primary.dark" }}>
        {listing.type.size.toUpperCase()} /{" "}
        <span style={{ fontSize: "14px" }}>
          {formatDistanceToNow(listing.timestamp.toDate())} ago
        </span>
      </Typography>
    </Box>
  );
}

export default function ListingDetailsMb({ listing }) {
  const userLink = `/${listing.user}`;

  return (
    <Box
      sx={{
        margin: "15px auto",
        width: "100%",
        alignItems: "center",
      }}
    >
      <SellerInfoMb listing={listing} />
      <GalleryMb
        images={listing.images}
        alt={listing.title}
        sold={listing.sold}
      />
      <Box
        sx={{
          display: "flex",
          width: "90%",
          justifyContent: "space-between",
          mt: "20px",
          margin: "20px auto 0 auto",
        }}
      >
        <ListingInfo listing={listing} />
        <Save listing={listing} />
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          width: "90%",
          color: "primary.dark",
          margin: "20px auto 0 auto",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
        }}
      >
        {listing.description}
      </Typography>
      <StickyBuyMb listing={listing} />
    </Box>
  );
}
