import React from "react";
import { Box, TextField, Typography, Fade } from "@mui/material";
import LoadingButton from "../../components/loading-button/LoadingButton";
import { auth } from "../../firebaseConfig";
import { updatePassword } from "firebase/auth";

const headerDisplay = {
  xxs: "block",
  xs: "block",
  sm: "block",
  md: "none",
  lg: "none",
  xl: "none",
};

export default function PasswordSettings() {
  const [password, setPassword] = React.useState("");
  const [verify, setVerify] = React.useState("");
  const [formStatus, setFormStatus] = React.useState({
    color: "error",
    content: "",
  });
  const [loading, setLoading] = React.useState({
    content: "đổi mật khẩu",
    disabled: false,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (password.length < 8) {
        throw new Error("password must be atleast 8 characters long");
      }
      if (verify !== password) {
        throw new Error("passwords do not match");
      }
      await updatePassword(auth.currentUser, password);
      setFormStatus({
        color: "primary.main",
        content: "đổi mật khẩu thành công :)",
      });
      setLoading({ content: "đổi mật khẩu", disabled: false });
    } catch (err) {
      setFormStatus({ color: "error", content: err.message });
      setLoading({ content: "đổi mật khẩu", disabled: false });
    }
  }

  return (
    <Fade in={true}>
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            mb: "20px",
            letterSpacing: "1px",
            display: headerDisplay,
          }}
        >
          PASSWORD
        </Typography>
        <Box
          onSubmit={handleSubmit}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "0.5px solid",
            borderColor: "secondary.dark",
            padding: "20px",
            width: "100%",
            gap: "20px",
          }}
        >
          <TextField
            value={password}
            inputProps={{ type: "password" }}
            label="mật khẩu mới"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          ></TextField>
          <TextField
            value={verify}
            inputProps={{ type: "password" }}
            label="xác nhận"
            onChange={(e) => {
              setVerify(e.target.value);
            }}
          ></TextField>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              mt: "15px",
            }}
          >
            <Box sx={{ width: "50%" }}>
              <LoadingButton loading={loading} variant="contained" />
            </Box>
            <Typography
              color={formStatus.color}
              variant="body2"
              sx={{ fontSize: "14px", width: "50%" }}
            >
              {formStatus.content}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
