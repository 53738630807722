import { NativeSelect, OutlinedInput, Box } from "@mui/material";
import { useSortBy } from "react-instantsearch";

function SortBy(props) {
  const { options, refine } = useSortBy(props);

  function handleChange(e) {
    refine(e.target.value);
  }
  return (
    <Box sx={{ padding: "0 0 0 30px" }}>
      <NativeSelect
        onChange={handleChange}
        variant="outlined"
        input={
          <OutlinedInput
            sx={{ pl: "8px" }}
            inputProps={{
              style: {
                padding: "5px",
                width: "150px",
                height: "20px",
                lineHeight: "20px",
                fontSize: "14px",
                min: 0,
              },
            }}
          />
        }
      >
        {options.map((item) => {
          return (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          );
        })}
      </NativeSelect>
    </Box>
  );
}

export default SortBy;
