const sizes = {
  m: {
    tops: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    bottoms: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
    ],
    outerwear: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    footwear: [
      "one-size",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
  },
  w: {
    tops: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    bottoms: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "22",
      "24",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
    ],
    outerwear: ["one-size", "xxs", "xs", "s", "m", "l", "xl", "xxl", "xxxl"],
    footwear: [
      "one-size",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ],
    dresses: [
      "one-size",
      "xxs",
      "xs",
      "s",
      "m",
      "l",
      "xl",
      "xxl",
      "xxxl",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
    ],
  },
};

function parseSize(sizeString) {
  if (!sizeString) {
    return false;
  }
  const gender = sizeString[0];
  let periodIndex = undefined;
  for (let i = 3; i < sizeString.length; i++) {
    if (sizeString[i] == ".") {
      periodIndex = i;
      break;
    }
  }
  const category = sizeString.slice(2, periodIndex);
  let size = sizeString.slice(periodIndex + 1);
  return { gender, category, size };
}

function getIndex(gender, category, size) {
  if (sizes[gender][category]) {
    for (let i = 0; i < sizes[gender][category].length; i++) {
      if (sizes[gender][category][i] === size) {
        return i;
      }
    }
  }
  return false;
}

function transformItems(items) {
  let newItems = { m: {}, w: {} };
  for (let key in sizes.m) {
    newItems.m[key] = sizes.m[key].map((size) => {
      return {
        count: undefined,
        value: undefined,
        label: size,
        isRefined: false,
      };
    });
  }
  for (let key in sizes.w) {
    newItems.w[key] = sizes.w[key].map((size) => {
      return {
        count: undefined,
        value: undefined,
        label: size,
        isRefined: false,
      };
    });
  }
  for (let item of items) {
    //make sure that type actually fits the format. Prevents app from crashing if it doesn't.
    const type = parseSize(item.label);
    if (!type) {
      break;
    }
    const { gender, category, size } = type;
    const sizeIndex = getIndex(gender, category, size);
    if (sizeIndex || sizeIndex === 0) {
      newItems[gender][category][sizeIndex].count = item.count;
      newItems[gender][category][sizeIndex].value = item.value;
      newItems[gender][category][sizeIndex].isRefined = item.isRefined;
    } else {
    }
  }
  return newItems;
}

function test() {
  let newItems = { m: {}, w: {} };
  for (let key in sizes.m) {
    newItems.m[key] = sizes.m[key].map((size) => {
      return {
        count: undefined,
        value: undefined,
        label: size,
        isRefined: false,
      };
    });
  }
  for (let key in sizes.w) {
    newItems.w[key] = sizes.w[key].map((size) => {
      return {
        count: undefined,
        value: undefined,
        label: size,
        isRefined: false,
      };
    });
  }
}

export { transformItems, sizes };
