import React from "react";
import { Box } from "@mui/material";
import ConditionMb from "./ConditionMb";
import BrandListMb from "./BrandListMb";
import SizeListMb from "./SizeMb";
import SearchOverviewMb from "./SearchOverviewMb";
import CategoryMb from "./CategoryMb.js";
import ClearMb from "./ClearMb";
import PriceRangeMb from "./PriceRangeMb";
import SortByMb from "./SortByMb";
import GenderMb from "./GenderMb";
import SoldStatusMb from "./SoldStatusMb";

export default function RefinementBar() {
  const containerRef = React.useRef();

  return (
    <Box
      sx={{
        width: "100%",
        padding: "15px 10px 20px 10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SearchOverviewMb />
        <ClearMb excludedAttributes={["uid"]} />
      </Box>
      <Box
        ref={containerRef}
        sx={{
          mt: "10px",
          display: "flex",
          gap: "2vw",
          justifyContent: "start",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <SortByMb
          containerRef={containerRef}
          defaultRefinement="listings/sort/_text_match:desc"
          items={[
            {
              label: "Xếp theo: liên quan",
              value: "listings/sort/_text_match:desc",
            },
            {
              label: "Xếp theo: mới nhất",
              value: "listings/sort/timestamp:desc",
            },
            {
              label: "Xếp theo: giá cao",
              value: "listings/sort/price:desc",
            },
            {
              label: "Xếp theo: giá thấp",
              value: "listings/sort/price:asc",
            },
          ]}
        />
        <GenderMb attribute="gender" containerRef={containerRef} />
        <BrandListMb attribute="brands" containerRef={containerRef} />
        <ConditionMb attribute="condition" containerRef={containerRef} />
        <SizeListMb attribute="size" containerRef={containerRef} limit={200} />
        <CategoryMb
          attribute="category"
          containerRef={containerRef}
          limit={200}
        />
        <PriceRangeMb attribute="price" containerRef={containerRef} />
        <SoldStatusMb attribute="sold" containerRef={containerRef} />
      </Box>
    </Box>
  );
}
