import React from "react";
import {
  Box,
  Menu,
  Typography,
  Button,
  ListItem,
  Checkbox,
  ListItemText,
  List,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useRefinementList } from "react-instantsearch";
import SearchIcon from "@mui/icons-material/Search";

export default function BrandListMb(props) {
  const { containerRef } = props;
  const { items, refine, searchForItems } = useRefinementList(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [variant, setVariant] = React.useState("outlined");
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (open) {
      setVariant("contained");
    } else {
      setVariant("outlined");
    }
  }, [open]);

  function handleClick(e) {
    setAnchorEl(containerRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button
        disableRipple
        onClick={handleClick}
        variant={variant}
        sx={{
          borderRadius: 0,
          fontSize: "0.7rem",
          padding: "3px 6px 3px 6px",
        }}
      >
        Brands
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: "background.paper",
              width: "95vw",
              position: "absolute",
              border: "0.5px solid",
              borderColor: "secondary.dark",
              borderRadius: 0,
              margin: "10px auto 0 auto",
            },
          },
        }}
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{
              width: "90%",
              margin: "10px auto 10px auto",
              padding: "0",
            }}
            onChange={(e) => {
              searchForItems(e.currentTarget.value);
            }}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size="small" />
                </InputAdornment>
              ),
            }}
          ></TextField>
          <List
            disablePadding
            sx={{
              marginBottom: "9px",
              width: "100%",
              maxHeight: "50vh",
              overflow: "scroll",
            }}
          >
            {items.map((item) => {
              return (
                <ListItem
                  sx={{
                    display: "flex",
                    justifyItems: "start",
                    gap: "2px",
                    padding: "0 5% 0 5%",
                  }}
                  key={item.label}
                >
                  <ListItemText
                    sx={{ padding: 0 }}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "13px",
                        padding: 0,
                        width: "70%",
                      },
                    }}
                  >
                    {item.label}
                  </ListItemText>

                  <Typography sx={{ fontSize: "13px", width: "28%" }}>
                    {item.count}
                  </Typography>

                  <Checkbox
                    checked={item.isRefined ? true : false}
                    disableRipple
                    size="8px"
                    onChange={(e) => {
                      refine(item.value);
                    }}
                    sx={{ padding: "2px" }}
                  ></Checkbox>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Menu>
    </React.Fragment>
  );
}
