import { useState } from "react";
import isEmail from "validator/lib/isEmail";
import {
  Box,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebaseConfig";
import { Link } from "react-router-dom";
import LoadingButton from "../loading-button/LoadingButton";

const formStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xxs: "80%",
    xs: "80%",
    sm: "80%",
    md: "30%",
    lg: "30%",
    xl: "30%",
  },
  padding: "20px 20px 30px 20px",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "25px",
};

export default function SigninD(props) {
  const navigate = useNavigate();
  const { close } = props;
  const [error, setError] = useState("");
  const [button, setButton] = useState("sign in");
  const [loading, setLoading] = useState({
    content: "ĐĂNG NHẬP",
    disabled: false,
  });
  const [password, setPassword] = useState({ content: "" });
  const [email, setEmail] = useState({ content: "" });

  function handleEmail(event) {
    setEmail((prevState) => ({
      ...prevState,
      content: event.target.value,
    }));
  }

  function handlePassword(event) {
    setPassword((prevState) => ({
      ...prevState,
      content: event.target.value,
    }));
  }

  async function signin(event) {
    event.preventDefault();
    try {
      setLoading({ content: "", disabled: true });
      setError("");
      await signInWithEmailAndPassword(auth, email.content, password.content);
    } catch (error) {
      console.log(error.code);
      setLoading({ content: "ĐĂNG NHẬP", disabled: false });
      setError(error.code);
    }
  }

  return (
    <Box component="form" sx={formStyle} onSubmit={signin}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" fontSize="18px">
          Đăng Nhập
        </Typography>
        <CloseIcon
          color="action"
          sx={{
            "&:hover": { cursor: "pointer", borderBottom: "1px solid white" },
          }}
          onClick={() => {
            close();
          }}
        />
      </Box>
      <TextField
        label="email"
        size="small"
        variant="standard"
        onChange={handleEmail}
      ></TextField>
      <TextField
        label="password"
        type="password"
        size="small"
        variant="standard"
        onChange={handlePassword}
      ></TextField>
      <Typography variant="body2" color="error" sx={{ textAlign: "center" }}>
        {error}
      </Typography>
      <LoadingButton loading={loading} variant="outlined" />
      <Link
        to="/request-password"
        style={{ textDecoration: "none" }}
        onClick={() => {
          close();
        }}
      >
        <Typography
          sx={{
            color: "secondary.main",
            textDecoration: "underline",
            textAlign: "center",
          }}
        >
          Quên mật khẩu?
        </Typography>
      </Link>
    </Box>
  );
}
