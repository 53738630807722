import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { connectCurrentRefinements } from "react-instantsearch-dom";

function CustomClearRefinements({ items, refine }) {
  return (
    <Button
      variant="outlined"
      sx={{ borderRadius: 0, padding: "9px 0 9px 0" }}
      onClick={() => refine(items)}
      disabled={!items.length}
      endIcon={<CloseIcon />}
    >
      Xoá Tất Cả
    </Button>
  );
}

const ClearRefinements = connectCurrentRefinements(CustomClearRefinements);

export default ClearRefinements;
