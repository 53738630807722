import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  Menu,
} from "@mui/material";
import SubRefinementList from "./SubRefinementListMb";
import transformItems from "../../../components/categories/Categories";
import { useRefinementList } from "react-instantsearch";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function MenuItems({ gender, items, refine, setGender }) {
  if (!gender) {
    return (
      <List disablePadding={true}>
        <ListItem
          alignItems="center"
          sx={{
            padding: "7px 5% 7px 5%",
          }}
          inset="true"
          onClick={() => {
            setGender("m");
          }}
        >
          <ListItemText
            primary="nam"
            primaryTypographyProps={{
              sx: {
                fontSize: "14px",
              },
            }}
          />
          <ChevronRightIcon sx={{ fontSize: "14px" }} />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            padding: "7px 5% 7px 5%",
          }}
          inset="true"
          onClick={() => {
            setGender("w");
          }}
        >
          <ListItemText
            primary="nữ"
            primaryTypographyProps={{
              sx: {
                marginTop: "0px",
                fontSize: "14px",
              },
            }}
          />
          <ChevronRightIcon sx={{ fontSize: "14px" }} />
        </ListItem>
      </List>
    );
  }
  if (gender === "m") {
    return (
      <List disablePadding sx={{ pb: "12px", pt: "2px", maxHeight: "50vh" }}>
        <ListItem
          sx={{
            padding: "5px 5% 5px 5%",
          }}
          inset="true"
        >
          <ListItemText
            primary="nam"
            primaryTypographyProps={{
              sx: {
                fontSize: "14px",
                textDecoration: "underline",
              },
            }}
          />
        </ListItem>
        <SubRefinementList items={items.m.tops} refine={refine} sub="áo" />
        <SubRefinementList
          items={items.m.outerwear}
          refine={refine}
          sub="áo khoác"
        />
        <SubRefinementList items={items.m.bottoms} refine={refine} sub="quần" />
        <SubRefinementList
          items={items.m.footwear}
          refine={refine}
          sub="giày"
        />
        <SubRefinementList
          items={items.m.accessories}
          refine={refine}
          sub="phụ kiện"
        />
      </List>
    );
  }
  return (
    <List disablePadding sx={{ pb: "15px", pt: "5px", maxHeight: "50vh" }}>
      <ListItem
        sx={{
          padding: "5px 5% 5px 5%",
        }}
        inset="true"
      >
        <ListItemText
          primary="nữ"
          primaryTypographyProps={{
            sx: {
              fontSize: "14px",
              textDecoration: "underline",
            },
          }}
        />
      </ListItem>
      <SubRefinementList items={items.w.tops} refine={refine} sub="áo" />
      <SubRefinementList
        items={items.w.outerwear}
        refine={refine}
        sub="áo khoác"
      />
      <SubRefinementList items={items.w.dresses} refine={refine} sub="váy" />
      <SubRefinementList items={items.w.bottoms} refine={refine} sub="quần" />
      <SubRefinementList items={items.w.footwear} refine={refine} sub="giày" />
      <SubRefinementList
        items={items.w.accessories}
        refine={refine}
        sub="phụ kiện"
      />
    </List>
  );
}

export default function CategoryMb(props) {
  const { containerRef } = props;
  const { refine, items, currentRefinements } = useRefinementList(props);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [variant, setVariant] = React.useState("outlined");
  const open = Boolean(anchorEl);
  const [newItems, setNewItems] = React.useState(transformItems(items));
  const [gender, setGender] = React.useState(undefined);

  React.useEffect(() => {
    if (open) {
      setVariant("contained");
    } else {
      setVariant("outlined");
    }
  }, [open]);

  function handleClick(e) {
    setAnchorEl(containerRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
    setGender(undefined);
  }

  React.useEffect(() => {
    setNewItems(transformItems(items));
  }, [items]);

  return (
    <React.Fragment>
      <Button
        disableRipple
        onClick={handleClick}
        variant={variant}
        sx={{
          borderRadius: 0,
          fontSize: "0.7rem",
          padding: "3px 6px 3px 6px",
          minWidth: 0,
        }}
      >
        loại
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        MenuListProps={{
          sx: {
            pt: 0,
            pb: 0,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: "background.paper",
              width: "95vw",
              position: "absolute",
              border: "0.5px solid",
              borderColor: "secondary.dark",
              borderRadius: 0,
              margin: "10px auto 0 auto",
            },
          },
        }}
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <MenuItems
          gender={gender}
          setGender={setGender}
          items={newItems}
          refine={refine}
        />
      </Menu>
    </React.Fragment>
  );
}
