import React from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { InstantSearch } from "react-instantsearch";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { User } from "./User";
import Nav from "./components/nav/Nav";
import Home from "./pages/home/Home";
import UserProfile from "./pages/user-profile/UserProfile";
import Sell from "./pages/sell/Sell";
import ListingDetails from "./pages/listing-details/ListingDetails";
import Search from "./pages/search/Search";
import Saves from "./pages/saves/Saves";
import Checkout from "./pages/checkout/Checkout";
import Settings from "./pages/settings/Settings";
import ProfileSettings from "./pages/settings/ProfileSettings";
import PasswordSettings from "./pages/settings/PasswordSettings";
import EmailSettings from "./pages/settings/EmailSettings";
import Sold from "./pages/sold/Sold";
import Purchased from "./pages/purchased/Purchased";
import BrandRequest from "./pages/brand-request/BrandRequest";
import Success from "./pages/success/Success";
import OG from "./pages/og/OG";
import OGTerms from "./pages/og/OGTerms";
import Action from "./pages/action/Action";
import RequestPassword from "./pages/request-password/RequestPassword";
import SearchTest from "./pages/search-copy/Search";
import Scraped from "./pages/scraped/Scraped";
import Collection from "./pages/collection/Collection";
import Support from "./pages/support/Support";
import StaffPicks from "./pages/staff-picks/StaffPicks";
import { Box, Typography } from "@mui/material";
//test key: BOuA6YjT1g9EUOyjLlggF2Oeg1nJvxMb
//test host: vber6cntaxo47f1hp-1.a1.typesense.net

//production key: ucOkPVolo0rmAXkic4hlj6eQy1UFXwOQ
//production host: kjzpyean341homqbp-1.a1.typesense.net

const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: "ucOkPVolo0rmAXkic4hlj6eQy1UFXwOQ",
    nodes: [
      {
        host: "kjzpyean341homqbp-1.a1.typesense.net",
        port: "443",
        protocol: "https",
      },
    ],
  },
  additionalSearchParameters: {
    query_by: "title, brands, description",
    query_by_weights: "2,2,1",
    per_page: 52,
  },
});

const searchClient = typesenseInstantsearchAdapter.searchClient;

function AnnouncementBar() {
  return (
    <Box sx={{ position: "fixed", width: "100%" }}>
      <Typography sx={{ fontSize: "13px" }}>
        nghỉ tết: 31/1 - 16/2 (ngừng nhận đơn){" "}
      </Typography>
    </Box>
  );
}

export default function Controller() {
  return (
    <User>
      <InstantSearch
        searchClient={searchClient}
        indexName="listings"
        routing={false}
      >
        <AnnouncementBar />
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/u/:username" element={<UserProfile />} />
          <Route path="/sell/:listingId" element={<Sell />} />
          <Route path="/search/:preset?" element={<SearchTest />} />
          <Route path="/saves" element={<Saves />} />
          <Route path="listing/:listingId" element={<ListingDetails />} />
          <Route path="checkout/:listingId" element={<Checkout />} />
          <Route path="/settings" element={<Settings />}>
            <Route path="profile" element={<ProfileSettings />} />
            <Route path="password" element={<PasswordSettings />} />
            <Route path="email" element={<EmailSettings />} />
          </Route>
          <Route path="/sold" element={<Sold />} />
          <Route path="/purchased" element={<Purchased />} />
          <Route path="/brand-request" element={<BrandRequest />} />
          <Route path="/og" element={<OG />} />
          <Route path="/og-terms" element={<OGTerms />} />
          <Route path="/action" element={<Action />} />
          <Route path="/request-password" element={<RequestPassword />} />
          <Route path="collection/:collection" element={<Collection />} />
          <Route path="/scraped" element={<Scraped />} />
          <Route path="/support" element={<Support />} />
          <Route path="/staff-picks" element={<StaffPicks />} />
          <Route
            path="success-verification"
            element={
              <Success
                title="Thành Công"
                content="Email của bạn đã được xác nhận thành công."
              />
            }
          />
          <Route
            path="success-purchase"
            element={
              <Success
                title="Congrats on the Purchase"
                content="Đơn mua thành công, chúng tôi đã gửi thông báo đến người bán. Hãy vui lòng check hàng trươc khi thanh toán.
                "
              />
            }
          />
          <Route
            path="success-signup"
            element={
              <Success
                title="Đăng ký thành công"
                content="Vui lòng xác nhận email để bắt đầu mua bán trên trang"
              />
            }
          />
        </Routes>
      </InstantSearch>
    </User>
  );
}
