import { Box, Card, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

const gapSize = {
  xxs: "2vw",
  xs: "2vw",
  sm: "2vw",
  md: "1vw",
  lg: "1vw",
  xl: "1vw",
};

const imageSize = {
  xxs: "35.5vw",
  xs: "35.5vw",
  sm: "35.5vw",
  md: "11.5vw",
  lg: "11.5vw",
  xl: "11.5vw",
};

function ListingImage({ index, images, handleChange, removeImage }) {
  if (index < images.length) {
    if (images[index].url === "loading") {
      return (
        <Card
          sx={{
            width: imageSize,
            height: imageSize,
            borderRadius: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
              border: "0.5px solid",
              borderColor: "primary.main",
            },
          }}
          component="label"
        >
          <CircularProgress sx={{ fontSize: "40px" }} />
        </Card>
      );
    }
    return (
      <Card sx={{ width: imageSize, height: imageSize, borderRadius: 0 }}>
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "secondary.main",
            opacity: "70%",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "secondary.light",
            },
          }}
          size="small"
          onClick={() => {
            removeImage(index);
          }}
        >
          <CloseIcon sx={{ fontSize: "24px", strokeWidth: 0 }} />
        </Box>
        <img
          src={images[index].url}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        ></img>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        width: imageSize,
        height: imageSize,
        borderRadius: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
          cursor: "pointer",
          border: "0.5px solid",
          borderColor: "primary.main",
        },
      }}
      component="label"
    >
      <AddIcon sx={{ fontSize: "40px" }} />
      <input
        type="file"
        hidden
        onChange={handleChange}
        multiple
        name="files[]"
        accept="image/png, image/jpeg, image/jpg"
      />
    </Card>
  );
}

export default function ListingImages({ handleChange, images, removeImage }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: gapSize,
        width: "91.5%",
        margin: "15px auto 0 auto",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <ListingImage
        index={0}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={1}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={2}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={3}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={4}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={5}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={6}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
      <ListingImage
        index={7}
        images={images}
        handleChange={handleChange}
        removeImage={removeImage}
      />
    </Box>
  );
}
