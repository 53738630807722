import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { connectSortBy } from "react-instantsearch-dom";

const menuItemStyles = {
  fontSize: "14px",
  pr: "25px",
  display: "flex",
  justifyContent: "end",
};

function CustomSortByMb({ items, refine }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleChange(e) {
    const { value } = e.currentTarget.dataset;
    refine(value);
  }

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        variant="text"
        sx={{
          borderRadius: 0,
          width: "50%",
          fontSize: "15px",
          minHeight: "35px",
          padding: 0,
        }}
        startIcon={<SortIcon />}
      >
        XẾP
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "background.paper",
            width: "50vw",
            position: "absolute",
            borderLeft: "0.5px solid",
            borderBottom: "0.5px solid",
            borderColor: "secondary.dark",
            borderRadius: 0,
            mt: "1px",
            ml: "16px",
          },
        }}
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        {items.map((item) => {
          return (
            <MenuItem
              data-value={item.value}
              onClick={handleChange}
              sx={{ fontSize: "14px" }}
              key={item.label}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}

const SortByMb = connectSortBy(CustomSortByMb);

export default SortByMb;
