import React from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useClearRefinements } from "react-instantsearch";

export default function CustomClearRefinements(props) {
  const { refine, canRefine } = useClearRefinements(props);

  return (
    <Button
      variant="outlined"
      sx={{ borderRadius: 0, padding: "9px 0 9px 0" }}
      onClick={() => refine()}
      disabled={!canRefine}
      endIcon={<CloseIcon />}
    >
      Xoá Tất Cả
    </Button>
  );
}
