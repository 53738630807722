import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Edit({ listingId, size, fontSize }) {
  const editLink = `/sell/${listingId}`;

  return (
    <Link to={editLink} style={{ textDecoration: "none", width: "100%" }}>
      <Button
        size={size}
        variant="contained"
        sx={{
          borderRadius: 0,
          width: "100%",
          fontSize: fontSize,
        }}
      >
        EDIT
      </Button>
    </Link>
  );
}
