import React from "react";
import {
  Box,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Modal,
} from "@mui/material";
import { updateDoc, doc, arrayRemove } from "firebase/firestore";
import AddAddressD from "./AddAddressD";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "1px solid grey",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  gap: "30px",
};

export default function AddressSelectorD({
  addressArray,
  updateAddress,
  setCurrent,
  close,
}) {
  const [newIndex, setNewIndex] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  function DisplayAddress({ address }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "15px" }}>
          {address.name} {address.phone}
          <br />
          {address.street}
          <br />
          {address.ward}
          {", "}
          {address.district}
          {", "}
          {address.city}
        </Typography>
      </Box>
    );
  }

  function handleChange(e) {
    setNewIndex(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setCurrent(addressArray[newIndex]);
    close();
  }
  return (
    <React.Fragment>
      <Box component="form" onSubmit={handleSubmit}>
        <FormControl sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              borderBottom: "1px solid",
              borderColor: "secondary.dark",
            }}
          >
            <Typography
              variant="h2"
              sx={{ fontSize: "18px", fontWeight: "normal" }}
            >
              Select Address
            </Typography>
            <CloseIcon
              color="action"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  borderBottom: "1px solid white",
                },
              }}
              onClick={() => {
                close();
              }}
            />
          </Box>
          <Box
            sx={{
              height: "240px",
              overflow: "scroll",
            }}
          >
            <RadioGroup defaultValue={0} onChange={handleChange}>
              {addressArray.map((address, index) => {
                return (
                  <React.Fragment key={index}>
                    <FormControlLabel
                      sx={{ padding: "25px" }}
                      value={index}
                      control={
                        <Radio sx={{ mr: "20px" }} disableRipple size="small" />
                      }
                      label={<DisplayAddress address={address} />}
                    />
                    <Divider sx={{ width: "75%", margin: "auto" }} />
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          </Box>
          <Box
            onClick={() => {
              setModal(true);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              pl: "60px",
              gap: "4px",
              "&:hover": { cursor: "pointer" },
            }}
          >
            <AddIcon />
            <Typography
              sx={{ textDecoration: "underline", fontSize: "16px" }}
              variant="body"
            >
              Thêm Địa Chỉ
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              width: "90%",
              margin: "5px auto 40px auto",
              borderRadius: 0,
              padding: "8px",
              fontWeight: "bold",
            }}
            type="submit"
          >
            Done
          </Button>
        </FormControl>
      </Box>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        <div>
          <AddAddressD
            addressArray={addressArray}
            updateAddress={updateAddress}
            close={() => {
              setModal(false);
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
