import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import { useSearchBox } from "react-instantsearch";

function SearchStore(props) {
  const { currentRefinement, refine } = useSearchBox(props);
  const searchBoxRef = React.createRef();
  const location = useLocation();

  async function submit(e) {
    e.preventDefault();
    const value = new FormData(e.target).get("search-input");
    refine(value);
    searchBoxRef.current.blur();
  }

  if (location.pathname.includes(`/u/`)) {
    return (
      <Box component="form" onSubmit={submit}>
        <Typography></Typography>
        <TextField
          placeholder="search store"
          size="small"
          inputRef={searchBoxRef}
          inputProps={{
            name: "search-input",
            type: "search",
            pl: "0",
          }}
          defaultValue={currentRefinement}
          InputProps={{
            sx: { pl: "2vw", fieldset: { borderColor: "secondary.dark" } },
            startAdornment: (
              <InputAdornment position="start" sx={{ padding: 0, ml: 0 }}>
                <IconButton
                  type="submit"
                  sx={{
                    borderRadius: 0,
                    margin: "0",
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
    );
  }
}

export default SearchStore;
