import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Card,
  Checkbox,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import { connectRefinementList } from "react-instantsearch-dom";
import FilterFooter from "./FilterFooter";

const conditionTranslation = {
  new: "mới",
  good: "tốt",
  okay: "khá",
  used: "cũ",
};

function CustomConditionMb({ items, refine, closeParent }) {
  const [open, setOpen] = React.useState(false);

  function modalContent() {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "200%",
          bgcolor: "background.default",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            backgroundColor: "background.default",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              margin: "35px auto 20px auto",
              height: "20px",
            }}
          >
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <ChevronLeftIcon fontSize="medium" />
            </IconButton>
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                letterSpacing: "1.5px",
              }}
            >
              TÌNH TRẠNG
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(false);
                closeParent();
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "113px",
            overflowY: "scroll",
            height: "38%",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <List
            sx={{
              width: "85%",
              margin: "auto",
            }}
          >
            {items.map((item) => {
              return (
                <React.Fragment key={item.label}>
                  <ListItem
                    sx={{
                      display: "flex",
                      justifyItems: "start",
                      gap: "2px",
                      padding: "2px 5px 5px 2px",
                    }}
                  >
                    <Checkbox
                      checked={item.isRefined ? true : false}
                      size="small"
                      onChange={(e) => {
                        refine(item.value);
                      }}
                      sx={{ padding: "2px" }}
                    ></Checkbox>
                    <ListItemText
                      sx={{ padding: 0 }}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: "14px",
                          padding: "3px",
                        },
                      }}
                    >
                      {conditionTranslation[item.label]}
                    </ListItemText>
                    <Card sx={{ padding: "2px 3px 2px 3px" }}>
                      <Typography sx={{ fontSize: "13px" }}>
                        {item.count}
                      </Typography>
                    </Card>
                  </ListItem>
                </React.Fragment>
              );
            })}
          </List>
        </Box>
        <FilterFooter
          closeParent={() => {
            setOpen(false);
            closeParent();
          }}
        />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <ListItem
        sx={{ padding: "8px 5px 8px 5px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        >
          tình trạng
        </ListItemText>
        <ChevronRightIcon />
      </ListItem>
      <Divider />
      <Modal open={open} keepMounted>
        {modalContent()}
      </Modal>
    </React.Fragment>
  );
}

const ConditionMb = connectRefinementList(CustomConditionMb);

export default ConditionMb;
