import React from "react";
import MediaQuery from "react-responsive";
import SearchD from "./search-d/SearchD";
import SearchMb from "./search-mb/SearchMb";
import StateBar from "./search-d/StateBar";

export default function Search() {
  return (
    <React.Fragment>
      <MediaQuery minWidth={900}>
        <SearchD />
      </MediaQuery>
      <MediaQuery maxWidth={899}>
        <SearchMb />
      </MediaQuery>
    </React.Fragment>
  );
}
