import React from "react";
import { UserContext } from "../../../User";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Buy from "../../../components/buy/Buy";
import Edit from "../../../components/edit/Edit";
import Delete from "../../../components/delete/Delete";

function Shipping({ shipping }) {
  if (shipping == 0) {
    return (
      <Typography
        component="span"
        sx={{
          display: "inline",
          fontSize: "16px",
          color: "primary.dark",
        }}
      >
        {" "}
        + free shipping
      </Typography>
    );
  }
  return (
    <Typography
      component="span"
      sx={{
        display: "inline",
        fontSize: "16px",
        color: "primary.dark",
      }}
    >
      {" "}
      + {shipping}k shipping
    </Typography>
  );
}

export default function StickyBuyMb({ listing }) {
  const user = React.useContext(UserContext).user;
  const editLink = `/sell/${listing.listingId}`;

  if (listing.sold) {
    return (
      <React.Fragment>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            height: "15vw",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 20px 0 20px",
            justifyContent: "space-between",
            bgcolor: "background.default",
            zIndex: 2,
            borderTop: "0.5px solid",
            borderColor: "secondary.dark",
          }}
        >
          <Typography variant="body" sx={{ fontSize: "16px" }}>
            {listing.price}K <Shipping shipping={listing.shipping} />
          </Typography>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Button
              disabled
              variant="contained"
              sx={{ borderRadius: 0, width: "100%", fontSize: "15px" }}
            >
              sold
            </Button>
          </Box>
        </Box>
        <Box sx={{ pb: "18vw" }}></Box>
      </React.Fragment>
    );
  }

  if (user && user.uid === listing.uid) {
    return (
      <React.Fragment>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            height: "15vw",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 20px 0 20px",
            justifyContent: "space-between",
            bgcolor: "background.default",
            zIndex: 2,
            borderTop: "0.5px solid",
            borderColor: "secondary.dark",
          }}
        >
          <Typography variant="body" sx={{ fontSize: "16px" }}>
            {listing.price}K <Shipping shipping={listing.shipping} />
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Edit listingId={listing.listingId} size="small" fontSize="16px" />
            <Delete
              listingId={listing.listingId}
              size="small"
              fontSize="16px"
            />
          </Box>
        </Box>
        <Box sx={{ pb: "18vw" }}></Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          height: "15vw",
          width: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 20px 0 20px",
          justifyContent: "space-between",
          bgcolor: "background.default",
          zIndex: 2,
          borderTop: "0.5px solid",
          borderColor: "secondary.dark",
        }}
      >
        <Typography variant="body" sx={{ fontSize: "16px" }}>
          {listing.price}K <Shipping shipping={listing.shipping} />
        </Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Buy listingId={listing.listingId} />
        </Box>
      </Box>
      <Box sx={{ pb: "18vw" }}></Box>
    </React.Fragment>
  );
}
