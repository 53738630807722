import React from "react";
import { Button, Menu, MenuItem, Divider, Box } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { useSortBy } from "react-instantsearch";

const menuItemStyles = {
  fontSize: "14px",
  pr: "25px",
  display: "flex",
  justifyContent: "end",
};

function SortByMb(props) {
  const { containerRef } = props;
  const { options, refine } = useSortBy(props);
  const [variant, setVariant] = React.useState("outlined");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleChange(e) {
    const { value } = e.currentTarget.dataset;
    refine(value);
  }

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  React.useEffect(() => {
    if (open) {
      setVariant("contained");
    } else {
      setVariant("outlined");
    }
  }, [open]);

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        variant={variant}
        sx={{
          borderRadius: 0,
          fontSize: "0.7rem",
          padding: "3px 6px 3px 6px",
          minWidth: 0,
        }}
      >
        xếp
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: "background.paper",
              width: "100vw",
              position: "absolute",
              border: "0.5px solid",
              borderColor: "secondary.dark",
              borderRadius: 0,
              mt: "10px",
            },
          },
        }}
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        {options.map((item) => {
          return (
            <Box>
              <MenuItem
                dense
                data-value={item.value}
                onClick={handleChange}
                sx={{ fontSize: "13px", padding: "0 5% 0 5%" }}
                key={item.label}
              >
                {item.label}
              </MenuItem>
              <Divider />
            </Box>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}

export default SortByMb;
