import React from "react";
import { useSwiper } from "swiper/react";
import { Box, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export default function SwiperButtons({ next, prev }) {
  return (
    <Box sx={{ display: "flex", gap: "0px" }}>
      <IconButton
        size="small"
        onClick={() => {
          prev();
          prev();
          prev();
          prev();
        }}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          next();
          next();
          next();
          next();
        }}
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
}
