import React from "react";
import { UserContext } from "../../User";
import MediaQuery from "react-responsive";
import { Typography, IconButton, Box, Button } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Recent from "../../components/recent/Recent";
import ClosetdListings from "../../components/closetd-listings/ClosetdListings";
import { Link } from "react-router-dom";
import CasinoIcon from "@mui/icons-material/Casino";
import banner from "./banner-3.jpg";

export default function Home() {
  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <Box>
          <Box
            sx={{
              backgroundColor: "secondary.dark",
              height: "37.5vw",
              width: "100vw",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
            }}
          >
            <Link to="/search">
              <Button
                size="medium"
                variant="contained"
                sx={{ fontSize: "18px" }}
              >
                Shop Now
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "4vw",
              width: "100vw",
              margin: "30px auto 0 auto",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="search/nam" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  width: "45vw",
                  height: "100px",
                  border: "1px solid",
                  borderColor: "secondary.dark",
                  "&:hover": { cursor: "pointer", borderColor: "primary.main" },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "primary.main",
                    fontSize: "18px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    padding: "5px",
                  }}
                >
                  NAM
                </Typography>
              </Box>
            </Link>
            <Link to="search/nu" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  width: "45vw",
                  height: "100px",
                  border: "1px solid",
                  borderColor: "secondary.dark",
                  "&:hover": { cursor: "pointer", borderColor: "primary.main" },
                  backgroundSize: "cover",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "primary.main",
                    textAlign: "right",
                    fontSize: "18px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    padding: "5px",
                  }}
                >
                  NỮ
                </Typography>
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              width: "80vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px auto 0 auto",
            }}
          >
            <Recent />
          </Box>
          <Box
            sx={{
              width: "80vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <ClosetdListings />
          </Box>
        </Box>
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <Box>
          <Box
            sx={{
              backgroundColor: "secondary.dark",
              height: "30vw",
              width: "80vw",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              backgroundImage: `url(${banner})`,
              backgroundSize: "cover",
            }}
          >
            <Link to="/search">
              <Button
                size="large"
                variant="contained"
                sx={{
                  fontSize: "18px",
                  border: "2px solid",
                  fontWeight: "bold",
                }}
              >
                Shop Now
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "2vw",
              width: "80vw",
              margin: "30px auto 0 auto",
            }}
          >
            <Link to="search/nam" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  width: "39vw",
                  height: "100px",
                  border: "1px solid",
                  borderColor: "secondary.dark",
                  "&:hover": { cursor: "pointer", borderColor: "primary.main" },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "primary.main",
                    fontSize: "18px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    padding: "10px",
                  }}
                >
                  NAM
                </Typography>
              </Box>
            </Link>
            <Link to="search/nu" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  width: "39vw",
                  height: "100px",
                  border: "1px solid",
                  borderColor: "secondary.dark",
                  "&:hover": { cursor: "pointer", borderColor: "primary.main" },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "primary.main",
                    textAlign: "right",
                    fontSize: "18px",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    padding: "10px",
                  }}
                >
                  NỮ
                </Typography>
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              width: "80vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Recent />
          </Box>
          <Box
            sx={{
              width: "80vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <ClosetdListings />
          </Box>
        </Box>
      </MediaQuery>
    </React.Fragment>
  );
}
