import React from "react";
import {
  Avatar,
  Drawer,
  Collapse,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate, Link } from "react-router-dom";
import { updateDoc, doc, arrayRemove } from "firebase/firestore";
import { UserContext } from "../../../User";
import { auth, db } from "../../../firebaseConfig";
import { signOut } from "firebase/auth";
import { useLocation } from "react-router-dom";
import SigninModal from "../../auth-forms/SigninModal";
import SignupModal from "../../auth-forms/SignupModal";
import CircleIcon from "@mui/icons-material/Circle";
import LogoutIcon from "@mui/icons-material/Logout";
import AddIcon from "@mui/icons-material/Add";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

function SellButtonGuest({ handleClick }) {
  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          color: "primary.main",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderColor: "secondary.dark",
          padding: "15px 15px 5px 15px",
        }}
      >
        <ListItemText
          primary="đăng bán"
          primaryTypographyProps={{
            sx: { fontSize: "16px" },
          }}
        />
        <AddIcon fontSize="15px" />
      </ListItem>
      <Divider sx={{ mt: "10px" }} />
    </React.Fragment>
  );
}

function NotificationsMenuIcon({ notifications }) {
  if (notifications.length === 0) {
    return <MenuIcon sx={{ width: 28, height: 28 }} />;
  }
  return (
    <Badge variant="dot" color="primary" overlap="rectangular">
      <MenuIcon sx={{ width: 28, height: 28 }} />
    </Badge>
  );
}

function NotificationsListItem({ notifications, value, uid, setOpen }) {
  const valueLink = `/${value}`;

  async function handleClick() {
    try {
      await updateDoc(doc(db, `users/${uid}`), {
        notifications: arrayRemove(value),
      });
      setOpen(false);
    } catch (err) {
      console.log(err);
    }
  }
  if (notifications.includes(value)) {
    return (
      <Link
        to={valueLink}
        onClick={handleClick}
        style={{ textDecoration: "none" }}
      >
        <ListItem
          sx={{
            color: "primary.main",
            fontSize: "15px",
            pr: "30px",
            pl: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ListItemText
            primary={value}
            primaryTypographyProps={{
              sx: { fontSize: "15px", color: "primary.main" },
            }}
          />
          <CircleIcon sx={{ width: "12px", height: "12px" }} />
        </ListItem>
      </Link>
    );
  }
  return (
    <Link
      to={valueLink}
      onClick={() => {
        setOpen(false);
      }}
      style={{ textDecoration: "none" }}
    >
      <ListItem sx={{ pl: "30px" }}>
        <ListItemText
          primary={value}
          primaryTypographyProps={{
            sx: { fontSize: "15px", color: "primary.main" },
          }}
        />
      </ListItem>
    </Link>
  );
}

function AccountGuest() {
  const [open, setOpen] = React.useState(true);

  return (
    <React.Fragment>
      <ListItem
        onClick={() => {
          setOpen(!open);
        }}
        sx={{
          fontSize: "15px",
        }}
      >
        <ListItemText
          primary="tài khoản"
          primaryTypographyProps={{
            sx: { fontSize: "17px" },
          }}
        />
        {open ? <ExpandLess fontSize="15px" /> : <ExpandMore fontSize="15px" />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List sx={{ padding: "0 0 15px 15px" }}>
          <SigninModal
            Component={({ handleClick }) => {
              return (
                <ListItem onClick={handleClick}>
                  <ListItemText
                    primary="đăng nhập"
                    primaryTypographyProps={{
                      sx: { fontSize: "15px" },
                    }}
                  />
                </ListItem>
              );
            }}
          />
          <SignupModal
            Component={({ handleClick }) => {
              return (
                <ListItem onClick={handleClick}>
                  <ListItemText
                    primary="đăng ký"
                    primaryTypographyProps={{
                      sx: { fontSize: "15px" },
                    }}
                  />
                </ListItem>
              );
            }}
          />
        </List>
      </Collapse>
      <Divider />
    </React.Fragment>
  );
}

function SettingsCollapse({ setDrawer }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ListItem
        onClick={() => {
          setOpen(!open);
        }}
        sx={{
          padding: "15px 15px 5px 15px",
        }}
      >
        <ListItemText
          primary="cài đặt"
          primaryTypographyProps={{
            sx: { fontSize: "16px" },
          }}
        />
        {open ? <ExpandLess fontSize="15px" /> : <ExpandMore fontSize="15px" />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List sx={{ padding: 0 }}>
          <Link
            to="settings/profile"
            onClick={() => {
              setDrawer(false);
            }}
            style={{ textDecoration: "none" }}
          >
            <ListItem sx={{ pl: "30px" }}>
              <ListItemText
                primary="profile"
                primaryTypographyProps={{
                  sx: { fontSize: "15px", color: "primary.main" },
                }}
              />
            </ListItem>
          </Link>
          <Link
            to="settings/password"
            onClick={() => {
              setDrawer(false);
            }}
            style={{ textDecoration: "none" }}
          >
            <ListItem sx={{ pl: "30px" }}>
              <ListItemText
                primary="password"
                primaryTypographyProps={{
                  sx: { fontSize: "15px", color: "primary.main" },
                }}
              />
            </ListItem>
          </Link>
          <Link
            to="settings/email"
            onClick={() => {
              setDrawer(false);
            }}
            style={{ textDecoration: "none" }}
          >
            <ListItem sx={{ pl: "30px" }}>
              <ListItemText
                primary="email"
                primaryTypographyProps={{
                  sx: { fontSize: "15px", color: "primary.main" },
                }}
              />
            </ListItem>
          </Link>
        </List>
      </Collapse>
      <Divider sx={{ mt: "10px" }} />
    </React.Fragment>
  );
}

export default function NavMenuMb({ notifications }) {
  const navigate = useNavigate();
  const user = React.useContext(UserContext).user;
  let userUrl = ``;
  if (user) {
    userUrl = `/u/${user.displayName}`;
  }
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setOpen(false);
  }, [location]);

  async function logout() {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  }

  function userDrawer() {
    return (
      <Box sx={{ width: 300 }} role="presentation">
        {" "}
        <List sx={{ padding: 0 }}>
          <Link
            to={userUrl}
            style={{ textDecoration: "none" }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <ListItem
              sx={{
                color: "primary.main",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderColor: "secondary.dark",
                padding: "15px 15px 5px 15px",
              }}
            >
              <ListItemText
                primary={user.displayName}
                primaryTypographyProps={{
                  sx: { fontSize: "17px", textDecoration: "underline" },
                }}
              />
              <Avatar sx={{ height: 30, width: 30 }} src={user.photoURL} />
            </ListItem>
          </Link>
          <Link
            to="/saves"
            onClick={() => {
              setOpen(false);
            }}
            style={{ textDecoration: "none" }}
          >
            <ListItem sx={{ pl: "30px" }}>
              <ListItemText
                primary="saves"
                primaryTypographyProps={{
                  sx: { fontSize: "15px", color: "primary.main" },
                }}
              />
            </ListItem>
          </Link>
          <NotificationsListItem
            notifications={notifications}
            value="sold"
            uid={user.uid}
            setOpen={setOpen}
          />
          <NotificationsListItem
            notifications={notifications}
            value="purchased"
            uid={user.uid}
            setOpen={setOpen}
          />
          <Divider sx={{ mt: "10px" }} />
          <Link to="/sell/new" style={{ textDecoration: "none" }}>
            <ListItem
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                color: "primary.main",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderColor: "secondary.dark",
                padding: "15px 15px 5px 15px",
              }}
            >
              <ListItemText
                primary="đăng bán"
                primaryTypographyProps={{
                  sx: { fontSize: "16px" },
                }}
              />
              <AddIcon fontSize="15px" />
            </ListItem>

            <Divider sx={{ mt: "10px" }} />
          </Link>

          <SettingsCollapse setDrawer={setOpen} />
          <Link to="/support" style={{ textDecoration: "none" }}>
            <ListItem
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                color: "primary.main",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderColor: "secondary.dark",
                padding: "15px 15px 5px 15px",
              }}
            >
              <ListItemText
                primary="hỗ trợ"
                primaryTypographyProps={{
                  sx: { fontSize: "16px" },
                }}
              />
              <QuestionMarkIcon fontSize="15px" />
            </ListItem>

            <Divider sx={{ mt: "10px" }} />
          </Link>
          <ListItem
            onClick={logout}
            sx={{
              color: "primary.main",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderColor: "secondary.dark",
              padding: "15px 15px 5px 15px",
            }}
          >
            <ListItemText
              primary={"đăng xuất"}
              primaryTypographyProps={{
                sx: { fontSize: "16px" },
              }}
            />
            <LogoutIcon fontSize="15px" />
          </ListItem>
          <Divider sx={{ mt: "10px" }} />
        </List>
      </Box>
    );
  }

  function guestDrawer() {
    return (
      <Box sx={{ width: 300 }} role="presentation">
        {" "}
        <List sx={{ padding: "5px 0 5px 0" }}>
          <AccountGuest
            cloes={() => {
              setOpen(false);
            }}
          />
          <SignupModal Component={SellButtonGuest} />
        </List>
      </Box>
    );
  }

  if (user) {
    return (
      <React.Fragment>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <NotificationsMenuIcon notifications={notifications} />
        </IconButton>
        <Drawer
          anchor="left"
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRight: "0.5px solid",
              borderColor: "primary.dark",
            },
          }}
          transitionDuration={150}
        >
          {userDrawer()}
        </Drawer>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
      >
        <MenuIcon
          sx={{ fontSize: "28px", stroke: "#121212", strokeWidth: 1 }}
        />
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRight: "0.5px solid",
            borderColor: "primary.dark",
          },
        }}
      >
        {guestDrawer()}
      </Drawer>
    </React.Fragment>
  );
}
