import { NativeSelect, OutlinedInput } from "@mui/material";
import { connectSortBy } from "react-instantsearch-dom";

function CustomSortBy({ items, refine }) {
  function handleChange(e) {
    refine(e.target.value);
  }

  return (
    <NativeSelect
      onChange={handleChange}
      variant="outlined"
      input={
        <OutlinedInput
          sx={{ pl: "8px" }}
          inputProps={{
            style: {
              padding: "5px",
              width: "150px",
              height: "20px",
              lineHeight: "20px",
              fontSize: "14px",
              min: 0,
            },
          }}
        />
      }
    >
      {items.map((item) => {
        return (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        );
      })}
    </NativeSelect>
  );
}

const SortBy = connectSortBy(CustomSortBy);

export default SortBy;
