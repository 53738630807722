import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLocation } from "react-router-dom";
import {
  connectSearchBox,
  connectCurrentRefinements,
} from "react-instantsearch-dom";

function CustomSearchBoxD(props) {
  const { currentRefinement, isSearchStalled, refine, reset } = props;
  const searchBoxRef = React.createRef();
  const location = useLocation();

  async function submit(e) {
    e.preventDefault();
    const value = new FormData(e.target).get("search-input");
    refine(value);
    searchBoxRef.current.blur();
  }

  if (location.pathname.includes(`/u/`)) {
    return (
      <Box component="form" onSubmit={submit}>
        <Typography></Typography>
        <TextField
          placeholder="search store"
          size="medium"
          inputRef={searchBoxRef}
          inputProps={{
            name: "search-input",
            type: "search",
            pl: "0",
          }}
          defaultValue={currentRefinement}
          InputProps={{
            sx: { pl: "2vw", fieldset: { borderColor: "secondary.dark" } },
            startAdornment: (
              <InputAdornment position="start" sx={{ padding: 0, ml: 0 }}>
                <IconButton
                  type="submit"
                  sx={{
                    borderRadius: 0,
                    margin: "0",
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Box>
    );
  }
}

const SearchBoxD = connectSearchBox(CustomSearchBoxD);

class CustomSearchClearD extends React.Component {
  reset = () => this.props.refine(this.props.items);

  render() {
    return <SearchBoxD reset={this.reset} searchAsYouType={false} />;
  }
}

const SearchStoreMb = connectCurrentRefinements(CustomSearchClearD);

export default SearchStoreMb;
