import { Box, List } from "@mui/material";

import ClearRefinements from "./ClearRefinements";
import BrandList from "./BrandList";
import PriceRange from "./PriceRange";
import SizeList from "./SizeList";
import SoldStatus from "./SoldStatus";
import Condition from "./Condition";
import SearchStore from "./SearchStore";
import Category from "./Category";
import Gender from "./Gender";

export default function Refinements() {
  return (
    <Box
      sx={{
        width: "250px",
        height: "650px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        gap: "20px",
        overflow: "auto",
      }}
    >
      <SearchStore />
      <ClearRefinements excludedAttributes={["uid"]} />
      <List
        disablePadding
        sx={{
          borderTop: "0.5px solid",
          borderRight: "0.5px solid",
          borderLeft: "0.5px solid",
          color: "primary.main",
          width: "250px",
        }}
      >
        <Gender attribute="gender" />
        <BrandList attribute="brands" />
        <SizeList attribute="size" limit={200} />
        <Category attribute="category" limit={200} />
        <PriceRange attribute="price" />
        <Condition attribute="condition" />
        <SoldStatus attribute="sold" />
      </List>
    </Box>
  );
}
