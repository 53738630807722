import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import InfiniteHits from "./InfiniteHits";
import Refinements from "./Refinements";
import StateBar from "./StateBar";

export default function SearchD() {
  function RefinementsAndFeed() {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          margin: "20px auto",
        }}
      >
        <Box sx={{ position: "sticky", top: "152.5px", height: "750px" }}>
          <Refinements />
        </Box>
        <Box sx={{ ml: "1vw", width: "100%" }}>
          <InfiniteHits />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "92%", margin: "auto" }}>
      <Box
        sx={{
          position: "sticky",
          top: "72.5px",
          width: "100%",
          margin: "auto",
          zIndex: 3,
        }}
      >
        <StateBar />
      </Box>
      <RefinementsAndFeed />
    </Box>
  );
}
