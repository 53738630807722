import React from "react";
import NavMb from "./nav-mb/NavMb";
import NavD from "./nav-d/NavD";
import MediaQuery from "react-responsive";
import { UserContext } from "../../User";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

export default function Nav() {
  const user = React.useContext(UserContext).user;
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(
        doc(db, `users/${user.uid}`),
        (userSnap) => {
          if (userSnap) {
            const newNotifications = userSnap.data().notifications;
            if (newNotifications !== notifications) {
              setNotifications(newNotifications);
            }
          }
        }
      );
      return () => unsubscribe();
    }
  }, []);

  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <NavMb notifications={notifications} />
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <NavD notifications={notifications} />
      </MediaQuery>
    </React.Fragment>
  );
}
