import React from "react";
import { UserContext } from "../../User";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SignupModal from "../auth-forms/SignupModal";
import MediaQuery from "react-responsive";

const buttonFontSize = {
  xxs: "15px",
  xs: "15px",
  sm: "15px",
  md: "18px",
  lg: "18px",
  xl: "18px",
};

export default function Buy({ listingId }) {
  const user = React.useContext(UserContext).user;
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/checkout/${listingId}`);
  }

  if (user) {
    return (
      <Button
        variant="contained"
        sx={{
          borderRadius: 0,
          width: "100%",
          fontSize: buttonFontSize,
        }}
        onClick={handleClick}
      >
        Buy Now
      </Button>
    );
  }
  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <SignupModal
          Component={({ handleClick }) => {
            return (
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{
                  borderRadius: 0,
                  width: "100%",
                  fontSize: buttonFontSize,
                }}
              >
                Buy Now
              </Button>
            );
          }}
        />
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <SignupModal
          Component={({ handleClick }) => {
            return (
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{
                  borderRadius: 0,
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: buttonFontSize,
                }}
              >
                Buy Now
              </Button>
            );
          }}
        />
      </MediaQuery>
    </React.Fragment>
  );
}
