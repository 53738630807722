import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  Checkbox,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function SubRefinementListMb({ items, refine, sub }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "0px 5% 2px 5%",
        }}
        inset="true"
      >
        <ListItemText
          primary={sub}
          primaryTypographyProps={{
            sx: { fontSize: "14px" },
          }}
        />
        {open ? <ExpandLess fontSize="10px" /> : <ExpandMore fontSize="10px" />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <Divider />
        <List
          disablePadding
          sx={{
            width: "100%",
            maxHeight: "30vh",
            overflow: "scroll",
            padding: "5px 0 5px 0",
          }}
        >
          {items.map((item) => {
            if (item.count) {
              return (
                <ListItem
                  sx={{
                    display: "flex",
                    justifyItems: "start",
                    gap: "2px",
                    padding: "0 5% 0 5%",
                  }}
                  key={item.label}
                >
                  <ListItemText
                    sx={{ padding: 0 }}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "13px",
                        padding: 0,
                        width: "70%",
                      },
                    }}
                  >
                    {item.label}
                  </ListItemText>

                  <Typography sx={{ fontSize: "13px", width: "28%" }}>
                    {item.count}
                  </Typography>

                  <Checkbox
                    checked={item.isRefined ? true : false}
                    disableRipple
                    size="8px"
                    onChange={(e) => {
                      refine(item.value);
                    }}
                    sx={{ padding: "2px" }}
                  ></Checkbox>
                </ListItem>
              );
            }
          })}
        </List>
        <Divider />
      </Collapse>
    </React.Fragment>
  );
}
