import React from "react";
import { UserContext } from "../../../User";
import { useLocation, Link } from "react-router-dom";
import { Box, Typography, Divider, Button, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Logo from "../../logo/Logo";
import SearchClearD from "../../custom-search-box/SearchClearD";
import NavMenuD from "./NavMenuD";
import SignupModal from "../../auth-forms/SignupModal";
import SigninModal from "../../auth-forms/SigninModal";
import AnnouncementBar from "../../announcement-bar/AnnoucementBar";

function SignupButton({ handleClick }) {
  return (
    <Button
      size="small"
      onClick={() => {
        handleClick();
      }}
      sx={{
        borderRadius: 0,
      }}
      variant="outlined"
    >
      đăng ký
    </Button>
  );
}

function SigninButton({ handleClick }) {
  return (
    <Button
      size="small"
      onClick={() => {
        handleClick();
      }}
      sx={{
        borderRadius: 0,
      }}
      variant="outlined"
    >
      đăng nhập
    </Button>
  );
}

function SellButtonGuest({ handleClick }) {
  return (
    <Button
      size="small"
      onClick={() => {
        handleClick();
      }}
      variant="outlined"
      sx={{ borderRadius: 0 }}
    >
      bán
    </Button>
  );
}

function SavesButtonGuest({ handleClick }) {
  return (
    <IconButton
      size="medium"
      onClick={() => {
        handleClick();
      }}
    >
      <FavoriteBorderIcon sx={{ color: "primary.main", fontSize: "24px" }} />
    </IconButton>
  );
}

export default function NavD({ notifications }) {
  const user = React.useContext(UserContext).user;
  const location = useLocation();
  if (location.pathname.includes("checkout")) {
    return (
      <React.Fragment>
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            backgroundColor: "background.default",
            zIndex: 3,
          }}
        >
          <Box
            color="background.light"
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              left: "4%",
              justifyContent: "space-between",
              alignItems: "center",
              height: "72px",
              padding: "16px 0 16px 0",
              width: "92%",
              margin: "auto",
            }}
          >
            <Logo />
            <Typography
              variant="h1"
              sx={{
                fontSize: "20px",
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              CHECKOUT
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: "secondary.dark" }} />
        </Box>
        <Box sx={{ height: "72px" }}></Box>
      </React.Fragment>
    );
  }

  if (user) {
    return (
      <React.Fragment>
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            backgroundColor: "background.default",
            zIndex: 3,
          }}
        >
          <Box
            color="background.default"
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              left: "4%",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 0 16px 0",
              width: "92.1%",
              margin: "auto",
            }}
          >
            <Logo />
            <SearchClearD includedAttributes={["brands"]} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "201px",
              }}
            >
              <Link to="/sell/new" style={{ textDecoration: "none" }}>
                <Button variant="outlined" sx={{ borderRadius: 0 }}>
                  bán
                </Button>
              </Link>
              <Link to="/saves">
                <IconButton size="medium">
                  <FavoriteBorderIcon
                    sx={{ color: "primary.main", fontSize: "24px" }}
                  />
                </IconButton>
              </Link>
              <NavMenuD notifications={notifications} />
            </Box>
          </Box>
          <Divider
            sx={{
              backgroundColor: "secondary.dark",
              borderBottomWidth: "0.5px",
            }}
          />
        </Box>
        <Box sx={{ height: "72.5px" }}></Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          backgroundColor: "background.default",
          zIndex: 3,
        }}
      >
        <Box
          color="background.default"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            left: "4%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 0 16px 0",
            height: "72px",
            width: "92.1%",
            margin: "auto",
          }}
        >
          <Logo />
          <SearchClearD includedAttributes={["brands"]} />
          <Box sx={{ display: "flex", gap: "20px" }}>
            <SignupModal Component={SellButtonGuest} />
            <SignupModal Component={SavesButtonGuest} />
            <SignupModal Component={SignupButton} />
            <SigninModal Component={SigninButton} />
          </Box>
        </Box>
        <Divider
          sx={{ backgroundColor: "secondary.dark", borderBottomWidth: "0.5px" }}
        />
      </Box>
      <Box sx={{ height: "72.5px" }}></Box>
    </React.Fragment>
  );
}
