import React from "react";
import {
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  InputAdornment,
} from "@mui/material";
import { UserContext } from "../../User";
import Details from "./Details";
import PublishFooter from "./PublishFooter";
import ListingImages from "./ListingImages";
import Type from "./Type";
import { db, storage, functions } from "../../firebaseConfig";
import {
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  doc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useParams, useNavigate } from "react-router-dom";
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import imageCompression from "browser-image-compression";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import NotVerified from "../error/NotVerified";
import WeeklyOverview from "./WeeklyOverview";
import { NumericFormat } from "react-number-format";
import { PriceChange } from "@mui/icons-material";

const containerWidth = {
  xxs: "85%",
  xs: "85%",
  sm: "85%",
  md: "40vw",
  lg: "40vw",
  xl: "40vw",
};

const subHeadingStyle = {
  margin: "60px auto 0 auto",
  fontSize: "16px",
  alignSelf: "start",
  width: "90%",
  letterSpacing: "1px",
  fontWeight: "bold",
};

function Scraper({ scrape, setShortCode, shortCode, IGUser, setIGUser }) {
  const user = React.useContext(UserContext).user;

  if (
    user.displayName == "curated" ||
    user.displayName == "curated2" ||
    user.displayName == "curated3"
  ) {
    return (
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", width: "100%", mt: "30px" }}>
          <TextField
            value={shortCode}
            onChange={(e) => {
              setShortCode(e.target.value);
            }}
          ></TextField>
          <Button onClick={scrape} variant="outlined">
            Scrape
          </Button>
        </Box>
        <TextField
          label="@"
          value={IGUser}
          onChange={(e) => {
            setIGUser(e.target.value);
          }}
        />
      </Box>
    );
  }
}

function Heading({ listingId }) {
  if (listingId === "new") {
    return (
      <Typography
        variant="h1"
        sx={{
          letterSpacing: "2.5px",
          fontSize: "20px",
          fontWeight: "bold",
          alignSelf: "start",
          width: "90%",
          margin: "30px auto 0 auto",
        }}
      >
        LIST AN ITEM
      </Typography>
    );
  }
  return (
    <Typography
      variant="h1"
      sx={{
        letterSpacing: "2.5px",
        fontSize: "20px",
        fontWeight: "bold",
        alignSelf: "start",
        width: "90%",
        margin: "30px auto 0 auto",
      }}
    >
      EDIT YOUR ITEM
    </Typography>
  );
}

export default function Sell() {
  const user = React.useContext(UserContext).user;
  const navigate = useNavigate();
  const listingId = useParams().listingId;
  const [listing, setListing] = React.useState("");
  const [IGUser, setIGUser] = React.useState("");
  const [shortCode, setShortCode] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [gender, setGender] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [sub, setSub] = React.useState("");
  const [size, setSize] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [brands, setBrands] = React.useState([]);
  const [condition, setCondition] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [shipping, setShipping] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [deletedImages, setDeletedImages] = React.useState([]);

  const [submitButton, setSubmitButton] = React.useState({
    disabled: false,
    content: "publish",
  });

  const [dialog, setDialog] = React.useState(false);

  function updateDefaults(data) {
    setPrice(data.price);
    setShipping(data.shipping);
    setDescription(data.description);
    setTitle(data.title);
    setCondition(data.condition);
    setBrands(data.brands);
    setGender(data.type.gender);
    setCategory(data.type.category);
    setSub(data.type.sub);
    setSize(data.type.size);
    setImages(data.imagesWithPath);
  }

  function reset() {
    setPrice(0);
    setShipping(0);
    setDescription("");
    setTitle("");
    setCondition("");
    setBrands([]);
    setImages([]);
    setSubmitButton({ disabled: false, content: "publish" });
    for (const image of images) {
      URL.revokeObjectURL(image.url);
    }
  }

  async function downloadScrapedImages(images) {
    const newImages = await Promise.all(
      images.map(async (image) => {
        const file = await fetch(image.url).blob;
        return { url: image.url, path: "", file };
      })
    );
    setImages(newImages);
  }

  async function scrape(e) {
    async function listingExists(shortCode) {
      const q = query(
        collection(db, "listings"),
        where("shortCode", "==", shortCode)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return false;
      }
      return true;
    }
    try {
      console.log("starting");
      const scrapePost = httpsCallable(functions, "scrapePost");

      const [res, exists] = await Promise.all([
        scrapePost({ shortCode: shortCode }),
        listingExists(shortCode),
      ]);
      console.log(res);
      if (exists) {
        setShortCode("đăng rồi :(");
        return;
      }
      if (res.data.error) {
        setShortCode(res.data.error);
        return;
      }
      setDescription(res.data.description);
      setIGUser(res.data.IGUser);
      //uses images from api
      let newImages = [];
      for (const imageURL of res.data.images) {
        const image = { path: "instagram", url: imageURL };
        newImages.push(image);
      }
      setImages(newImages);
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    return () => {
      for (const image of images) {
        URL.revokeObjectURL(image.url);
      }
    };
  }, []);

  React.useEffect(() => {
    async function getListing() {
      try {
        const listingSnap = await getDoc(doc(db, "listings", listingId));
        const data = listingSnap.data();
        setListing(data);
        updateDefaults(data);
        return;
      } catch (err) {
        console.log(err);
      }
    }

    if (listingId !== "new") {
      getListing();
    } else {
      reset();
      setListing("");
    }
  }, [listingId]);

  async function handleImages(e) {
    let spotsLeft = 8 - images.length;
    let fileIndex = 0;
    let fileLength = e.target.files.length;
    const options = {
      maxSizeMB: 0.8,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    let optimizedImages = await Promise.all(
      [...e.target.files].map((file) => {
        return imageCompression(file, options);
      })
    );
    //adds files to images, ensures that there are no more than 8.
    while (spotsLeft > 0 && fileIndex <= fileLength - 1) {
      const url = URL.createObjectURL(optimizedImages[fileIndex]);
      const file = optimizedImages[fileIndex];
      setImages((prevState) => [
        ...prevState,
        {
          url,
          path: "",
          file,
        },
      ]);
      fileIndex++;
      spotsLeft--;
    }
  }

  async function removeImage(index) {
    const currentImage = images[index];
    const newImages = images.filter(
      (img, removeIndex) => removeIndex !== index
    );
    setImages(newImages);
    URL.revokeObjectURL(currentImage.url);
    if (currentImage.path) {
      setDeletedImages((prevState) => [...prevState, currentImage]);
    }
  }

  function handleGenderCategory(e) {
    setGender(e.target.value[0]);
    setCategory(e.target.value.slice(2));
    setSub("");
    setSize("");
  }

  async function uploadImages(files) {
    try {
      const uploadResults = await Promise.all(
        files.map((file) => {
          const currentTime = Date.now();
          return uploadBytes(
            ref(storage, `${user.uid}/${currentTime + file.name}`),
            file,
            { cacheControl: "public,max-age=4000" }
          );
        })
      );
      const imageUrls = await Promise.all(
        uploadResults.map((result) => {
          return getDownloadURL(result.ref);
        })
      );
      const imagesWithPath = [];
      for (let i = 0; i < uploadResults.length; i++) {
        imagesWithPath.push({
          url: imageUrls[i],
          path: uploadResults[i].ref._location.path_,
        });
      }
      return imagesWithPath;
    } catch (err) {
      console.log(err);
    }
  }

  async function publishListing() {
    setSubmitButton({ disabled: true, content: "" });
    const uploadFiles = images.map((image) => {
      return image.file;
    });
    try {
      let imagesWithPath;
      //only upload if image doesn't have path (only other case is scraped ig image)
      if (!images[0].path) {
        imagesWithPath = await uploadImages(uploadFiles);
      } else {
        imagesWithPath = images;
      }
      const imageUrls = imagesWithPath.map((image) => {
        return image.url;
      });
      const data = {
        gender,
        category: `${gender}.${category}.${sub}`,
        size: `${gender}.${category}.${size}`,
        title,
        images: imageUrls,
        imagesWithPath,
        condition,
        brands,
        description,
        price: Number(price),
        shipping: Number(shipping),
        user: user.displayName,
        uid: user.uid,
        type: { category, size, gender, sub },
        timestamp: serverTimestamp(),
        sold: false,
        numSaves: 0,
        shortCode,
        IGUser,
      };
      await addDoc(collection(db, "listings"), data);
      setDialog(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function editListing() {
    setSubmitButton({ disabled: true, content: "" });
    //delete images that have been removed
    let notUploaded = images.filter((image) => !image.path);
    notUploaded = notUploaded.map((image) => {
      return image.file;
    });
    const uploaded = images.filter((image) => image.path);
    try {
      const newImagesWithPath = await uploadImages(notUploaded);
      for (const image of newImagesWithPath) {
        uploaded.push(image);
      }
      const imageUrls = uploaded.map((image) => {
        return image.url;
      });
      const data = {
        gender,
        category: `${gender}.${category}.${sub}`,
        size: `${gender}.${category}.${size}`,
        title,
        images: imageUrls,
        imagesWithPath: uploaded,
        condition,
        brands,
        description,
        price: Number(price),
        shipping: Number(shipping),
        user: user.displayName,
        uid: user.uid,
        type: { category, size, gender, sub },
      };
      await updateDoc(doc(db, `listings/${listingId}`), data);
      await Promise.all(
        deletedImages.map((image) => {
          deleteObject(ref(storage, image.path));
        })
      );
      setDialog(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (listingId === "new") {
      await publishListing();
    } else {
      await editListing();
    }
  }

  function dialogContent() {
    if (listingId === "new") {
      return (
        <React.Fragment>
          <DialogTitle
            sx={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            Thành Công <SentimentSatisfiedAltIcon />
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: "15px" }}>
              your item has been listed.
            </Typography>
          </DialogContent>
        </React.Fragment>
      );
    }
    return (
      <Box sx={{ padding: "15px" }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          Thành Công <SentimentSatisfiedAltIcon />
        </Typography>
        <Typography sx={{ fontSize: "15px" }}>
          your item has been edited.
        </Typography>
      </Box>
    );
  }

  function formatTotal() {
    if (total === 0 || total === "0") {
      return "0";
    }
    return `${total.toLocaleString()},000đ`;
  }

  React.useEffect(() => {
    const newTotal = Number(price) + Number(shipping);
    setTotal(newTotal);
  }, [price, shipping]);

  if (!user) {
    return <div>you must be signed in to post a listing</div>;
  }

  if (!user.emailVerified) {
    return <NotVerified />;
  }

  return (
    <Box onSubmit={handleSubmit} component="form">
      <Box
        sx={{
          width: containerWidth,
          margin: "10px auto 0 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: "90px",
        }}
      >
        <WeeklyOverview />
        <Heading listingId={listingId} />
        <Scraper
          scrape={scrape}
          setShortCode={setShortCode}
          shortCode={shortCode}
          IGUser={IGUser}
          setIGUser={setIGUser}
        />
        <Typography variant="h2" sx={subHeadingStyle}>
          THỂ LOẠI
        </Typography>
        <Type
          gender={gender}
          category={category}
          sub={sub}
          size={size}
          handleGenderCategory={handleGenderCategory}
          handleSub={(e) => {
            setSub(e.target.value);
          }}
          handleSize={(e) => {
            setSize(e.target.value);
          }}
        />
        <Typography variant="h2" sx={subHeadingStyle}>
          CHI TIẾT
        </Typography>
        <Details
          handleCondition={(e) => {
            setCondition(e.target.value);
          }}
          handleBrands={(e, value) => {
            setBrands(value);
          }}
          handleTitle={(e) => {
            setTitle(e.target.value);
          }}
          handleDescription={(e) => {
            setDescription(e.target.value);
          }}
          brands={brands}
          title={title}
          description={description}
          condition={condition}
        />
        <Typography variant="h2" sx={subHeadingStyle}>
          GIÁ
        </Typography>
        <Box
          sx={{
            width: "90%",
            mt: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <TextField
            value={price}
            label="giá sản phẩm"
            InputProps={{
              endAdornment: <InputAdornment>,000đ</InputAdornment>,
              type: "number",
            }}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            required
          />
          <TextField
            value={shipping}
            label="giá ship"
            InputProps={{
              endAdornment: <InputAdornment>,000đ</InputAdornment>,
              type: "number",
            }}
            onChange={(e) => {
              setShipping(e.target.value);
            }}
            required
          />
          <Typography>tổng: {formatTotal()}</Typography>
        </Box>
        <Typography variant="h2" sx={subHeadingStyle}>
          ẢNH
        </Typography>
        <ListingImages
          images={images}
          handleChange={handleImages}
          removeImage={removeImage}
        />
      </Box>
      <PublishFooter submitButton={submitButton} />
      <Dialog
        open={dialog}
        onClose={() => {
          navigate("/sell/new");
          reset();
          setDialog(false);
        }}
        PaperProps={{
          sx: {
            padding: "10px",
            backgroundColor: "background.paper",
            borderRadius: 0,
            border: "0.5px solid",
            borderColor: "primary.main",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          },
        }}
      >
        {dialogContent()}
      </Dialog>
    </Box>
  );
}
