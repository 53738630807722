import React from "react";
import LoadingButton from "../loading-button/LoadingButton";
import { functions } from "../../firebaseConfig";
import { httpsCallable } from "firebase/functions";

//button adds listing to staff picks
export default function RemoveStaffPicks({ listing }) {
  const [loading, setLoading] = React.useState({
    disabled: false,
    content: "-S.P",
  });

  async function handleClick() {
    try {
      setLoading({ content: "", disabled: true });
      const removeStaffPicks = httpsCallable(functions, "removeStaffPicks");
      const res = await removeStaffPicks({ listingId: listing.id });
      console.log(res);
      if (res.data.status === "success") {
        setLoading({ content: "removed", disabled: true });
        return;
      }
      setLoading({
        disabled: false,
        content: "+S.P",
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <LoadingButton loading={loading} onClick={handleClick} variant="outlined" />
  );
}
