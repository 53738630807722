import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import "./logo.css";
import logo from "./logo.png";

const logoSize = {
  xxs: "22px",
  xs: "22px",
  sm: "22px",
  md: "24px",
  lg: "24px",
  xl: "24px",
};

export default function Logo() {
  function oldLogo() {
    return;
    <Link to="/" style={{ textDecoration: "none" }}>
      <Typography
        className="logo"
        variant="h2"
        sx={{
          fontSize: logoSize,
          letterSpacing: "1.6px",
          color: "primary.light",
          mr: "5px",
          mt: "5px",
        }}
      >
        CLOSET<span style={{ letterSpacing: "1px" }}>'D</span>
      </Typography>
    </Link>;
  }
  return (
    <Link
      to="/"
      style={{ textDecoration: "none", display: "flex", alignItems: "center" }}
    >
      <img src={logo} style={{ height: "20px" }} />
    </Link>
  );
}
