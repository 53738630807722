import React from "react";
import {
  Menu,
  MenuItem,
  Avatar,
  Box,
  IconButton,
  Typography,
  Badge,
} from "@mui/material";
import { UserContext } from "../../../User";
import { useNavigate, Link } from "react-router-dom";
import { auth, db } from "../../../firebaseConfig";
import { doc, updateDoc, arrayRemove } from "firebase/firestore";
import { signOut } from "firebase/auth";
import MenuIcon from "@mui/icons-material/Menu";
import CircleIcon from "@mui/icons-material/Circle";
import AnnouncementBar from "../../announcement-bar/AnnoucementBar";

const menuItemStyles = {
  color: "primary.main",
  fontSize: "14px",
  pr: "20px",
  display: "flex",
  justifyContent: "end",
};

function NotificationMenuIcon({ notifications }) {
  if (notifications.length === 0) {
    return <MenuIcon sx={{ width: 28, height: 28 }} />;
  }
  return (
    <Badge variant="dot" color="primary" overlap="rectangular">
      <MenuIcon sx={{ width: 28, height: 28 }} />
    </Badge>
  );
}

function NotificationMenuItem({ notifications, value, uid }) {
  const valueUrl = `/${value}`;

  const translation = { sold: "đã bán", purchased: "đơn mua" };

  async function handleClick() {
    try {
      await updateDoc(doc(db, `users/${uid}`), {
        notifications: arrayRemove(value),
      });
    } catch (err) {
      console.log(err);
    }
  }
  if (notifications.includes(value)) {
    return (
      <Link
        to={valueUrl}
        onClick={handleClick}
        style={{ textDecoration: "none" }}
      >
        <MenuItem
          sx={{
            color: "primary.main",
            fontSize: "14px",
            pr: "20px",
            pl: "25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CircleIcon sx={{ width: "12px", height: "12px" }} />
          <Typography sx={{ fontSize: "14px" }}>
            {translation[value]}
          </Typography>
        </MenuItem>
      </Link>
    );
  }
  return (
    <Link
      to={valueUrl}
      onClick={handleClick}
      style={{ textDecoration: "none" }}
    >
      <MenuItem sx={menuItemStyles}>{translation[value]}</MenuItem>
    </Link>
  );
}

export default function NavMenuD({ notifications }) {
  const user = React.useContext(UserContext).user;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const userUrl = `/u/${user.displayName}`;

  async function logout() {
    try {
      await signOut(auth);
    } catch (error) {
      console.log(error);
    }
  }

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (user) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <NotificationMenuIcon notifications={notifications} />
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          MenuListProps={{
            sx: {
              pt: 0,
              pb: "10px",
            },
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              padding: 0,
              width: "200px",
              position: "absolute",
              borderRight: "0.5px solid",
              borderLeft: "0.5px solid",
              borderBottom: "0.5px solid",
              borderRadius: 0,
              borderColor: "secondary.dark",
              mt: "17.5px",
            },
          }}
          sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
        >
          <Link to={userUrl} style={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                color: "primary.main",
                fontSize: "14px",
                pr: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.5px solid",
                borderColor: "secondary.dark",
                pt: "8px",
                pb: "8px",
              }}
            >
              <Avatar sx={{ height: 30, width: 30 }} src={user.photoURL} />
              <Typography sx={{ fontSize: "15px" }}>
                {user.displayName}
              </Typography>
            </MenuItem>
          </Link>
          <NotificationMenuItem
            value="sold"
            notifications={notifications}
            uid={user.uid}
          />
          <NotificationMenuItem
            value="purchased"
            notifications={notifications}
            uid={user.uid}
          />
          <Link to="settings/profile" style={{ textDecoration: "none" }}>
            <MenuItem sx={menuItemStyles}>cài đặt</MenuItem>
          </Link>
          <Link to="support" style={{ textDecoration: "none" }}>
            <MenuItem sx={menuItemStyles}>hỗ trợ</MenuItem>
          </Link>
          <MenuItem sx={menuItemStyles} onClick={logout}>
            đăng xuất
          </MenuItem>
        </Menu>
      </Box>
    );
  }
}
