import { Box, Typography, Fade } from "@mui/material";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

export default function Success({ title, content }) {
  return (
    <Fade in={true}>
      <Box
        sx={{
          width: "92%",
          margin: "20px auto 0 auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "24px",
            mt: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <span style={{ fontWeight: "bold" }}>{title}</span>{" "}
          <SentimentSatisfiedAltIcon fontSize="large" />
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            mt: "5px",
            color: "secondary.main",
            whiteSpace: "pre-wrap",
          }}
        >
          {content}
        </Typography>
      </Box>
    </Fade>
  );
}
