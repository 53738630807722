import React from "react";
import Signin from "./Signin";
import { Modal } from "@mui/material";

export default function SigninModal({ Component }) {
  const [signinModal, setSigninModal] = React.useState(false);
  return (
    <React.Fragment>
      <Component
        handleClick={() => {
          setSigninModal(true);
        }}
      />
      <Modal
        open={signinModal}
        onClose={() => {
          setSigninModal(false);
        }}
      >
        <div>
          <Signin
            close={() => {
              setSigninModal(false);
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
