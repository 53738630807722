import React from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  Divider,
  ButtonBase,
} from "@mui/material";
import { Link } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { db } from "../../firebaseConfig";
import { getDoc, doc } from "firebase/firestore";
import DisplayTotalMb from "../../pages/checkout/checkout-mb/DisplayTotalMb";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ActionButtons from "./ActionButtons";

const statusTranslations = {
  processing: "đang xử lý",
  shipped: "đã ship",
  cancelled: "đã bị huỷ",
  received: "đã nhận",
};

function ModalHeader({ setOpen, listingId, soldDate }) {
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          backgroundColor: "background.default",
          zIndex: 3,
        }}
      >
        <Box
          color="background.light"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            left: "4%",
            justifyContent: "space-between",
            alignItems: "center",
            height: "64px",
            padding: "10px 0 10px 0",
            width: "92%",
            margin: "auto",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "20px",
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              Receipt
            </Typography>
            <Typography sx={{ color: "secondary.main", alignSelf: "end" }}>
              {format(soldDate, "dd/MM/yyyy")}
            </Typography>
          </Box>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Divider sx={{ backgroundColor: "secondary.dark" }} />
      </Box>
      <Box sx={{ height: "64px" }} />
    </React.Fragment>
  );
}

function DisplayStatus({ status, tracking }) {
  const [statusColor, setStatusColor] = React.useState("yellow");

  React.useEffect(() => {
    if (status === "shipped" || status === "received") {
      setStatusColor("success.main");
    } else if (status === "cancelled") {
      setStatusColor("error");
    } else {
      setStatusColor("yellow");
    }
  }, [status]);

  if (tracking) {
    return (
      <Box sx={{}}>
        <Typography sx={{ fontSize: "14px" }} color={statusColor}>
          <b>tình trạng:</b> {statusTranslations[status]}
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          {tracking.courier} / {tracking.code}
        </Typography>
      </Box>
    );
  }

  return (
    <Typography sx={{ fontSize: "14px" }} color={statusColor}>
      tình trạng: {statusTranslations[status]}
    </Typography>
  );
}

function DisplayAddress({ address }) {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography sx={{ fontSize: "14px", textAlign: "left" }}>
        {address.name} {address.phone}
        <br />
        {address.street}
        <br />
        {address.ward}
        {", "}
        {address.district}
        {", "}
        {address.city}
      </Typography>
    </Box>
  );
}

function Buyer({ address, buyerUsername }) {
  const buyerUrl = `/u/${buyerUsername}`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        margin: "auto",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: "14px",
            fontWeight: "normal",
            color: "primary.main",
          }}
        >
          Người mua:
        </Typography>
        <Link to={buyerUrl} style={{ textDecoration: "none" }}>
          <Typography
            sx={{
              fontSize: "14px",
              color: "primary.main",
              textDecoration: "underline",
            }}
          >
            {buyerUsername}
          </Typography>
        </Link>
      </Box>
      <ButtonBase
        onClick={() => {
          navigator.clipboard.writeText(
            `${address.name}, ${address.phone}, ${address.street}, ${address.ward}, ${address.district}, ${address.city}`
          );
        }}
        sx={{
          border: "1px solid",
          borderColor: "secondary.dark",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <DisplayAddress address={address} />
        <ContentCopyIcon />
      </ButtonBase>
    </Box>
  );
}

function Seller({ listing, payment }) {
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: "20px" }}>
        Thánh toán: {payment.toUpperCase()}
      </Typography>
      <DisplayTotalMb listing={listing} />
    </Box>
  );
}

export default function SoldListingMb({ soldListing }) {
  const {
    listing,
    address,
    buyer,
    seller,
    listingId,
    timestamp,
    payment,
    status,
    tracking,
  } = soldListing;
  const soldDate = timestamp.toDate();
  const listingUrl = `/listing/${listingId}`;
  const [open, setOpen] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState(status);
  const [sellerUsername, setSellerUsername] = React.useState(undefined);
  const [buyerUsername, setBuyerUsername] = React.useState(undefined);

  React.useEffect(() => {
    async function getInfo() {
      const [buyerSnap, sellerSnap] = await Promise.all([
        getDoc(doc(db, `/profiles/${buyer.uid}`)),
        getDoc(doc(db, `/profiles/${seller.uid}`)),
      ]);
      setBuyerUsername(buyerSnap.data().username);
      setSellerUsername(sellerSnap.data().username);
    }
    getInfo();
  }, []);

  function modalContent() {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowY: "scroll",
          pb: "40px",
          bgcolor: "background.paper",
        }}
      >
        <ModalHeader
          setOpen={setOpen}
          listingId={listingId}
          soldDate={soldDate}
        />
        <Box
          sx={{
            width: "90vw",
            margin: "auto",
            mt: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <DisplayStatus status={currentStatus} tracking={tracking} />
          <Buyer address={address} buyerUsername={buyerUsername} />
          <Seller
            listing={listing}
            payment={payment}
            sellerUsername={sellerUsername}
          />
          <ActionButtons
            status={currentStatus}
            setStatus={setCurrentStatus}
            listingId={listingId}
            timestamp={timestamp}
          />
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box sx={{ width: "90vw", display: "flex", margin: "auto" }}>
        <Box sx={{ height: "30vw", width: "30vw" }}>
          <Link to={listingUrl}>
            <img
              src={listing.images[0]}
              style={{ height: "100%", objectFit: "cover", width: "30vw" }}
            />
          </Link>
        </Box>
        <Box
          onClick={() => {
            setOpen(true);
          }}
          sx={{
            border: "0.5px solid",
            borderColor: "secondary.dark",
            display: "flex",
            width: "60vw",
            justifyContent: "space-between",
            padding: "1vw 1vw 1vw 2vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "40vw",
              padding: "5px",
            }}
          >
            <DisplayStatus status={currentStatus} tracking={tracking} />
            <Typography sx={{ fontSize: "14px" }}>{listing.title}</Typography>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                {Number(listing.price) + Number(listing.shipping)}k /{" "}
                {payment.toUpperCase()}
              </Typography>
              <Typography sx={{ color: "secondary.main" }}>
                {format(soldDate, "dd/MM/yyyy")}
              </Typography>
            </Box>
          </Box>
          <ChevronRightIcon sx={{ alignSelf: "center" }} />
        </Box>
      </Box>
      <ActionButtons
        status={currentStatus}
        setStatus={setCurrentStatus}
        listingId={listingId}
        timestamp={timestamp}
      />
      <Modal open={open}>{modalContent()}</Modal>
    </React.Fragment>
  );
}
