import { Box, Typography } from "@mui/material";
export default function GalleryD({ images, alt, sold }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {images.map((image, index) => {
        if (index === 0 && sold) {
          return (
            <Box sx={{ width: "40vw", height: "40vw" }} key={index}>
              <Typography
                sx={{
                  position: "absolute",
                  backgroundColor: "background.default",
                  width: "40vw",
                  height: "40vw",
                  opacity: "60%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "2rem",
                  letterSpacing: "0.1rem",
                  fontWeight: "bold",
                  color: "yellow",
                }}
              >
                SOLD
              </Typography>
              <img
                src={image}
                alt={alt}
                style={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          );
        }
        return (
          <Box sx={{ width: "40vw", height: "40vw" }} key={index}>
            <img
              src={image}
              alt={alt}
              style={{
                display: "block",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
}
