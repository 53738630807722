import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material/";
import { BrowserRouter, useLocation } from "react-router-dom";
import { analytics } from "./firebaseConfig";
import { logEvent } from "firebase/analytics";
import Controller from "./Controller";
import Dark from "./themes/Dark";
import "./App.css";

function FirebaseAnalytics() {
  const location = useLocation();
  React.useEffect(() => {
    logEvent(analytics, "page_view", { path: location.pathName });
  }, [location]);
  return null;
}
//6.4.2 version of react router dom
function App() {
  return (
    <ThemeProvider theme={Dark}>
      <CssBaseline />
      <BrowserRouter>
        <FirebaseAnalytics />
        <Controller />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
