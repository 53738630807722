import React from "react";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import brandList from "./brandsNameOnly.json";
import { createFilterOptions } from "@mui/material/Autocomplete";
import MediaQuery from "react-responsive";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 300,
});

const autocompleteMaxHeight = {
  xxs: "46vh",
  xs: "46vh",
  sm: "46vh",
  md: "22vw",
  lg: "22vw",
  xl: "22vw",
};

const conditionTranslation = {
  new: "mới",
  good: "tốt",
  okay: "khá",
  used: "cũ",
};

function conditionRenderValue(selected) {
  return `${conditionTranslation[selected]}`;
}

export default function Details({
  handleTitle,
  handleDescription,
  handleBrands,
  handleCondition,
  brands,
  description,
  title,
  condition,
}) {
  function handleAutoCompleteFocus(e) {
    var yourHeight = 90;

    // scroll to your element
    e.target.scrollIntoView(true);

    // now account for fixed header
    var scrolledY = window.scrollY;
    console.log(scrolledY);
    console.log(scrolledY - yourHeight);

    if (scrolledY) {
      window.scroll(0, scrolledY - yourHeight);
    }
  }

  return (
    <Box
      sx={{
        width: "90%",
        mt: "15px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
      }}
    >
      <Link
        to="/brand-request"
        style={{ textDecoration: "none", width: "70%" }}
      >
        <Typography
          sx={{ textDecoration: "underline", color: "secondary.light" }}
        >
          Không có brand móng muốn?
        </Typography>
      </Link>
      <Autocomplete
        filterOptions={filterOptions}
        getOptionDisabled={() => {
          if (brands.length >= 3) {
            return true;
          }
          return false;
        }}
        value={brands}
        onChange={handleBrands}
        multiple
        variant="outlined"
        options={brandList}
        ListboxProps={{
          sx: {
            mt: "10px",
            backgroundColor: "background.default",
            fontSize: "14px",
            width: "100%",
            position: "absolute",
            border: "0.5px solid",
            borderRadius: 0,
            maxHeight: autocompleteMaxHeight,
            zIndex: 1,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="medium"
            variant="outlined"
            label="brands"
            InputLabelProps={{ required: false }}
          />
        )}
        ChipProps={{
          variant: "outlined",
          deleteIcon: (
            <CloseIcon sx={{ strokeWidth: "0", color: "primary.main" }} />
          ),
          sx: {
            borderRadius: 0,
          },
        }}
      />
      <TextField
        required={true}
        value={title}
        label="tên"
        variant="outlined"
        onChange={handleTitle}
        inputProps={{ maxLength: 50 }}
      />
      <TextField
        required={true}
        value={condition}
        select
        onChange={handleCondition}
        defaultValue=""
        label="tình trạng"
        SelectProps={{
          renderValue: (selected) => {
            return `${conditionTranslation[selected]}`;
          },
        }}
      >
        {["new", "good", "okay", "used"].map((condition) => {
          return (
            <MenuItem
              size="small"
              dense
              value={condition}
              key={condition}
              sx={{
                fontSize: "15px",
                padding: "0 15px 0 15px",
                height: "20px",
              }}
            >
              {conditionTranslation[condition]}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        required={true}
        value={description}
        label="mô tả"
        multiline={true}
        minRows={7}
        maxRows={7}
        onChange={handleDescription}
      />
    </Box>
  );
}
