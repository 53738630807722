import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Card,
  Checkbox,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export default function SubRefinementList({ items, refine, sub }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "0 10px 0 10px",
        }}
        inset="true"
      >
        <ListItemText
          primary={sub}
          primaryTypographyProps={{
            sx: { fontSize: "14px" },
          }}
        />
        {open ? <ExpandLess fontSize="10px" /> : <ExpandMore fontSize="10px" />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        sx={{
          padding: "0 13px 0 13px",
        }}
      >
        <List disablePadding>
          {items.map((item) => {
            if (item.count) {
              return (
                <ListItem
                  sx={{
                    display: "flex",
                    justifyItems: "start",
                    gap: "2px",
                    padding: 0,
                  }}
                  key={item.label}
                >
                  <Checkbox
                    checked={item.isRefined ? true : false}
                    disableRipple
                    size="8px"
                    onChange={(e) => {
                      refine(item.value);
                    }}
                    sx={{ padding: "2px" }}
                  ></Checkbox>
                  <ListItemText
                    sx={{ padding: 0 }}
                    primaryTypographyProps={{
                      sx: {
                        fontSize: "13px",
                        padding: 0,
                      },
                    }}
                  >
                    {item.label}
                  </ListItemText>
                  <Card sx={{ padding: "2px 3px 2px 3px" }}>
                    <Typography sx={{ fontSize: "11px" }}>
                      {item.count}
                    </Typography>
                  </Card>
                </ListItem>
              );
            }
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
}
