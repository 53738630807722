const subCategories = {
  m: {
    tops: [
      "tees",
      "jersey",
      "polos",
      "shirts",
      "sweatshirts-hoodies",
      "sweaters-knitwear",
      "other",
    ],
    bottoms: [
      "jeans",
      "work-pants",
      "cargo-pants",
      "military-pants",
      "sweats-joggers",
      "chinos",
      "casual-pants",
      "dress-pants",
      "shorts",
      "swimwear",
      "other",
    ],
    outerwear: [
      "denim-jacket",
      "leather-jacket",
      "military-jacket",
      "wool-jacket",
      "work-jacket",
      "puffer",
      "windbreaker",
      "parka",
      "fleece",
      "blazer",
      "other",
    ],
    footwear: ["boots", "casual", "low-sneakers", "high-sneakers", "other"],
    accessories: [
      "bags",
      "watches",
      "hats",
      "belts",
      "glasses",
      "gloves",
      "necklaces",
      "rings",
      "wallets",
    ],
  },
  w: {
    tops: [
      "tees",
      "jersey",
      "baby-tee",
      "crop-tops",
      "tank-tops",
      "sweatshirts-hoodies",
      "sweaters-knitwear",
      "blouses",
      "body-suits",
      "polos",
      "other",
    ],
    bottoms: [
      "jeans",
      "dress-pants",
      "leggings",
      "sweats-joggers",
      "shorts",
      "skirts",
      "work-pants",
      "cargo-pants",
      "military-pants",
      "chinos",
      "other",
    ],
    outerwear: [
      "denim-jacket",
      "leather-jacket",
      "military-jacket",
      "wool-jacket",
      "work-jacket",
      "fleece",
      "blazer",
      "puffer",
      "windbreaker",
      "parka",
      "other",
    ],
    footwear: [
      "boots",
      "casual",
      "dress",
      "low-sneakers",
      "high-sneakers",
      "other",
    ],
    skirts: ["short", "long"],
    dresses: ["dresses", "gowns"],
    accessories: [
      "bags",
      "necklaces",
      "rings",
      "watches",
      "hats",
      "belts",
      "glasses",
      "gloves",
      "wallets",
      "hair-accessories",
    ],
  },
};

function parseSize(sizeString) {
  if (!sizeString) {
    return false;
  }
  const gender = sizeString[0];
  let periodIndex = undefined;
  for (let i = 3; i < sizeString.length; i++) {
    if (sizeString[i] == ".") {
      periodIndex = i;
      break;
    }
  }
  const category = sizeString.slice(2, periodIndex);
  const sub = sizeString.slice(periodIndex + 1);
  return { gender, category, sub };
}

function getIndex(gender, category, sub) {
  if (subCategories[gender][category]) {
    for (let i = 0; i < subCategories[gender][category].length; i++) {
      if (subCategories[gender][category][i] === sub) {
        return i;
      }
    }
  }
  return false;
}

function transformItems(items) {
  let newItems = { m: {}, w: {} };
  for (let key in subCategories.m) {
    newItems.m[key] = subCategories.m[key].map((sub) => {
      return {
        count: undefined,
        value: undefined,
        label: sub,
        isRefined: false,
      };
    });
  }
  for (let key in subCategories.w) {
    newItems.w[key] = subCategories.w[key].map((sub) => {
      return {
        count: undefined,
        value: undefined,
        label: sub,
        isRefined: false,
      };
    });
  }
  for (let item of items) {
    //make sure that type actually fits the format. Prevents app from crashing if it doesn't.
    const type = parseSize(item.label);
    if (!type) {
      break;
    }
    const { gender, category, sub } = type;
    const index = getIndex(gender, category, sub);
    if (index || index === 0) {
      newItems[gender][category][index].count = item.count;
      newItems[gender][category][index].value = item.value;
      newItems[gender][category][index].isRefined = item.isRefined;
    } else {
    }
  }
  return newItems;
}

export default transformItems;
