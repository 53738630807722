import React from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContentText,
} from "@mui/material";
import { db } from "../../firebaseConfig";
import { updateDoc, doc } from "firebase/firestore";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import LoadingButton from "../loading-button/LoadingButton";
import MediaQuery from "react-responsive";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import differenceInMinutes from "date-fns/differenceInMinutes";

export default function ActionButtons({
  status,
  setStatus,
  listingId,
  timestamp,
}) {
  const location = useLocation();

  if (location.pathname.includes("sold")) {
    return (
      <SellerButtons
        status={status}
        setStatus={setStatus}
        listingId={listingId}
      />
    );
  }
  return (
    <BuyerButtons
      status={status}
      setStatus={setStatus}
      listingId={listingId}
      timestamp={timestamp}
    />
  );
}

function BuyerButtons({ status, setStatus, listingId, timestamp }) {
  const [received, setReceived] = React.useState({
    content: "đã nhận",
    disabled: false,
  });
  const [cancel, setCancel] = React.useState({
    content: "huỷ đơn",
    disabled: false,
  });
  const [cancelDialog, setCancelDialog] = React.useState(false);

  function dialogContent() {
    const now = new Date();
    const timebought = timestamp.toDate();
    const difference = differenceInMinutes(now, timebought);

    if (difference > 120) {
      return (
        <React.Fragment>
          <DialogTitle
            variant="h2"
            sx={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            Hãy liên hệ với chúng tôi <SentimentDissatisfiedIcon />
          </DialogTitle>
          <DialogContentText sx={{ width: "90%", margin: "auto" }}>
            Để huỷ đơn đã mua hơn 2 tiếng vui lòng liện hệ với chúng tôi qua ig:
            closetd_app. Mã đơn {listingId}
          </DialogContentText>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <DialogTitle
          variant="h2"
          sx={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          Bạn Có Chắc Không? <SentimentDissatisfiedIcon />
        </DialogTitle>
        <DialogActions sx={{ display: "flex", gap: "10px" }}>
          <LoadingButton
            loading={cancel}
            onClick={handleCancel}
            variant="contained"
          />
          <Button
            variant="outlined"
            onClick={() => {
              setCancelDialog(false);
            }}
            sx={{ width: "100%" }}
          >
            Không
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  React.useEffect(() => {
    if (status === "processing") {
      setReceived({
        content: "đã nhận",
        disabled: false,
      });
      setCancel({
        content: "huỷ đơn",
        disabled: false,
      });
    } else if (status === "shipped") {
      setReceived({
        content: "đã nhận",
        disabled: false,
      });
      setCancel({
        content: "huỷ đơn",
        disabled: true,
      });
    } else {
      setReceived({
        content: "đã nhận",
        disabled: true,
      });
      setCancel({
        content: "huỷ đơn",
        disabled: true,
      });
    }
  }, [status]);

  async function handleReceived() {
    try {
      setReceived({ content: "", disabled: true });
      await updateDoc(doc(db, `/sold/${listingId}`), { status: "received" });
      setStatus("received");
      setReceived({
        content: "đã nhận",
        disabled: false,
      });
    } catch (err) {
      console.log(err);
      setReceived({
        content: "đã nhận",
        disabled: false,
      });
    }
  }

  async function handleCancel() {
    try {
      setCancel({ content: "", disabled: true });
      await updateDoc(doc(db, `/sold/${listingId}`), { status: "cancelled" });
      setStatus("cancelled");
      setCancel({
        content: "huỷ đơn",
        disabled: false,
      });
      setCancelDialog(false);
    } catch (err) {
      console.log(err);
      setCancel({
        content: "huỷ đơn",
        disabled: false,
      });
    }
  }

  return (
    <React.Fragment>
      <MediaQuery minWidth={900}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: "2vw",
            width: "12vw",
            gap: "0.8vw",
          }}
        >
          <LoadingButton
            loading={received}
            variant="contained"
            onClick={handleReceived}
          />
          <Button
            disabled={cancel.disabled}
            onClick={() => {
              setCancelDialog(true);
            }}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            Huỷ Đơn
          </Button>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={899}>
        <Box
          sx={{
            display: "flex",
            width: "90vw",
            gap: "2vw",
            margin: "auto",
          }}
        >
          <LoadingButton
            loading={received}
            variant="contained"
            onClick={handleReceived}
          />
          <Button
            disabled={cancel.disabled}
            onClick={() => {
              setCancelDialog(true);
            }}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            Huỷ Đơn
          </Button>
        </Box>
      </MediaQuery>
      <Dialog
        open={cancelDialog}
        onClose={() => {
          setCancelDialog(!cancelDialog);
        }}
        PaperProps={{
          sx: {
            padding: "10px",
            backgroundColor: "background.paper",
            borderRadius: 0,
            border: "0.5px solid",
            borderColor: "primary.main",
          },
        }}
      >
        {dialogContent()}
      </Dialog>
    </React.Fragment>
  );
}

function SellerButtons({ status, setStatus, listingId, timestamp }) {
  const [shipped, setShipped] = React.useState({
    content: "xác nhận",
    disabled: false,
  });
  const [cancel, setCancel] = React.useState({
    content: "huỷ đơn",
    disabled: false,
  });
  const [cancelDialog, setCancelDialog] = React.useState(false);
  const [shippingDialog, setShippingDialog] = React.useState(false);
  const [tracking, setTracking] = React.useState("");
  const [courier, setCourier] = React.useState("");

  function dialogContent() {
    return (
      <React.Fragment>
        <DialogTitle
          variant="h2"
          sx={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          Bạn Có Chắc Không? <SentimentDissatisfiedIcon />
        </DialogTitle>
        <DialogActions sx={{ display: "flex", gap: "10px" }}>
          <LoadingButton
            loading={cancel}
            onClick={handleCancel}
            variant="contained"
          />
          <Button
            variant="outlined"
            onClick={() => {
              setCancelDialog(false);
            }}
            sx={{ width: "100%" }}
          >
            Không
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  function shippingDialogContent() {
    return (
      <React.Fragment>
        <DialogTitle
          variant="h2"
          sx={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            minWidth: "20vw",
          }}
        >
          NHẬP MÃ VẬN ĐƠN
        </DialogTitle>
        <DialogActions
          sx={{ display: "flex", flexDirection: "column", gap: "25px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <TextField
              label="mã vận đơn"
              value={tracking}
              onChange={(e) => {
                setTracking(e.target.value);
              }}
            />
            <TextField
              label="đơn vị vận chuyển"
              value={courier}
              onChange={(e) => {
                setCourier(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", width: "100%" }}>
            <LoadingButton
              loading={shipped}
              onClick={handleShipped}
              variant="contained"
            />
            <Button
              variant="outlined"
              onClick={() => {
                setShippingDialog(false);
              }}
              sx={{ width: "100%" }}
            >
              Đóng
            </Button>
          </Box>
        </DialogActions>
      </React.Fragment>
    );
  }

  React.useEffect(() => {
    if (status === "processing") {
      setShipped({
        content: "xác nhận",
        disabled: false,
      });
      setCancel({
        content: "huỷ đơn",
        disabled: false,
      });
    } else {
      setShipped({
        content: "xác nhận",
        disabled: false,
      });
      setCancel({
        content: "huỷ đơn",
        disabled: true,
      });
    }
  }, [status]);

  async function handleShipped() {
    try {
      setShipped({ content: "", disabled: true });
      await updateDoc(doc(db, `/sold/${listingId}`), {
        status: "shipped",
        tracking: { code: tracking, courier },
      });
      setStatus("shipped");
      setShipped({
        content: "xác nhận",
        disabled: false,
      });
      setShippingDialog(false);
    } catch (err) {
      console.log(err);
      setShipped({
        content: "xác nhận",
        disabled: false,
      });
    }
  }

  async function handleCancel() {
    try {
      setCancel({ content: "", disabled: true });
      await updateDoc(doc(db, `/sold/${listingId}`), { status: "cancelled" });
      setStatus("cancelled");
      setCancel({
        content: "huỷ đơn",
        disabled: false,
      });
      setCancelDialog(false);
    } catch (err) {
      console.log(err);
      setCancel({
        content: "huỷ đơn",
        disabled: false,
      });
    }
  }

  return (
    <React.Fragment>
      <MediaQuery minWidth={900}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: "2vw",
            width: "12vw",
            gap: "0.8vw",
          }}
        >
          <Button
            onClick={() => {
              setShippingDialog(true);
            }}
            variant="contained"
            sx={{ width: "100%" }}
          >
            nhập mã vận đơn
          </Button>
          <Button
            disabled={cancel.disabled}
            onClick={() => {
              setCancelDialog(true);
            }}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            Huỷ Đơn
          </Button>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={899}>
        <Box
          sx={{
            display: "flex",
            width: "90vw",
            gap: "2vw",
            margin: "auto",
          }}
        >
          <Button
            onClick={() => {
              setShippingDialog(true);
            }}
            variant="contained"
            sx={{ width: "100%" }}
          >
            nhập mã vận đơn
          </Button>
          <Button
            disabled={cancel.disabled}
            onClick={() => {
              setCancelDialog(true);
            }}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            Huỷ Đơn
          </Button>
        </Box>
      </MediaQuery>
      <Dialog
        open={cancelDialog}
        onClose={() => {
          setCancelDialog(!cancelDialog);
        }}
        PaperProps={{
          sx: {
            padding: "10px",
            backgroundColor: "background.paper",
            borderRadius: 0,
            border: "0.5px solid",
            borderColor: "primary.main",
          },
        }}
      >
        {dialogContent()}
      </Dialog>
      <Dialog
        open={shippingDialog}
        onClose={() => {
          setShippingDialog(!shippingDialog);
        }}
        PaperProps={{
          sx: {
            padding: "10px",
            backgroundColor: "background.paper",
            borderRadius: 0,
            border: "0.5px solid",
            borderColor: "primary.main",
          },
        }}
      >
        {shippingDialogContent()}
      </Dialog>
    </React.Fragment>
  );
}
