import React from "react";
import { Typography, Box, Card, IconButton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { db } from "../../firebaseConfig";
import { Link } from "react-router-dom";
import { Navigation } from "swiper";
import MediaQuery from "react-responsive";
import "swiper/css";
import "swiper/css/navigation";
import SwiperButtons from "./SwiperButtons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";

const listingWidthHeight = {
  xxs: "28vw",
  xs: "28vw",
  sm: "28vw",
  md: "14.5vw",
  lg: "14.5vw",
  xl: "14.5vw",
};

function ListingImage({ image, sold }) {
  if (sold) {
    return (
      <Card
        sx={{
          backgroundColor: "none",
          borderRadius: "0",
          width: listingWidthHeight,
          height: listingWidthHeight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            cursor: "pointer",
          },
          overflow: "hidden",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            backgroundColor: "background.default",
            width: listingWidthHeight,
            height: listingWidthHeight,
            opacity: "60%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "2rem",
            letterSpacing: "0.1rem",
            fontWeight: "bold",
            color: "yellow",
          }}
        >
          SOLD
        </Typography>
        <img src={image} style={{ height: "100%" }} />
      </Card>
    );
  }
  return (
    <Card
      sx={{
        backgroundColor: "none",
        borderRadius: "0",
        width: listingWidthHeight,
        height: listingWidthHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          cursor: "pointer",
        },
        overflow: "hidden",
      }}
    >
      <img src={image} style={{ height: "100%" }} />
    </Card>
  );
}

function Listing({ listing }) {
  const listingUrl = `listing/${listing.id}`;

  return (
    <Box sx={{ width: listingWidthHeight }}>
      <Link
        to={listingUrl}
        style={{
          textDecoration: "none",
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ListingImage image={listing.images[0]} sold={listing.sold} />
      </Link>
      <Typography
        sx={{
          textAlign: "start",
          mt: "4px",
          padding: "0px 4px 0px 4px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: "14px",
          textOverflow: "ellipsis",
        }}
      >
        {listing.title}
      </Typography>
      <Box
        sx={{
          padding: "0px 4px 0px 4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "primary.main",
          width: "100%",
        }}
      >
        <Typography
          noWrap={true}
          variant="body"
          sx={{
            fontSize: "14px",
          }}
        >
          {listing.type.size.toUpperCase()}
        </Typography>
        <Typography
          noWrap={true}
          variant="body"
          sx={{
            fontSize: "14px",
          }}
        >
          {listing.price}k
        </Typography>
      </Box>
    </Box>
  );
}

function ListingSwiper({ listings, label, link }) {
  const sliderRef = React.useRef(null);

  function ConditionalLabelLink({ label, link }) {
    if (link) {
      return (
        <Link to={link} style={{ textDecoration: "none" }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: "18px",
              padding: 0,
              mr: "10px",
              color: "primary.main",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {label}
          </Typography>
        </Link>
      );
    }
    return (
      <Typography
        variant="h2"
        sx={{
          fontSize: "18px",
          padding: 0,
          mr: "10px",
        }}
      >
        {label}
      </Typography>
    );
  }

  const handlePrev = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    sliderRef.current.swiper.slidePrev();
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = React.useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    sliderRef.current.swiper.slideNext();
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <React.Fragment>
      <MediaQuery minWidth={900}>
        <Box sx={{ width: "81.5vw" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              margin: "20px 10px 7px 10px",
            }}
          >
            <ConditionalLabelLink label={label} link={link} />
            <SwiperButtons next={handleNext} prev={handlePrev} />
          </Box>
          <Swiper
            ref={sliderRef}
            slidesPerView={5}
            modules={[Navigation]}
            style={{
              height: "18vw",
              width: "81.5vw",
              display: "flex",
              flexWrap: "wrap",
              gap: "1vw",
              margin: "auto",
              alignItems: "start",
              justifyContent: "center",
              backgroundColor: "transparent",
            }}
          >
            {listings.map((listing) => {
              return (
                <SwiperSlide
                  key={listing.id}
                  style={{ width: "20vw", backgroundColor: "transparent" }}
                >
                  <Listing listing={listing} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </MediaQuery>
      <MediaQuery maxWidth={899}>
        <Box sx={{ width: "100vw" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              margin: "0px 10px 0 2vw",
            }}
          >
            <ConditionalLabelLink label={label} link={link} />
            <SwiperButtons next={handleNext} prev={handlePrev} />
          </Box>
          <Swiper
            ref={sliderRef}
            slidesPerView={3}
            spaceBetween={5}
            style={{
              height: "40vw",
              width: "95vw",
              display: "flex",
              flexWrap: "wrap",
              margin: "auto",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
            }}
          >
            {listings.map((listing) => {
              return (
                <SwiperSlide
                  key={listing.id}
                  style={{ width: "20vw", backgroundColor: "transparent" }}
                >
                  <Listing listing={listing} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </MediaQuery>
    </React.Fragment>
  );
}

export default ListingSwiper;
