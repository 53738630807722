import React from "react";
import {
  Menu,
  Typography,
  Button,
  ListItem,
  Checkbox,
  ListItemText,
  List,
} from "@mui/material";
import { useRefinementList } from "react-instantsearch";

export default function SoldStatusMb(props) {
  const { containerRef } = props;
  const { items, refine } = useRefinementList(props);
  const [variant, setVariant] = React.useState("outlined");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function transformLabel(label) {
    if (label === "true") {
      return "đã bán";
    }
    return "còn hàng";
  }

  React.useEffect(() => {
    if (open) {
      setVariant("contained");
    } else {
      setVariant("outlined");
    }
  }, [open]);

  function handleClick(e) {
    setAnchorEl(containerRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        variant={variant}
        sx={{
          borderRadius: 0,
          fontSize: "0.7rem",
          padding: "3px 6px 3px 6px",
          minWidth: 0,
        }}
      >
        còn
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              backgroundColor: "background.paper",
              width: "95vw",
              position: "absolute",
              border: "0.5px solid",
              borderColor: "secondary.dark",
              borderRadius: 0,
              mt: "10px",
            },
          },
        }}
        sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
      >
        <List
          disablePadding
          sx={{
            marginBottom: "0px",
            width: "100%",
            maxHeight: "30vh",
            overflow: "scroll",
          }}
        >
          {items.map((item) => {
            return (
              <ListItem
                sx={{
                  display: "flex",
                  justifyItems: "start",
                  gap: "2px",
                  padding: "0 5% 0 5%",
                }}
                key={item.label}
              >
                <ListItemText
                  sx={{ padding: 0 }}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "13px",
                      padding: 0,
                      width: "70%",
                    },
                  }}
                >
                  {transformLabel(item.label)}
                </ListItemText>

                <Typography sx={{ fontSize: "13px", width: "28%" }}>
                  {item.count}
                </Typography>

                <Checkbox
                  checked={item.isRefined ? true : false}
                  disableRipple
                  size="8px"
                  onChange={(e) => {
                    refine(item.value);
                  }}
                  sx={{ padding: "2px" }}
                ></Checkbox>
              </ListItem>
            );
          })}
        </List>
      </Menu>
    </React.Fragment>
  );
}
