import React from "react";
import { Box, Typography, Modal } from "@mui/material";
import { UserContext } from "../../../User";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddIcon from "@mui/icons-material/Add";
import AddressSelectorMb from "./AddressSelectorMb";
import AddAddressMb from "./AddAddressMb";

export default function AddressMb({
  address,
  setAddress,
  addressArray,
  updateAddress,
}) {
  const [modal, setModal] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);
  const user = React.useContext(UserContext).user;

  function DisplayAddress({ address }) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Typography sx={{ fontSize: "15px" }}>
          {address.name} {address.phone}
          <br />
          {address.street}
          <br />
          {address.ward}
          {", "}
          {address.district}
          {", "}
          {address.city}
        </Typography>
      </Box>
    );
  }

  if (!user) {
    return;
  }

  if (addressArray.length > 0) {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            margin: "auto",
            width: "100%",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "16px",
              fontWeight: "normal",
            }}
          >
            Địa Chỉ Người Nhận
          </Typography>
          <Box
            onClick={() => {
              setModal(true);
            }}
            sx={{
              border: "1px solid",
              borderColor: "secondary.dark",
              padding: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <DisplayAddress address={address} />
            <ChevronRightIcon />
          </Box>
        </Box>
        <Modal
          open={modal}
          onClose={() => {
            setModal(false);
          }}
        >
          <div>
            <AddressSelectorMb
              close={() => {
                setModal(false);
              }}
              addressArray={addressArray}
              current={address}
              setCurrent={setAddress}
              updateAddress={updateAddress}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Typography
        variant="h2"
        sx={{
          fontSize: "16px",
          width: "70%",
          fontWeight: "normal",
        }}
      >
        Địa Chỉ Người Nhận
      </Typography>
      <Box
        onClick={() => {
          setAddModal(true);
        }}
        sx={{
          width: "100%",
          border: "1px solid",
          borderColor: "secondary.dark",
          padding: "20px 15px 20px 15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            "&:hover": { cursor: "pointer" },
          }}
        >
          <AddIcon />
          <Typography
            sx={{ textDecoration: "underline", fontSize: "16px" }}
            variant="body"
          >
            Thêm Địa Chỉ
          </Typography>
        </Box>
        <ChevronRightIcon />
      </Box>
      <Modal
        open={addModal}
        onClose={() => {
          setAddModal(false);
        }}
      >
        <div>
          <AddAddressMb
            addressArray={addressArray}
            updateAddress={updateAddress}
            close={() => {
              setAddModal(false);
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}
