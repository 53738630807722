import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  useSearchBox,
  useCurrentRefinements,
  useClearRefinements,
} from "react-instantsearch";

function SearchBoxMb(props) {
  const { reset } = props;
  const { currentRefinement, refine } = useSearchBox(props);
  const searchBoxRef = React.createRef();
  const navigate = useNavigate();
  async function submit(e) {
    e.preventDefault();
    const value = new FormData(e.target).get("search-input");
    reset();
    refine(value);
    navigate("/search");
    window.scrollTo(0, 0);
    searchBoxRef.current.blur();
  }
  return (
    <form role="search" onSubmit={submit} style={{ width: "51.5%" }}>
      <TextField
        inputRef={searchBoxRef}
        inputProps={{
          name: "search-input",
          type: "search",
          pl: "0",
        }}
        defaultValue={currentRefinement}
        InputProps={{
          sx: { pl: "2px", fieldset: { borderColor: "secondary.dark" } },
          startAdornment: (
            <InputAdornment position="start" sx={{ padding: 0, ml: 0 }}>
              <IconButton
                type="submit"
                sx={{
                  borderRadius: 0,
                  margin: "0",
                }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      ></TextField>
    </form>
  );
}

function SearchClearMb(props) {
  const { refine } = useClearRefinements(props);

  return <SearchClearClass refine={refine} />;
}

class SearchClearClass extends React.Component {
  reset = () => this.props.refine();

  render() {
    return <SearchBoxMb reset={this.reset} searchAsYouType={false} />;
  }
}

export default SearchClearMb;
