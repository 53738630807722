import React from "react";
import { UserContext } from "../../User";
import {
  doc,
  setDoc,
  deleteDoc,
  getDocs,
  collection,
  getDoc,
} from "firebase/firestore";
import { Box, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { db } from "../../firebaseConfig";
import SignupModal from "../auth-forms/SignupModal";
import MediaQuery from "react-responsive";

//this saves requires fetching the listing

const iconSize = {
  xxs: "24px",
  xs: "24px",
  sm: "24px",
  md: "24px",
  lg: "24px",
  xl: "24px",
};

const iconCountSize = {
  xxs: "12px",
  xs: "12px",
  sm: "12px",
  md: "15px",
  lg: "15px",
  xl: "15px",
};

export default function Save({ listing }) {
  const [fetching, setFetching] = React.useState(true);
  const [save, setSave] = React.useState(false);
  const [numSaves, setNumSaves] = React.useState(listing.numSaves);
  const user = React.useContext(UserContext).user;

  React.useEffect(() => {
    async function getSave() {
      const snap = await getDoc(
        doc(db, `listings/${listing.listingId}/saves/${user.uid}`)
      );
      if (snap.exists()) {
        setSave(true);
      }
      setNumSaves(listing.numSaves);
      setFetching(false);
    }
    if (user) {
      getSave();
    }
  }, [user]);

  async function handleSave() {
    const docRef = doc(db, `listings/${listing.listingId}/saves/${user.uid}`);
    if (save) {
      setSave(false);
      setNumSaves(numSaves - 1);
      await deleteDoc(docRef);
      return;
    }
    setSave(true);
    setNumSaves(numSaves + 1);
    await setDoc(docRef, { user: user.displayName });
  }

  if (user) {
    if (fetching) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
            height: "52px",
            width: "28px",
          }}
        >
          <FavoriteIcon
            color="disabled"
            sx={{
              stroke: "#121212",
              strokeWidth: 1,
              fontSize: iconSize,
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
                transition: "all .2s ease-in-out",
              },
            }}
          />
        </Box>
      );
    }
    if (save) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
            height: "52px",
            width: "28px",
          }}
        >
          <FavoriteIcon
            onClick={handleSave}
            sx={{
              stroke: "#121212",
              strokeWidth: 1,
              fontSize: iconSize,
              "&:hover": {
                cursor: "pointer",
                transform: "scale(1.05)",
                transition: "all .2s ease-in-out",
              },
            }}
          />
          <Typography sx={{ fontSize: iconCountSize }}>{numSaves}</Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          alignItems: "center",
          justifyContent: "center",
          height: "52px",
          width: "28px",
        }}
      >
        <FavoriteBorderIcon
          onClick={handleSave}
          sx={{
            fontSize: iconSize,
            stroke: "#121212",
            strokeWidth: 1,
            "&:hover": {
              cursor: "pointer",
              transform: "scale(1.05)",
              transition: "all .2s ease-in-out",
            },
          }}
        />
        <Typography sx={{ fontSize: iconCountSize }}>{numSaves}</Typography>
      </Box>
    );
  }
  return (
    <React.Fragment>
      <MediaQuery maxWidth={899}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
            height: "52px",
          }}
        >
          <SignupModal
            Component={({ handleClick }) => {
              return (
                <FavoriteBorderIcon
                  onClick={handleClick}
                  sx={{
                    fontSize: iconSize,
                    stroke: "#121212",
                    strokeWidth: 1,
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.05)",
                      transition: "all .2s ease-in-out",
                    },
                  }}
                />
              );
            }}
          />
          <Typography sx={{ fontSize: iconCountSize }}>{numSaves}</Typography>
        </Box>
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            alignItems: "center",
            justifyContent: "center",
            height: "52px",
          }}
        >
          <SignupModal
            Component={({ handleClick }) => {
              return (
                <FavoriteBorderIcon
                  onClick={handleClick}
                  sx={{
                    fontSize: iconSize,
                    stroke: "#121212",
                    strokeWidth: 1,
                    "&:hover": {
                      cursor: "pointer",
                      transform: "scale(1.05)",
                      transition: "all .2s ease-in-out",
                    },
                  }}
                />
              );
            }}
          />
          <Typography sx={{ fontSize: iconCountSize }}>{numSaves}</Typography>
        </Box>
      </MediaQuery>
    </React.Fragment>
  );
}
