import React from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Collapse,
  Card,
  Checkbox,
  InputAdornment,
  Button,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { connectRefinementList } from "react-instantsearch-dom";
import FilterFooter from "./FilterFooter";
import SubSizeListMb from "./SubSizeListMb";
import { transformItems } from "../../../components/sizes/Sizes";

function CustomSizeListMb({
  items,
  refine,
  currentRefinements,
  attribute,
  closeParent,
}) {
  const [open, setOpen] = React.useState(false);
  const [mens, setMens] = React.useState(false);
  const [womens, setWomens] = React.useState(false);
  const [newItems, setNewItems] = React.useState(transformItems(items));

  React.useEffect(() => {
    setNewItems(transformItems(items));
  }, [items]);

  function modalContent() {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "200%",
          bgcolor: "background.default",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            backgroundColor: "background.default",
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "90%",
              margin: "35px auto 20px auto",
              height: "20px",
            }}
          >
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <ChevronLeftIcon fontSize="medium" />
            </IconButton>
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
                letterSpacing: "1.5px",
              }}
            >
              {attribute}
            </Typography>
            <IconButton
              onClick={() => {
                setOpen(false);
                closeParent();
              }}
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            mt: "103px",
            overflowY: "scroll",
            height: "40%",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <List
            sx={{
              width: "85%",
              margin: "auto",
            }}
          >
            <ListItem
              onClick={() => {
                setMens(!mens);
              }}
              sx={{
                padding: "10px 5px 0px 5px",
              }}
              inset="true"
            >
              <ListItemText
                primary="nam"
                primaryTypographyProps={{
                  sx: { fontSize: "15px" },
                }}
              />
              {mens ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mens}>
              <List sx={{ padding: "13px 0 10px 0" }}>
                <SubSizeListMb
                  items={newItems.m.tops}
                  refine={refine}
                  sub="áo"
                />
                <SubSizeListMb
                  items={newItems.m.outerwear}
                  refine={refine}
                  sub="áo khoác"
                />
                <SubSizeListMb
                  items={newItems.m.bottoms}
                  refine={refine}
                  sub="quần"
                />
                <SubSizeListMb
                  items={newItems.m.footwear}
                  refine={refine}
                  sub="giày"
                />
              </List>
            </Collapse>
            <Divider sx={{ pt: "10px" }} />
            <ListItem
              onClick={() => {
                setWomens(!womens);
              }}
              sx={{
                padding: "10px 5px 0px 5px",
              }}
              inset="true"
            >
              <ListItemText
                primary="nữ"
                primaryTypographyProps={{
                  sx: { fontSize: "15px" },
                }}
              />
              {womens ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={womens}>
              <List sx={{ padding: "13px 0 10px 0" }}>
                <SubSizeListMb
                  items={newItems.w.tops}
                  refine={refine}
                  sub="áo"
                />
                <SubSizeListMb
                  items={newItems.w.outerwear}
                  refine={refine}
                  sub="áo khoác"
                />
                <SubSizeListMb
                  items={newItems.w.dresses}
                  refine={refine}
                  sub="váy"
                />
                <SubSizeListMb
                  items={newItems.w.bottoms}
                  refine={refine}
                  sub="quần"
                />
                <SubSizeListMb
                  items={newItems.w.footwear}
                  refine={refine}
                  sub="giày"
                />
              </List>
            </Collapse>
            <Divider sx={{ pt: "10px" }} />
          </List>
        </Box>
        <FilterFooter
          closeParent={() => {
            setOpen(false);
            closeParent();
          }}
        />
      </Box>
    );
  }
  if (!newItems) {
    return;
  }
  return (
    <React.Fragment>
      <ListItem
        sx={{ padding: "8px 5px 8px 5px" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            sx: { fontSize: "15px" },
          }}
        >
          {attribute}
        </ListItemText>
        <ChevronRightIcon />
      </ListItem>
      <Divider />
      <Modal open={open} keepMounted>
        {modalContent()}
      </Modal>
    </React.Fragment>
  );
}

const SizeListMb = connectRefinementList(CustomSizeListMb);

export default SizeListMb;
