import { useClearRefinements } from "react-instantsearch";
import { Button } from "@mui/material";

export default function ClearMb(props) {
  const { refine, canRefine } = useClearRefinements(props);

  return (
    <Button
      sx={{ borderRadius: 0, fontSize: "13px" }}
      variant="text"
      onClick={() => refine()}
      disabled={!canRefine}
    >
      Reset
    </Button>
  );
}
