import React from "react";
import {
  Box,
  TextField,
  Avatar,
  Badge,
  Button,
  Typography,
  LinearProgress,
  Fade,
} from "@mui/material";
import { UserContext } from "../../User";
import {
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import { db, storage, auth } from "../../firebaseConfig";
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import imageCompression from "browser-image-compression";

const headerDisplay = {
  xxs: "block",
  xs: "block",
  sm: "block",
  md: "none",
  lg: "none",
  xl: "none",
};

function DisplayPhoto({ photo, handleChange }) {
  if (!photo) {
    return (
      <Badge
        component="label"
        badgeContent="+"
        sx={{
          width: 90,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        color="primary"
        overlap="circular"
      >
        <Avatar sx={{ width: 90, height: 90 }} />
        <input
          type="file"
          hidden
          onChange={handleChange}
          accept="image/png, image/jpeg, image/jpg"
        />
      </Badge>
    );
  }
  return (
    <Badge
      component="label"
      badgeContent="+"
      sx={{
        width: 90,
        "&:hover": {
          cursor: "pointer",
        },
      }}
      color="primary"
      overlap="circular"
    >
      <Avatar sx={{ width: 90, height: 90 }} src={photo.url} />
      <input
        type="file"
        hidden
        onChange={handleChange}
        accept="image/png, image/jpeg, image/jpg"
      />
    </Badge>
  );
}

function LoadingButton({ button }) {
  function buttonContent() {
    if (button.content) {
      return "save changes";
    }
    return <LinearProgress sx={{ width: "90%", margin: "10px 0 10px 0" }} />;
  }
  return (
    <Button
      disabled={button.disabled}
      type="submit"
      sx={{ width: "50%", borderRadius: 0 }}
      variant="contained"
    >
      {buttonContent()}
    </Button>
  );
}

export default function ProfileSettings() {
  const uid = React.useContext(UserContext).user.uid;
  const [formStatus, setFormStatus] = React.useState({
    color: "error",
    content: "",
  });
  const [username, setUsername] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [photo, setPhoto] = React.useState({ url: "", path: "" });
  const [submitButton, setSubmitButton] = React.useState({
    disabled: false,
    content: "save changes",
  });
  const [before, setBefore] = React.useState(undefined);

  React.useEffect(() => {
    async function getUser() {
      const profileSnap = await getDoc(doc(db, "profiles", uid));
      setBefore(profileSnap.data());
      setProfile(profileSnap.data());
    }
    getUser();
  }, []);

  //url cleanup
  React.useEffect(() => {
    return () => {
      URL.revokeObjectURL(photo.url);
    };
  }, []);

  function setProfile(data) {
    setUsername(data.username);
    setBio(data.bio);
    setPhoto(data.photo);
  }

  async function handlePhoto(e) {
    const oldPhoto = photo;
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    };
    const optimizedImage = await imageCompression(e.target.files[0], options);
    const url = URL.createObjectURL(optimizedImage);
    if (oldPhoto.url && !oldPhoto.path) {
      URL.revokeObjectURL(oldPhoto.url);
    }
    setPhoto({ url, file: optimizedImage });
  }

  async function checkUsername(username) {
    const usernameSnap = await getDoc(doc(db, `usernames/${username}`));
    if (!usernameSnap.exists()) {
      return true;
    }
    if (usernameSnap.data().uid === uid) {
      return true;
    }
    return false;
  }

  function success() {
    setFormStatus({ color: "primary.main", content: "changes saved :)" });
    setSubmitButton({ content: "save changes", disabled: false });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setSubmitButton({ content: "", disabled: true });
    try {
      //check if username in use
      const usernameAvailable = await checkUsername(username);
      if (!usernameAvailable) {
        throw new Error("username taken :(");
      }

      //photo already uploaded or user does not have a photo
      if (!photo.url || photo.path) {
        const data = {
          username: username.toLowerCase(),
          bio,
        };
        await Promise.all([
          updateDoc(doc(db, "profiles", uid), data),
          updateProfile(auth.currentUser, {
            displayName: username.toLowerCase(),
          }),
        ]);
        return success();
      }
      //upload photo
      const currentTime = Date.now();
      const photoPath = `${uid}/${currentTime + photo.file.name}`;
      const uploadResult = await uploadBytes(
        ref(storage, photoPath),
        photo.file
      );
      const photoUrl = await getDownloadURL(uploadResult.ref);
      const data = {
        username: username.toLowerCase(),
        bio,
        photo: { url: photoUrl, path: photoPath },
      };
      await Promise.all([
        updateDoc(doc(db, "profiles", uid), data),
        updateProfile(auth.currentUser, {
          displayName: username.toLowerCase(),
          photoURL: photoUrl,
        }),
      ]);
      return success();
    } catch (err) {
      setFormStatus({ color: "error", content: err.message });
      setSubmitButton({ content: "save changes", disabled: false });
    }
  }

  return (
    <Fade in={true}>
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            mb: "20px",
            letterSpacing: "1px",
            display: headerDisplay,
          }}
        >
          PROFILE
        </Typography>
        <Box
          onSubmit={handleSubmit}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            border: "0.5px solid",
            borderColor: "secondary.dark",
            padding: "20px",
            width: "100%",
            gap: "20px",
          }}
        >
          <DisplayPhoto photo={photo} handleChange={handlePhoto} />
          <TextField
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            label="username"
            value={username}
          ></TextField>
          <Box>
            <TextField
              onChange={(e) => {
                setBio(e.target.value);
              }}
              label="bio"
              multiline
              minRows={6}
              maxRows={6}
              inputProps={{ maxLength: 150 }}
              value={bio}
            ></TextField>
            <Typography
              sx={{ mt: "5px", fontSize: "13px", color: "primary.dark" }}
            >
              {bio.length}/150
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <LoadingButton button={submitButton} />
            <Typography
              color={formStatus.color}
              variant="body2"
              sx={{ fontSize: "14px" }}
            >
              {formStatus.content}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
