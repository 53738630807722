import React from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";

export default function GalleryMb({ images, alt, sold }) {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vw",
        mt: "15px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Swiper
        pagination={{
          type: "progressbar",
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {images.map((image, index) => {
          if (index === 0 && sold) {
            return (
              <SwiperSlide key={index}>
                <Typography
                  sx={{
                    position: "absolute",
                    backgroundColor: "background.default",
                    width: "100vw",
                    height: "100vw",
                    opacity: "60%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "2rem",
                    letterSpacing: "0.1rem",
                    fontWeight: "bold",
                    color: "yellow",
                  }}
                >
                  SOLD
                </Typography>
                <img src={image} alt={alt} />
              </SwiperSlide>
            );
          }
          return (
            <SwiperSlide key={index}>
              <img src={image} alt={alt} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
