import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  MenuList,
  MenuItem,
  Box,
  ListItemText,
  Typography,
} from "@mui/material";
import MediaQuery from "react-responsive";

const containerSize = {
  xxs: "85vw",
  xs: "85vw",
  sm: "85vw",
  md: "45vw",
  lg: "45vw",
  xl: "45vw",
};

const headerDisplay = {
  xxs: "none",
  xs: "none",
  sm: "none",
  md: "block",
  lg: "block",
  xl: "block",
};

export default function Settings() {
  const [selected, setSelected] = React.useState({
    profile: false,
    password: false,
    email: false,
  });

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes("profile")) {
      setSelected({
        profile: true,
        password: false,
        email: false,
      });
    } else if (location.pathname.includes("password")) {
      setSelected({
        profile: false,
        password: true,
        email: false,
      });
    } else if (location.pathname.includes("email")) {
      setSelected({
        profile: false,
        password: false,
        email: true,
      });
    }
  }, [location]);

  return (
    <React.Fragment>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          width: "45vw",
          margin: "30px auto 0 auto",
          display: headerDisplay,
        }}
      >
        SETTINGS
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: containerSize,
          margin: "30px auto 0 auto",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <MediaQuery minWidth={900}>
          <MenuList
            sx={{
              border: "0.5px solid",
              borderColor: "secondary.dark",
              height: "100%",
            }}
          >
            <Link to="profile" style={{ textDecoration: "none" }}>
              <MenuItem selected={selected.profile}>
                <ListItemText sx={{ color: "primary.main" }}>
                  Profile
                </ListItemText>
              </MenuItem>
            </Link>
            <Link to="password" style={{ textDecoration: "none" }}>
              <MenuItem selected={selected.password}>
                <ListItemText sx={{ color: "primary.main" }}>
                  Password
                </ListItemText>
              </MenuItem>
            </Link>
            <Link to="email" style={{ textDecoration: "none" }}>
              <MenuItem selected={selected.email}>
                <ListItemText sx={{ color: "primary.main" }}>
                  Email
                </ListItemText>
              </MenuItem>
            </Link>
          </MenuList>
        </MediaQuery>
        <Outlet />
      </Box>
    </React.Fragment>
  );
}
