import React from "react";
import { Box, TextField, Typography, Fade, Button } from "@mui/material";
import LoadingButton from "../../components/loading-button/LoadingButton";
import { auth, functions, db } from "../../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { updateEmail } from "firebase/auth";
import { updateDoc, doc } from "firebase/firestore";
import { UserContext } from "../../User";
import { useLocation } from "react-router-dom";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const headerDisplay = {
  xxs: "block",
  xs: "block",
  sm: "block",
  md: "none",
  lg: "none",
  xl: "none",
};

export default function EmailSettings() {
  const user = React.useContext(UserContext).user;
  const location = useLocation();

  const [email, setEmail] = React.useState("");
  const [formStatus, setFormStatus] = React.useState({
    color: "error",
    content: "",
  });
  const [confirmation, setConfirmation] = React.useState({
    content: "gửi xác nhận",
    disabled: false,
  });
  const [update, setUpdate] = React.useState({
    content: "đổi email",
    disabled: false,
  });

  React.useEffect(() => {
    setEmail(user.email);
    console.log(user);
    if (user.emailVerified) {
      setConfirmation({
        content: "gửi xác nhận",
        disabled: true,
      });
    }
  }, []);

  async function updateEmailFunction() {
    try {
      setUpdate({ content: "", disabled: true });
      await updateEmail(auth.currentUser, email);
      await updateDoc(doc(db, "users", user.uid), { email });
      setFormStatus({
        color: "primary.main",
        content: "đổi email thành công :)",
      });
      setUpdate({ content: "đổi email", disabled: false });
    } catch (err) {
      setFormStatus({ color: "error", content: err.message });
      setUpdate({ content: "đổi email", disabled: false });
    }
  }

  async function sendConfirmation() {
    try {
      setConfirmation({ content: "", disabled: true });
      const sendConfirmationEmail = httpsCallable(
        functions,
        "emailConfirmation"
      );
      await sendConfirmationEmail();
      setConfirmation({
        content: "gửi xác nhận",
        disabled: false,
      });
      setFormStatus({
        color: "primary.main",
        content: "gửi xác nhận thành công :)",
      });
    } catch (err) {
      setFormStatus({ color: "error", content: err.message });
      setConfirmation({
        content: "gửi xác nhận",
        disabled: false,
      });
    }
  }

  if (location.pathname.includes("settings")) {
    return (
      <Fade in={true}>
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              mb: "20px",
              letterSpacing: "1px",
              display: headerDisplay,
            }}
          >
            EMAIL
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              border: "0.5px solid",
              borderColor: "secondary.dark",
              padding: "20px",
              width: "100%",
              gap: "20px",
            }}
          >
            <TextField
              value={email}
              inputProps={{ type: "email" }}
              label="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></TextField>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                mt: "15px",
              }}
            >
              <LoadingButton
                loading={update}
                variant="contained"
                onClick={updateEmailFunction}
              />
              <LoadingButton
                loading={confirmation}
                variant="outlined"
                onClick={sendConfirmation}
              />
            </Box>
            <Typography
              color={formStatus.color}
              variant="body2"
              sx={{ fontSize: "14px", width: "50%" }}
            >
              {formStatus.content}
            </Typography>
          </Box>
        </Box>
      </Fade>
    );
  }

  // email form used to verify emails on pages where email verification is needed
  return (
    <Fade in={true}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            width: "100%",
            gap: "10px",
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "24px",
              mt: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              mb: "25px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Chưa Xác Nhận Email</span>{" "}
            <SentimentDissatisfiedIcon fontSize="large" />
          </Typography>
          <TextField
            value={email}
            inputProps={{ type: "email" }}
            label="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          ></TextField>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              mt: "10px",
            }}
          >
            <LoadingButton
              loading={update}
              variant="contained"
              onClick={updateEmailFunction}
            />
            <LoadingButton
              loading={confirmation}
              variant="outlined"
              onClick={sendConfirmation}
            />
          </Box>
          <Typography
            color={formStatus.color}
            variant="body2"
            sx={{ fontSize: "15px", mt: "5px" }}
          >
            {formStatus.content}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
