import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Card,
  Checkbox,
  Divider,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { connectRefinementList } from "react-instantsearch-dom";

function CustomSoldStatus({ items, refine, attribute }) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  function transformLabel(label) {
    if (label === "true") {
      return "đã bán";
    }
    return "còn hàng";
  }

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{
          padding: "8px 5px 8px 5px",
          width: "100%",
        }}
        inset="true"
      >
        <List
          disablePadding
          sx={{
            mb: "16px",
            mt: "16px",
            display: "flex",
            gap: "120px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {items.map((item) => {
            return (
              <ListItem
                sx={{
                  width: "110px",
                  display: "flex",
                  justifyItems: "start",
                  alignItems: "center",
                  gap: "2px",
                  padding: 0,
                }}
                key={item.label}
              >
                <Checkbox
                  checked={item.isRefined ? true : false}
                  disableRipple
                  onChange={(e) => {
                    refine(item.value);
                  }}
                  size="18px"
                  sx={{ padding: "3px" }}
                ></Checkbox>
                <ListItemText
                  sx={{ padding: 0 }}
                  primaryTypographyProps={{
                    sx: {
                      fontSize: "15px",
                      padding: 0,
                    },
                  }}
                >
                  {transformLabel(item.label)}
                </ListItemText>
                <Card sx={{ padding: "2px 3px 2px 3px" }}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {item.count}
                  </Typography>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </ListItem>
    </React.Fragment>
  );
}

const SoldStatusMb = connectRefinementList(CustomSoldStatus);

export default SoldStatusMb;
