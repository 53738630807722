import React from "react";
import SearchD from "../../search-copy/search-d/SearchD";
import { Box, Avatar, Typography, Divider, Button, Badge } from "@mui/material";
import { UserContext } from "../../../User";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useRefinementList } from "react-instantsearch";

function EditProfile({ username, uid }) {
  const user = React.useContext(UserContext).user;
  if (user && user.uid === uid) {
    return (
      <Link to="/settings/profile" style={{ textDecoration: "none" }}>
        <Button variant="outlined" sx={{ ml: "20px" }}>
          edit
        </Button>
      </Link>
    );
  }
  return;
}

function VerifiedAvatar({ verified, photo }) {
  if (verified) {
    return (
      <Badge
        badgeContent={<CheckCircleIcon variant="no stroke" />}
        overlap="circular"
      >
        <Avatar sx={{ height: "75px", width: "75px" }} src={photo} />
      </Badge>
    );
  }
  return <Avatar sx={{ height: "75px", width: "75px" }} src={photo} />;
}

function ProfileCard({ profile }) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        justifyContent: "start",
        alignItems: "center",
        padding: "20px 10px 20px 10spx",
        width: "100%",
      }}
    >
      <VerifiedAvatar photo={profile.photo.url} verified={profile.verified} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
        <Typography>{profile.username}</Typography>
        <Typography
          type="body"
          sx={{ color: "secondary.main", fontSize: "15px" }}
        >
          {profile.sold} sold
        </Typography>
      </Box>
      <EditProfile uid={profile.uid} />
    </Box>
  );
}

export default function UserProfileD({ profile }) {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "92%",
          margin: "30px auto 30px auto",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <ProfileCard profile={profile} />

        <Typography sx={{ fontSize: "15px", color: "primary.dark" }}>
          {profile.bio}
        </Typography>
      </Box>
      <Divider
        sx={{ backgroundColor: "secondary.dark", width: "92%", margin: "auto" }}
      />
      <SearchD />
    </React.Fragment>
  );
}
