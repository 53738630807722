import { Box, Typography, Fade } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import EmailSettings from "../settings/EmailSettings";

const containerSize = {
  xxs: "85vw",
  xs: "85vw",
  sm: "85vw",
  md: "35vw",
  lg: "35vw",
  xl: "35vw",
};

export default function NotVerified() {
  return (
    <Fade in={true}>
      <Box
        sx={{
          width: "92%",
          margin: "20px auto 0 auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: containerSize, margin: "20px auto 0 auto" }}>
          <EmailSettings />
        </Box>
      </Box>
    </Fade>
  );
}
