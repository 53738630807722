import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { UserContext } from "../../../User";
import Buy from "../../../components/buy/Buy";
import Edit from "../../../components/edit/Edit";
import Delete from "../../../components/delete/Delete";

function Shipping({ shipping }) {
  if (shipping == 0) {
    return (
      <Typography
        component="span"
        sx={{
          display: "inline",
          fontSize: "16px",
          color: "primary.dark",
        }}
      >
        {" "}
        + free shipping
      </Typography>
    );
  }
  return (
    <Typography
      component="span"
      sx={{
        display: "inline",
        fontSize: "16px",
        color: "primary.dark",
      }}
    >
      {" "}
      + {shipping}k shipping
    </Typography>
  );
}

export default function BuyBoxD({ listing }) {
  const user = React.useContext(UserContext).user;
  const editLink = `/sell/${listing.listingId}`;

  if (listing.sold) {
    return (
      <Box
        sx={{
          bgcolor: "background.default",
          position: "sticky",
          top: "73px",
          padding: "13px 0 23px 0",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          mt: "10px",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", fontSize: "22px", fontWeight: "bold" }}
        >
          {listing.price}k <Shipping shipping={listing.shipping} />
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            disabled
            variant="contained"
            sx={{ borderRadius: 0, width: "100%", fontSize: "18px" }}
          >
            sold
          </Button>
        </Box>
      </Box>
    );
  }

  if (user && user.uid === listing.uid) {
    return (
      <Box
        sx={{
          bgcolor: "background.default",
          position: "sticky",
          top: "73px",
          padding: "13px 0 23px 0",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          mt: "10px",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", fontSize: "22px", fontWeight: "bold" }}
        >
          {listing.price}k <Shipping shipping={listing.shipping} />
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Edit listingId={listing.listingId} />
          <Delete listingId={listing.listingId} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "background.default",
        position: "sticky",
        top: "73px",
        padding: "13px 0 23px 0",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        mt: "10px",
      }}
    >
      <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
        {listing.price}k <Shipping shipping={listing.shipping} />
      </Typography>
      <Buy listingId={listing.listingId} />
    </Box>
  );
}
