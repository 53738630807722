import React from "react";
import { db } from "../../firebaseConfig";
import { doc, getDocs, query, where, collection } from "firebase/firestore";
import { UserContext } from "../../User";
import { Box, Typography, Divider, Button } from "@mui/material";
import {
  toDate,
  eachMonthOfInterval,
  format,
  isWithinInterval,
  isAfter,
  isBefore,
} from "date-fns";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

function binarySearch(date, monthIntervals, start, end) {
  const mid = Math.floor((start + end) / 2);
  if (start === end) {
    return mid;
  }
  if (isWithinInterval(date, monthIntervals[mid].interval)) {
    return mid;
  }
  if (isAfter(date, monthIntervals[mid].interval.end)) {
    return binarySearch(date, monthIntervals, mid + 1, end);
  }
  if (isBefore(date, monthIntervals[mid].interval.start)) {
    return binarySearch(date, monthIntervals, start, mid - 1);
  }
}

function divideMonthsTest() {
  const start = new Date();
  start.setFullYear(start.getFullYear() - 1);
  const end = new Date();
  end.setMonth(end.getMonth() + 1);
  const months = eachMonthOfInterval({ start, end });
  let l = 0;
  let r = 1;
  const monthIntervals = [];
  while (r < months.length) {
    const interval = { start: months[l], end: months[r] };
    const month = {
      month: format(months[l], "MM/yy"),
      interval,
      sold: 0,
      revenue: 0,
      listed: 0,
    };
    monthIntervals.push(month);
    l++;
    r++;
  }
  let listings = [];
  for (let i = 0; i < 240; i++) {
    listings.push({
      timestamp: randomDate(start, end),
      price: Math.floor(Math.random() * 1500),
      shipping: 0,
    });
  }
  let sold = [];
  for (const listing of listings) {
    const random = Math.floor(Math.random() * 10);
    if (random >= 5) {
      sold.push(listing);
    }
  }
  for (const listing of listings) {
    const index = binarySearch(
      listing.timestamp,
      monthIntervals,
      0,
      monthIntervals.length - 1
    );
    monthIntervals[index].listed += 1;
  }
  for (const soldListing of sold) {
    const index = binarySearch(
      soldListing.timestamp,
      monthIntervals,
      0,
      monthIntervals.length - 1
    );
    monthIntervals[index].sold += 1;
    monthIntervals[index].revenue += soldListing.price + soldListing.shipping;
  }
  return monthIntervals;
}

const containerWidth = {
  xxs: "80%",
  xs: "80%",
  sm: "80%",
  md: "40vw",
  lg: "40vw",
  xl: "40vw",
};

const tableHeadingStyle = {
  width: "25%",
  fontSize: "17px",
  fontWeight: "bold",
};

function MonthlyOverview({ month, sold, listed, revenue }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography sx={{ width: "25%" }}>{month}</Typography>
      <Typography sx={{ width: "25%" }}>{listed}</Typography>
      <Typography sx={{ width: "25%" }}>{sold}</Typography>
      <Typography sx={{ width: "25%" }}>{revenue}K</Typography>
    </Box>
  );
}

export default function OG() {
  const months = divideMonthsTest();
  console.log(months);
  const user = React.useContext(UserContext).user;
  const [sold, setSold] = React.useState([]);
  const [listings, setListings] = React.useState([]);
  const [revenue, setRevenue] = React.useState(0);
  React.useEffect(() => {
    async function getListings() {
      const listingsQuery = query(
        collection(db, "listings"),
        where("user", "==", user.displayName)
      );
      const listingsSnap = await getDocs(listingsQuery);
      let listingsData = [];
      listingsSnap.forEach((listing) => {
        listingsData.push(listing.data());
      });
      setListings(listingsData);
    }
    async function getSold() {
      const soldSnap = await getDocs(collection(db, `/users/${user.uid}/sold`));
      if (!soldSnap.empty) {
        let soldData = [];
        let revenueData = 0;
        soldSnap.forEach((soldListing) => {
          const soldListingData = soldListing.data();
          soldData.push(soldListingData);
          revenueData +=
            Number(soldListingData.listing.price) +
            Number(soldListingData.listing.shipping);
        });
        soldData = soldData.sort(function descending(a, b) {
          if (a.timestamp.seconds > b.timestamp.seconds) {
            return -1;
          }
          if (a.timestamp.seconds < b.timestamp.seconds) {
            return 1;
          }
          return 0;
        });
        setSold(soldData);
        setRevenue(revenueData);
      }
    }
    Promise.all([getSold(), getListings()]);
  }, []);

  function divideMonths(sold, listings) {
    const start = toDate(Number(user.metadata.createdAt));
    const end = new Date();
    end.setMonth(end.getMonth() + 1);
    const months = eachMonthOfInterval({ start, end });
    let l = 0;
    let r = 1;
    const monthIntervals = [];
    while (r < months.length) {
      const interval = { start: months[l], end: months[r] };
      const month = {
        month: format(months[l], "MM/yy"),
        interval,
        sold: 0,
        revenue: 0,
        listed: 0,
      };
      monthIntervals.push(month);
      l++;
      r++;
    }
    for (const listing of listings) {
      const index = binarySearch(
        listing.timestamp,
        monthIntervals,
        0,
        monthIntervals.length - 1
      );
      monthIntervals[index].listings += 1;
    }
    for (const soldListing of sold) {
      const index = binarySearch(
        soldListing.timestamp,
        monthIntervals,
        0,
        monthIntervals.length - 1
      );
      monthIntervals[index].sold += 1;
      monthIntervals[index].revenue += soldListing.price + soldListing.shipping;
    }
    return monthIntervals;
  }

  return (
    <Box sx={{ width: containerWidth, margin: "30px auto 0 auto" }}>
      <Typography
        variant="h2"
        sx={{ fontSize: "20px", letterSpacing: "1px", mb: "50px" }}
      >
        <strong>OG SELLER</strong> TỔNG QUAN
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={tableHeadingStyle}>THÁNG</Typography>
          <Typography sx={tableHeadingStyle}>ĐĂNG</Typography>
          <Typography sx={tableHeadingStyle}>BÁN</Typography>
          <Typography sx={tableHeadingStyle}>DOANH THU</Typography>
        </Box>
        <Divider />
        {months.reverse().map((month) => {
          return (
            <MonthlyOverview
              key={month.month}
              month={month.month}
              listed={month.listed}
              sold={month.sold}
              revenue={month.revenue}
            />
          );
        })}
      </Box>
    </Box>
  );
}
