import React from "react";
import { Box, Typography } from "@mui/material";
import GalleryD from "./GalleryD";
import SellerInfoD from "./SellerInfoD";
import BuyBoxD from "./BuyBoxD";
import Save from "../../../components/save/Save";
import EditCollections from "./EditCollections";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { UserContext } from "../../../User";

function DisplayBrands({ brands }) {
  return (
    <Typography component="span">
      {brands.map((brand, index) => {
        if (index != 0) {
          return (
            <Typography
              key={brand}
              component="span"
              sx={{
                display: "inline",
                textDecoration: "underline",
                fontSize: "16px",
              }}
            >
              , {brand}
            </Typography>
          );
        }
        return (
          <Typography
            key={brand}
            component="span"
            sx={{
              display: "inline",
              fontSize: "16px",
              textDecoration: "underline",
            }}
          >
            {brand}
          </Typography>
        );
      })}
    </Typography>
  );
}

function ListingInfo({ listing }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <DisplayBrands brands={listing.brands} />
      <Typography sx={{ fontSize: "15px" }}>{listing.title}</Typography>
      <Typography sx={{ fontSize: "15px", color: "primary.dark" }}>
        size {listing.type.size.toUpperCase()} /{" "}
        <span style={{ fontSize: "14px" }}>
          {formatDistanceToNow(listing.timestamp.toDate())} ago
        </span>
      </Typography>
    </Box>
  );
}

function IGInfo(listing) {
  const user = React.useContext(UserContext).user;
  let IGUrl = `https://instagram.com/p/${listing.listing.shortCode}`;

  if (
    listing.listing.IGUser &&
    user &&
    (user.displayName === "closetd" ||
      user.displayName === "curated" ||
      user.displayName === "curated2" ||
      user.displayName === "curated3")
  ) {
    return (
      <React.Fragment>
        <Typography
          variant="body2"
          sx={{
            color: "primary.main",
            mt: "20px",
            fontSize: "15px",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        >
          {listing.listing.IGUser} <br />
          <a href={IGUrl}>link</a> <br />
          {listing.listing.category}
        </Typography>
        <Typography>{listing.category}</Typography>
      </React.Fragment>
    );
  }
  return;
}

export default function ListingDetailsD({ listing }) {
  return (
    <Box sx={{ mt: "20px" }}>
      <Box
        sx={{
          display: "flex",
          width: "80%",
          gap: "30px",
          margin: "auto",
          justifyContent: "center",
        }}
      >
        <GalleryD
          images={listing.images}
          alt={listing.title}
          sold={listing.sold}
        />
        <Box sx={{ width: "30vw" }}>
          <SellerInfoD listing={listing} />
          <Box
            sx={{
              mt: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ListingInfo listing={listing} />
            <Save listing={listing} />
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: "primary.dark",
              mt: "20px",
              fontSize: "14px",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {listing.description}
          </Typography>
          <IGInfo listing={listing} />
          <EditCollections listing={listing} attribute="collections" />
          <BuyBoxD listing={listing} />
        </Box>
      </Box>
    </Box>
  );
}
