import React from "react";
import { useCurrentRefinements } from "react-instantsearch";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const conditionTranslation = {
  new: "mới",
  good: "tốt",
  okay: "khá",
  used: "cũ",
};

function parseCategory(categoryString) {
  if (!categoryString) {
    return false;
  }
  const gender = categoryString[0];
  let periodIndex = undefined;
  for (let i = 3; i < categoryString.length; i++) {
    if (categoryString[i] == ".") {
      periodIndex = i;
      break;
    }
  }
  const category = categoryString.slice(2, periodIndex);
  const sub = categoryString.slice(periodIndex + 1);
  return { gender, category, sub };
}

export default function CurrentRefinements(props) {
  const { items, canRefine, refine } = useCurrentRefinements(props);

  React.useEffect(() => {
    console.log(items);
  }, [items]);

  function textContent(refinement) {
    if (refinement.attribute === "gender") {
      if (refinement.label === "m") {
        return "Nam";
      }
      return "Nữ";
    }
    if (refinement.attribute === "sold") {
      if (refinement.label === "true") {
        return "đã bán";
      }
      return "còn hàng";
    }
    if (
      refinement.attribute === "brands" ||
      refinement.attribute === "size" ||
      refinement.attribute === "price"
    ) {
      return refinement.label;
    }
    if (refinement.attribute === "condition") {
      return conditionTranslation[refinement.label];
    }
    if (refinement.attribute === "category") {
      const categoryObject = parseCategory(refinement.label);
      return `${categoryObject.gender} : ${categoryObject.sub}`;
    }
    return `${refinement.attribute.toUpperCase()} : ${refinement.label}`;
  }

  return (
    <Box sx={{ display: "flex", gap: "5px", overflowX: "scroll" }}>
      {items.map((item) => {
        return item.refinements.map((refinement) => {
          if (refinement.attribute === "uid") {
            return;
          }
          return (
            <Box
              sx={{
                padding: "4px 6px 4px 6px",
                border: "0.5px solid",
                borderColor: "secondary.dark",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                maxHeight: "40px",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>
                {textContent(refinement).toUpperCase()}
              </Typography>
              <CloseIcon
                sx={{ fontSize: "15px", "&:hover": { cursor: "pointer" } }}
                onClick={() => {
                  refine(refinement);
                }}
              />
            </Box>
          );
        });
      })}
    </Box>
  );
}
