import React from "react";
import SearchMb from "../../search-copy/search-mb/SearchMb";
import { Paper, Box, Avatar, Typography, Button, Badge } from "@mui/material";
import { UserContext } from "../../../User";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function EditProfile({ username, uid }) {
  const user = React.useContext(UserContext).user;
  if (user && user.uid === uid) {
    return (
      <Link
        to="/settings/profile"
        style={{ textDecoration: "none", marginTop: "3px" }}
      >
        <Button variant="outlined">edit</Button>
      </Link>
    );
  }
  return;
}

function VerifiedAvatar({ verified, photo }) {
  if (verified) {
    return (
      <Badge
        badgeContent={<CheckCircleIcon variant="no stroke" />}
        overlap="circular"
      >
        <Avatar sx={{ height: "20vw", width: "20vw" }} src={photo} />
      </Badge>
    );
  }
  return <Avatar sx={{ height: "20vw", width: "20vw" }} src={photo} />;
}

function ProfileCard({ profile }) {
  return (
    <Box
      sx={{
        margin: "20px auto auto",
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "secondary.dark",
        padding: "2vw 2vw 20px 2vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "5vw",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 10px 15px",
          width: "100%",
        }}
      >
        <VerifiedAvatar photo={profile.photo.url} verified={profile.verified} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mr: "2vw",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {profile.username}
            </Typography>
            <Typography type="body" sx={{ color: "secondary.main" }}>
              {profile.sold} transactions
            </Typography>
          </Box>
          <EditProfile uid={profile.uid} />
        </Box>
      </Box>
      <Typography sx={{ width: "92%", color: "primary.dark", margin: "auto" }}>
        {profile.bio}
      </Typography>
    </Box>
  );
}

export default function UserProfileMb({ profile }) {
  return (
    <React.Fragment>
      <ProfileCard profile={profile} />
      <SearchMb />
    </React.Fragment>
  );
}
