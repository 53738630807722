import { Box, Typography } from "@mui/material";
import { connectStateResults } from "react-instantsearch-dom";

function CustomSearchOverview({ searchResults, searchState }) {
  if (searchState.query && searchResults) {
    return (
      <Box>
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {searchResults.nbHits} listings for "{searchState.query}"
        </Typography>
      </Box>
    );
  } else if (searchResults) {
    return (
      <Box>
        <Typography variant="body2" sx={{ fontSize: "16px" }}>
          {searchResults.nbHits} listings
        </Typography>
      </Box>
    );
  }
}

const SearchOverview = connectStateResults(CustomSearchOverview);

export default SearchOverview;
